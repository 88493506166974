import {
  upsertTransaction,
  markTransactionForDeletion,
  markCurrencyForDeletion,
  changePrimaryCurrency,
  upsertCurrency,
  upsertAccount,
  updateAccountsOrder,
  markAccountForDeletion,
  revertAccountStatus,
  upsertQuickAction,
  markQuickActionForDeletion,
  updateAccountsGroupsOrder,
  markTagForDeletion,
} from "database";
import { isDriveSyncEnabled, syncDriveData } from "./dataSync";
import {
  upsertAccountsGroup,
  revertAccountsGroupStatus,
  markAccountsGroupForDeletion,
} from "database/accountsGroups";

const timeoutRef = {
  current: setTimeout(() => {}, 0),
};

function sync() {
  clearTimeout(timeoutRef.current);

  timeoutRef.current = setTimeout(() => {
    isDriveSyncEnabled().then((res) => {
      if (res) {
        syncDriveData();
      }
    });
  }, 1500);
}

function syncDecorator<PARAMS extends any[], RETURN>(
  func: (...params: PARAMS) => Promise<RETURN>
) {
  return async (...params: PARAMS) => {
    const res = await func(...params);
    sync();

    return res;
  };
}

export const upsertTransactionSynced = syncDecorator(upsertTransaction);

export const markTransactionForDeletionSynced = syncDecorator(
  markTransactionForDeletion
);

export const changePrimaryCurrencySycned = syncDecorator(changePrimaryCurrency);

export const upsertCurrencySynced = syncDecorator(upsertCurrency);

export const markCurrencyForDeletionSynced = syncDecorator(
  markCurrencyForDeletion
);

export const upsertAccountSynced = syncDecorator(upsertAccount);
export const markAccountForDeletionSynced = syncDecorator(
  markAccountForDeletion
);
export const revertAccountStatusSynced = syncDecorator(revertAccountStatus);

export const updateAccountsOrderSynced = syncDecorator(updateAccountsOrder);
export const updateAccountsGroupsOrderSynced = syncDecorator(
  updateAccountsGroupsOrder
);

export const upsertAccountsGroupSynced = syncDecorator(upsertAccountsGroup);
export const markAccountsGroupForDeletionSynced = syncDecorator(
  markAccountsGroupForDeletion
);
export const revertAccountsGroupStatusSynced = syncDecorator(
  revertAccountsGroupStatus
);

export const upsertQuickActionSynced = syncDecorator(upsertQuickAction);

export const markQuickActionForDeletionSynced = syncDecorator(
  markQuickActionForDeletion
);

export const markTagForDeletionSynced = syncDecorator(markTagForDeletion);
