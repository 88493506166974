import {
  Alert,
  AlertProps,
  Button,
  Snackbar,
  SnackbarProps,
  Typography,
} from "@mui/material";
import React from "react";

type Options = Omit<SnackbarProps, "message" | "onClose"> & {
  once?: boolean;
  severity?: AlertProps["severity"];
  description?: string;
};

type State = {
  bars: React.ReactElement[];
};

class MSnackbar extends React.Component<{}, State> {
  static show(_: string, ___: Options = {}) {
    throw new Error("MSnackbar Component not Initialized");
  }

  static hide() {
    throw new Error("MSnackbar Component not Initialized");
  }

  state: State = {
    bars: [],
  };

  constructor(props: {}) {
    super(props);
    MSnackbar.show = this._show;
    MSnackbar.hide = this._hide;
  }

  _show = (message: string, options: Options = {}) => {
    const { once, severity, description, ...snackbarProps } = options;

    const { bars: globalBars } = this.state;

    if (once && globalBars.some(({ props }) => props.message === message)) {
      return;
    }

    const bar = (
      <Snackbar
        key="snack"
        open
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...snackbarProps}
        autoHideDuration={snackbarProps.autoHideDuration || 3000}
        TransitionProps={{
          onExited: this.handleClose,
        }}
        onClose={(e, reason) => {
          if (reason === "clickaway") {
            return;
          }
          this.setState(({ bars }) => ({
            bars: [
              React.cloneElement(bars[0], { open: false }),
              ...bars.slice(1),
            ],
          }));
        }}
        message={message}
      >
        {severity && (
          <Alert severity={severity} sx={{ width: "100%" }}>
            <Typography>{message}</Typography>
            {description && (
              <Typography variant="caption">{description}</Typography>
            )}
          </Alert>
        )}
      </Snackbar>
    );

    this.setState(({ bars }) => ({
      bars: [...bars, bar],
    }));
  };

  _hide = () => {
    this.setState(({ bars }) => ({
      bars: [React.cloneElement(bars[0], { open: false }), ...bars.slice(1)],
    }));
  };

  handleClose = () => {
    this.setState(({ bars }) => ({
      bars: bars.slice(1),
    }));
  };

  render() {
    const { bars } = this.state;

    return <>{bars.slice(0, 1)}</>;
  }
}

export default MSnackbar;

export function getActionElement(label: string, func: () => void) {
  return <Button onClick={func}>{label}</Button>;
}
