import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useAtom } from "@m1st1ck/atomjs-react";
import { accountsAtom, accountsGroupsAtom, accountsGroupsOrderAtom, accountsOrderAtom } from "utils/atoms";
import { Account } from "types/data.types";
import { useModal } from "components/ModalProvider";
import { getDataToExport } from "utils/dataSync";
import { format } from "date-fns";

export default function ExportFromAccountModal() {
  const { closeModal } = useModal();
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [accounts] = useAtom(accountsAtom);
  const [accountsGroups] = useAtom(accountsGroupsAtom);
  const [accountsOrder] = useAtom(accountsOrderAtom);
  const [accountsGroupsOrder] = useAtom(accountsGroupsOrderAtom);

  const sortedAccounts = accounts
    .sort(
      (a, b) =>
        accountsOrder[a.groupId || "NOT_IN_GROUP_ID"]?.indexOf(a.uid) -
        accountsOrder[b.groupId || "NOT_IN_GROUP_ID"]?.indexOf(b.uid)
    )
    .sort(
      (a, b) =>
        accountsGroupsOrder.indexOf(a.groupId || "") -
        accountsGroupsOrder.indexOf(b.groupId || "")
    );

  const [options, setOptions] = useState({
    from: true,
    to: true,
  });

  return (
    <>
      <DialogTitle>Export from Account</DialogTitle>
      <DialogContent sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Autocomplete
          getOptionLabel={(option) => option.name}
          options={sortedAccounts}
          groupBy={(a) =>
            accountsGroups.find((ag) => ag.uid === a.groupId)?.name || ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Account"
              margin="dense"
              helperText=" "
            />
          )}
          value={selectedAccount}
          onChange={(__, newValue: Account | null) => {
            setSelectedAccount(newValue);
          }}
        />

        <FormControl>
          <FormGroup>
            <FormControlLabel
              label="Export transfers"
              control={
                <Checkbox
                  checked={options.from && options.to}
                  indeterminate={options.from !== options.to}
                  onChange={(_, checked) => {
                    setOptions((prev) => ({
                      ...prev,
                      from: checked,
                      to: checked,
                    }));
                  }}
                />
              }
            />
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              <FormControlLabel
                label="From"
                control={
                  <Checkbox
                    checked={options.from}
                    onChange={(_, checked) => {
                      setOptions((prev) => ({
                        ...prev,
                        from: checked,
                      }));
                    }}
                  />
                }
              />
              <FormControlLabel
                label="To"
                control={
                  <Checkbox
                    checked={options.to}
                    onChange={(_, checked) => {
                      setOptions((prev) => ({
                        ...prev,
                        to: checked,
                      }));
                    }}
                  />
                }
              />
            </Box>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              if (!selectedAccount) {
                return;
              }

              const data = await getDataToExport();

              const transactionsToExport = data.transactions.filter(
                (t) =>
                  (t.account.uid === selectedAccount.uid &&
                    (!t.toAccount ||
                      t.toAccount.uid === selectedAccount.uid)) ||
                  (options.from && t.account.uid === selectedAccount.uid) ||
                  (options.to && t.toAccount?.uid === selectedAccount.uid)
              );

              const jsonStr = JSON.stringify(transactionsToExport);
              const filename = `${selectedAccount.name}_${format(
                new Date(),
                "dd_MM_yyyy_HH_mm"
              )}_FUNDS_NINJA.json`;
              const element = document.createElement("a");
              element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(jsonStr)
              );
              element.setAttribute("download", filename);

              element.style.display = "none";
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
            } catch (error) {
              alert("Failed to parse json");
            }
          }}
        >
          Export
        </Button>
      </DialogActions>
    </>
  );
}
