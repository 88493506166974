import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

type PromptAction<T> = {
  text: string;
  value: T;
};

type Options<T> = {
  actions?: PromptAction<T>[] | string;
};

type PromptResolver<T> = (value: T | PromiseLike<T>) => void;

type PromptContent<T> =
  | string
  | JSX.Element
  | ((resolve: PromptResolver<T>) => JSX.Element);

type State<T> = {
  title?: string;
  content?: PromptContent<T>;
  options: Options<T>;
  resolve?: PromptResolver<T>;
};

class Prompt extends React.Component<{}, State<any>> {
  static show<T>(
    _: string,
    __: PromptContent<T> = "",
    ___: Options<T> = {}
  ): Promise<T> {
    throw new Error("Prompt Component not Initialized");
  }

  state: State<any> = {
    title: undefined,
    content: undefined,
    options: {},
    resolve: undefined,
  };

  constructor(props: {}) {
    super(props);
    Prompt.show = this._show;
  }

  _show = <T, >(
    title: string,
    content: PromptContent<T> = "",
    options: Options<T> = {}
  ): Promise<T> => {
    return new Promise((resolve) => {
      this.setState({
        title,
        content,
        options,
        resolve,
      });
    });
  }

  handleClose = (answer: boolean) => {
    const { resolve } = this.state;

    resolve?.(answer);

    this.setState({
      title: undefined,
      content: undefined,
      options: {},
      resolve: undefined,
    });
  };

  render() {
    const { title, content, options, resolve } = this.state;
    const { actions } = options;

    if (!title || !resolve) {
      return null;
    }

    return (
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>

        {typeof content === "string" && (
          <DialogContent>
            <DialogContentText color="textPrimary">{content}</DialogContentText>
          </DialogContent>
        )}

        {typeof content === "object" && (
          <DialogContent>{content}</DialogContent>
        )}

        {typeof content === "function" && content(this.handleClose)}

        {actions && (
          <DialogActions>
            {typeof actions === "string" && (
              <Button onClick={() => this.handleClose(true)} color="primary">
                {actions}
              </Button>
            )}
            {Array.isArray(actions) &&
              actions.map(({ text, value }, i) => (
                <Button
                  key={i}
                  onClick={() => this.handleClose(value)}
                  color="primary"
                >
                  {text}
                </Button>
              ))}
          </DialogActions>
        )}

        {!actions && (
          <DialogActions>
            <Button onClick={() => this.handleClose(false)} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => this.handleClose(true)}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default Prompt;
