import { AccountsGroup } from "types/data.types";
import { v4 } from "uuid";
import { loadDb, Upsert } from "./database";

export const upsertAccountsGroup = async (
  accountsGroup: Upsert<AccountsGroup>
) => {
  const db = await loadDb;
  const tx = db.transaction(["accountsGroups"], "readwrite");
  const accountsGroupsStore = tx.objectStore("accountsGroups");
  const accountGroup = accountsGroup.uid
    ? await accountsGroupsStore.get(accountsGroup.uid)
    : undefined;

  const nAccountsGroup: AccountsGroup = {
    ...accountsGroup,
    updateDate: new Date(),
    createDate: accountGroup?.createDate || new Date(),
    uid: accountGroup?.uid || v4(),
    status: !accountGroup ? "ADD" : undefined,
    log: [
      ...(accountGroup?.log || []),
      {
        event: !accountGroup ? "ADD" : "UPDATE",
        timestamp: new Date(),
        userAgent: window.navigator.userAgent,
      },
    ],
  };

  await accountsGroupsStore.put(nAccountsGroup);

  return nAccountsGroup;
};

export const getAccountsGroups = async () => {
  const db = await loadDb;
  return (await db.getAll("accountsGroups")).filter(
    (c) => c.status !== "DELETE"
  );
};

export const markAccountsGroupForDeletion = async (
  id: AccountsGroup["uid"]
) => {
  if (!id) {
    throw new Error("AccountsGroup id is required");
  }

  const db = await loadDb;
  const tx = db.transaction(["accountsGroups"], "readwrite");
  const accountsGroupsStore = tx.objectStore("accountsGroups");
  const accountsGroup = await accountsGroupsStore.get(id);

  if (!accountsGroup) {
    throw new Error("AccountsGroup not found");
  }

  await accountsGroupsStore.put({
    ...accountsGroup,
    status: "DELETE",
  });
};

export const revertAccountsGroupStatus = async (id: AccountsGroup["uid"]) => {
  if (!id) {
    throw new Error("AccountsGroup id is required");
  }

  const db = await loadDb;
  const tx = db.transaction(["accountsGroups"], "readwrite");
  const accountsGroupsStore = tx.objectStore("accountsGroups");
  const accountGroup = await accountsGroupsStore.get(id);

  if (!accountGroup) {
    throw new Error("AccountsGroup not found");
  }

  const nAccountsGroup = {
    ...accountGroup,
    status: undefined,
    updateDate: new Date(),
  };

  await accountsGroupsStore.put(nAccountsGroup);

  return nAccountsGroup;
};

export const exportAccountsGroups = async () => {
  const db = await loadDb;
  return db.getAll("accountsGroups");
};

export const importAccountsGroup = async (accountsGroup: AccountsGroup) => {
  const db = await loadDb;
  await db.put("accountsGroups", accountsGroup);
};

export const clearAccountsGroupsState = async (
  accountsGroups: AccountsGroup[]
) => {
  const db = await loadDb;
  const tx = db.transaction("accountsGroups", "readwrite");
  const accountsGroupsStore = tx.objectStore("accountsGroups");

  let cursor = await accountsGroupsStore.openCursor();

  while (cursor) {
    const accountsGroup = cursor.value;

    if (accountsGroups.some((a) => a.uid === accountsGroup.uid)) {
      accountsGroupsStore.put({
        ...accountsGroup,
        status: undefined,
      });
    }

    cursor = await cursor.continue();
  }
};

export const deleteAccountsGroup = async (accountsGroup: AccountsGroup) => {
  const db = await loadDb;
  await db.delete("accountsGroups", accountsGroup.uid);
};

export const deleteAllAccountsGroups = async () => {
  const db = await loadDb;
  await db.clear("accountsGroups");
};
