import { AccountCircle, Groups3 } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useModal } from "components/ModalProvider";
import Prompt from "components/Prompt";
import { useNavigate } from "react-router-dom";
import { primaryCurrencyAtom } from "utils/atoms";

export default function AccountAddButton() {
  const { openModal } = useModal();
  const navigate = useNavigate();

  return (
    <SpeedDial
      ariaLabel="Accounts SpeedDial"
      sx={{ position: "absolute", bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        icon={<AccountCircle />}
        tooltipTitle="Account"
        onClick={() => {
          const primaryCurrency = primaryCurrencyAtom.getState();
          if (!primaryCurrency) {
            Prompt.show("No primary currency", "", {
              actions: "Add",
            }).then(() => {
              navigate("/currencies");
            });
            return;
          }
          openModal("account", {
            base: 0,
            name: "",
            currency: primaryCurrency,
            groupId: null,
          });
        }}
      />
      <SpeedDialAction
        icon={<Groups3 />}
        tooltipTitle="Group"
        onClick={() => {
          openModal("accountGroup", {
            name: "",
          });
        }}
      />
    </SpeedDial>
  );
}
