import TimeBox from "./TimeBox";
import {
  Checkbox,
  IconButton,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { getColorForType } from "utils/constants";
import { addTagsToTransaction, removeTagsFromTransaction } from "database";
import TagsGroup from "./TagsGroup";
import { Transaction } from "types/data.types";
import { formatNumber } from "utils/format";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { useAtom } from "@m1st1ck/atomjs-react";
import {
  multipleDeletionAtom,
  primaryCurrencyAtom,
  quickTagsAtom,
  transactionsAtom,
} from "utils/atoms";
import { useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import { useModal } from "./ModalProvider";
import Box from "./Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type TransactionBoxProps = {
  transaction: Transaction;
};

const TransactionBox: React.FC<TransactionBoxProps> = ({ transaction }) => {
  const { openModal } = useModal();

  const {
    amount,
    date,
    type,
    description,
    tags,
    account,
    toAccount,
    toAmount,
  } = transaction;
  const addTagsClipboard = useAtom(quickTagsAtom);
  const multipleDeletion = useAtom(multipleDeletionAtom);
  const primaryCurrency = useAtom(primaryCurrencyAtom);

  const tagsToAdd = addTagsClipboard.filter((t) => !tags.includes(t));

  const [justAddedTags, setJustAddedTags] = useState<string[]>([]);

  return (
    <ListItemButton
      onClick={() => {
        if (multipleDeletion.enabled) {
          multipleDeletionAtom.setState((prev) => ({
            ...prev,
            items: prev.items.some((item) => item.uid === transaction.uid)
              ? prev.items.filter((item) => item.uid !== transaction.uid)
              : [...prev.items, transaction],
          }));
          return;
        }
        openModal("transaction", transaction);
      }}
      style={{
        background: `${getColorForType(type)}50`,
        borderTop: "1px solid black",
        height: 64,
      }}
    >
      {multipleDeletion.enabled && (
        <Checkbox
          sx={{
            ml: -2,
          }}
          checked={multipleDeletion.items.some(
            (t) => t.uid === transaction.uid
          )}
        />
      )}
      <TimeBox date={new Date(date)} />
      {justAddedTags.length > 0 && (
        <Tooltip enterDelay={666} title={`Revert Added Tags`}>
          <IconButton
            aria-label="revert pasted tags"
            onClick={(e) => {
              e.stopPropagation();
              removeTagsFromTransaction(justAddedTags, transaction).then(
                (updateTransaction) => {
                  setJustAddedTags([]);
                  transactionsAtom.setState((prev) => [
                    ...prev.filter((t) => t.uid !== updateTransaction.uid),
                    updateTransaction,
                  ]);
                }
              );
            }}
          >
            <UndoIcon />
          </IconButton>
        </Tooltip>
      )}
      {tagsToAdd.length > 0 && (
        <Tooltip enterDelay={666} title={`Add tags - ${tagsToAdd.join(", ")}`}>
          <IconButton
            aria-label="paste tags"
            onClick={(e) => {
              e.stopPropagation();
              addTagsToTransaction(tagsToAdd, transaction).then(
                (updateTransaction) => {
                  setJustAddedTags(tagsToAdd);
                  transactionsAtom.setState((prev) => [
                    ...prev.filter((t) => t.uid !== updateTransaction.uid),
                    updateTransaction,
                  ]);
                }
              );
            }}
          >
            <ContentPasteGoIcon />
          </IconButton>
        </Tooltip>
      )}
      <Box flex={1} mx={1} overflow="hidden">
        <Box display="block" overflow="overlay">
          <TagsGroup tags={tags} />
        </Box>
        <Typography
          color="textSecondary"
          variant="caption"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mx: 1,
          }}
        >
          {description}
        </Typography>
      </Box>
      <Box alignItems="flex-end">
        <Box flexDirection="row" alignItems="center">
          <Box flexDirection="row" alignItems="flex-start">
            <Typography>{formatNumber(amount.value)}</Typography>
            {amount.currency.uid !== primaryCurrency?.uid && (
              <Typography fontSize={8} variant="caption">
                {amount.currency.uid}
              </Typography>
            )}
          </Box>

          {toAmount && (
            <>
              <ArrowForwardIcon sx={{ width: 16, mx: 0.5 }} />
              <Box flexDirection="row" alignItems="flex-start">
                <Typography>{formatNumber(toAmount.value)}</Typography>
                {toAmount.currency.uid !== primaryCurrency?.uid && (
                  <Typography fontSize={8} variant="caption">
                    {toAmount.currency.uid}
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Typography variant="caption">{account.name}</Typography>
          {toAccount && (
            <>
              <ArrowForwardIcon sx={{ width: 16, mx: 0.5 }} />
              <Typography variant="caption">{toAccount.name}</Typography>
            </>
          )}
        </Box>
      </Box>
    </ListItemButton>
  );
};

export default TransactionBox;
