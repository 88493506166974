import { Typography, Box } from "@mui/material";
import { format, isValid } from "date-fns";

type DateBoxProps = {
  date: Date;
};

const DateBox: React.FC<DateBoxProps> = ({ date }) => {
  if (!isValid(date)) {
    return (
      <Box>
        <Typography color="textPrimary" variant="h6">
          Invalid
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography color="textPrimary" variant="h6">
        {format(date, "dd")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 1,
        }}
      >
        <Typography color="textSecondary" variant="caption">
          {format(date, "yyyy.MM")}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {format(date, "iiii")}
        </Typography>
      </Box>
    </Box>
  );
};

export default DateBox;
