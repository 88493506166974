import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useInput } from "components/FormComponents";
import { Account, Currency, Transaction } from "types/data.types";
import { isQueryMatch, Upsert } from "database";
import {
  AppBar,
  Autocomplete,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useAtom } from "@m1st1ck/atomjs-react";
import { formatNumber, formatInputNumber, parseNumber } from "utils/format";
import {
  accountsAtom,
  accountsGroupsAtom,
  accountsGroupsOrderAtom,
  accountsOrderAtom,
  currenciesAtom,
  primaryCurrencyAtom,
  quickActionsAtom,
  tagsAtom,
  transactionQueryAtom,
  transactionsAtom,
} from "utils/atoms";
import { fetchTags } from "utils/actions";
import { useRef } from "react";
import { TYPES } from "utils/constants";
import Big from "big.js";
import DeleteIcon from "@mui/icons-material/Delete";
import Prompt from "components/Prompt";
import { useModal } from "components/ModalProvider";
import {
  markQuickActionForDeletionSynced,
  markTransactionForDeletionSynced,
  upsertQuickActionSynced,
  upsertTransactionSynced,
} from "utils/databaseMiddleware";
import { useAsync } from "@m1st1ck/useasync";
import CurrencySelector from "components/CurrencySelector";
import Box from "components/Box";
import { set } from "date-fns";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { Replay } from "@mui/icons-material";
import EmojiSelector from "components/EmojiSelector";
import useMForm from "utils/useMForm";
import { Controller } from "react-hook-form";
import { matchSorter } from "match-sorter";

type TransactionFormData = Pick<
  Upsert<Transaction>,
  "date" | "description" | "rate" | "tags" | "type"
> & {
  amount: string;
  amountCurrency: Currency;
  account: Account | null;
  toAmount: string;
  toAmountCurrency: Currency;
  toAccount: Account | null;
  isQuickAction: boolean;
  quickActionName: string;
  quickActionEmoji: string;
};

const focusRefOnEnter = (
  ref: React.MutableRefObject<HTMLInputElement | null>
) => {
  const timeoutRef = { current: setTimeout(() => {}, 0) };
  return {
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          ref.current?.focus();
        }, 300);
      }
    },
  };
};

const TransactionModal: React.FC = () => {
  const { closeModal: handleClose, data } = useModal<"transaction">();

  const [accounts] = useAtom(accountsAtom);
  const [accountsGroups] = useAtom(accountsGroupsAtom);
  const [accountsOrder] = useAtom(accountsOrderAtom);
  const [accountsGroupsOrder] = useAtom(accountsGroupsOrderAtom);

  const sortedAccounts = accounts
    .sort(
      (a, b) =>
        accountsOrder[a.groupId || "NOT_IN_GROUP_ID"]?.indexOf(a.uid) -
        accountsOrder[b.groupId || "NOT_IN_GROUP_ID"]?.indexOf(b.uid)
    )
    .sort(
      (a, b) =>
        accountsGroupsOrder.indexOf(a.groupId || "") -
        accountsGroupsOrder.indexOf(b.groupId || "")
    );

  const form = useMForm<TransactionFormData>({
    mode: "onBlur",
    defaultValues: {
      isQuickAction: !!data.quickActionUid || false,
      quickActionEmoji: data.quickActionEmoji || "🍏",
      quickActionName: data.quickActionName || "",
      amount: data.amount ? formatNumber(data.amount.value) : "",
      amountCurrency:
        data.amount?.currency ||
        primaryCurrencyAtom.getState() ||
        currenciesAtom.getCoreState()[0],
      account: data.account || accounts[0],
      type: data.type || TYPES.expense,
      date:
        data.date && !data.quickActionUid ? new Date(data.date) : new Date(),
      tags: data.tags || [],
      description: data.description || "",
      toAmount: data.toAmount ? formatNumber(data.toAmount.value) : "",
      toAmountCurrency:
        data.amount?.currency ||
        primaryCurrencyAtom.getState() ||
        currenciesAtom.getCoreState()[0],
      rate: data.rate || 1,
      toAccount: accounts.find((a) => a.uid === data.toAccount?.uid),
    },
  });
  const Input = useInput(form.control);

  const [upsertTransaction, upsertTransactionStat] = useAsync(
    async ({
      amount,
      amountCurrency,
      toAmount,
      toAmountCurrency,
      ...formData
    }: TransactionFormData) => {
      const res = await upsertTransactionSynced({
        uid: data.uid,
        date: formData.date,
        description: formData.description,
        rate: formData.rate,
        tags: formData.tags,
        type: formData.type,
        account: {
          name: formData.account!.name,
          uid: formData.account!.uid,
        },
        amount: {
          value: parseNumber(amount),
          currency: {
            rate: amountCurrency.rate,
            uid: amountCurrency.uid,
          },
        },
        toAccount:
          formData.type === TYPES.transfer
            ? {
                name: formData.toAccount!.name,
                uid: formData.toAccount!.uid,
              }
            : null,
        toAmount:
          formData.type === TYPES.transfer
            ? {
                value: parseNumber(toAmount),
                currency: {
                  rate: toAmountCurrency.rate,
                  uid: toAmountCurrency.uid,
                },
              }
            : null,
      });

      fetchTags();

      if (
        res.new &&
        isQueryMatch(transactionQueryAtom.getState(), res.transaction)
      ) {
        transactionsAtom.setState((prev) => [...prev, res.transaction]);
      } else if (!res.new) {
        transactionsAtom.setState((prev) =>
          prev.map((t) => (t.uid === res.transaction.uid ? res.transaction : t))
        );
      }

      handleClose();
    }
  );

  const [upsertQuickAction, upsertQuickActionStat] = useAsync(
    async ({
      amount,
      amountCurrency,
      toAmount,
      toAmountCurrency,
      ...formData
    }: TransactionFormData) => {
      const res = await upsertQuickActionSynced({
        emoji: formData.quickActionEmoji,
        name: formData.quickActionName,
        uid: data.quickActionUid,
        transaction: {
          date: formData.date,
          description: formData.description,
          rate: formData.rate,
          tags: formData.tags,
          type: formData.type,
          account: {
            name: formData.account!.name,
            uid: formData.account!.uid,
          },
          amount: {
            value: parseNumber(amount),
            currency: {
              rate: amountCurrency.rate,
              uid: amountCurrency.uid,
            },
          },
          toAccount:
            formData.type === TYPES.transfer
              ? {
                  name: formData.toAccount!.name,
                  uid: formData.toAccount!.uid,
                }
              : null,
          toAmount:
            formData.type === TYPES.transfer
              ? {
                  value: parseNumber(toAmount),
                  currency: {
                    rate: toAmountCurrency.rate,
                    uid: toAmountCurrency.uid,
                  },
                }
              : null,
        },
      });

      if (res.new) {
        quickActionsAtom.setState((prev) => [...prev, res.quickAction]);
      } else {
        quickActionsAtom.setState((prev) =>
          prev.map((t) => (t.uid === res.quickAction.uid ? res.quickAction : t))
        );
      }

      handleClose();
    }
  );

  const [tags] = useAtom(tagsAtom);

  const type = form.watch("type");
  const isQuickAction = form.watch("isQuickAction");
  const selectedAccount = form.watch("account");

  const timeRef = useRef<HTMLInputElement | null>(null);
  const accountRef = useRef<HTMLInputElement | null>(null);
  const accountToRef = useRef<HTMLInputElement | null>(null);
  const amountRef = useRef<HTMLInputElement | null>(null);
  const rateRef = useRef<HTMLInputElement | null>(null);
  const amountToRef = useRef<HTMLInputElement | null>(null);
  const tagsRef = useRef<HTMLInputElement | null>(null);

  return (
    <div>
      <AppBar sx={{ position: "relative" }}>
        {form.render("type", ({ field: { onChange, value } }) => (
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={(_, val) => {
              onChange(val);
            }}
          >
            <Tab label="Income" value={TYPES.income} />
            <Tab label="Expense" value={TYPES.expense} />
            <Tab label="Transfer" value={TYPES.transfer} />
          </Tabs>
        ))}
      </AppBar>
      <DialogContent>
        {form.render("date", ({ field: { onChange, value } }) => (
          <Box flexDirection="row">
            <MobileDatePicker
              value={value}
              format="dd/MM/yyyy"
              label="Date"
              onChange={(e) => {
                if (e) {
                  onChange(
                    set(value, {
                      date: e.getDate(),
                      month: e.getMonth(),
                      year: e.getFullYear(),
                    })
                  );
                }
              }}
              onAccept={(date) => {
                if (date?.toString() === value.toString()) {
                  setTimeout(() => {
                    timeRef.current?.click();
                  }, 0);
                }
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: "dense",
                  sx: { mr: 1 },
                  helperText: " ",
                },
              }}
            />

            <MobileTimePicker
              value={value}
              format="HH:mm"
              label="Time"
              ampm={false}
              onChange={(e) => {
                if (e) {
                  onChange(
                    set(value, {
                      hours: e.getHours(),
                      milliseconds: e.getMilliseconds(),
                      minutes: e.getMinutes(),
                      seconds: e.getSeconds(),
                    })
                  );
                }
              }}
              onAccept={(date) => {
                if (date?.toString() === value.toString()) {
                  setTimeout(() => {
                    accountRef.current?.focus();
                  }, 0);
                }
              }}
              slotProps={{
                textField: {
                  inputRef: timeRef,
                  fullWidth: true,
                  margin: "dense",
                  helperText: " ",
                },
              }}
            />
          </Box>
        ))}

        <Controller
          name="account"
          control={form.control}
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.uid === value.uid}
              options={sortedAccounts}
              groupBy={(a) =>
                accountsGroups.find((ag) => ag.uid === a.groupId)?.name || ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={accountRef}
                  label="Account"
                  margin="dense"
                  helperText=" "
                />
              )}
              value={value || null}
              onChange={(__, newValue: Account | null) => {
                onChange(newValue);

                if (!newValue) {
                  return;
                }

                setTimeout(() => {
                  if (type === TYPES.transfer) {
                    accountToRef.current?.focus();
                  } else {
                    amountRef.current?.focus();
                  }
                }, 0);
              }}
            />
          )}
        />

        {type === TYPES.transfer && (
          <Controller
            name="toAccount"
            control={form.control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.uid === value.uid
                }
                options={sortedAccounts}
                groupBy={(a) =>
                  accountsGroups.find((ag) => ag.uid === a.groupId)?.name || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={accountToRef}
                    label="To account"
                    margin="dense"
                    helperText=" "
                  />
                )}
                value={value || null}
                onChange={(__, newValue: Account | null) => {
                  onChange(newValue);
                  if (newValue && selectedAccount) {
                    const rate = new Big(newValue.currency.rate)
                      .div(selectedAccount.currency.rate)
                      .toNumber();
                    form.setValue("rate", rate);
                  }

                  if (!newValue) {
                    return;
                  }

                  setTimeout(() => {
                    amountRef.current?.focus();
                  }, 0);
                }}
              />
            )}
          />
        )}

        <Input
          format={formatInputNumber}
          rules={{
            required: true,
          }}
          fullWidth
          autoComplete="off"
          label="Amount"
          name="amount"
          afterChange={
            type === TYPES.transfer
              ? () => {
                  const rate = Number(form.getValues("rate"));
                  const amount = parseNumber(form.getValues("amount"));
                  form.setValue(
                    "toAmount",
                    `${new Big(amount).mul(rate).toNumber()}`
                  );
                }
              : undefined
          }
          ref={amountRef}
          suffix={
            <InputAdornment
              sx={{
                mr: -1.5,
              }}
              position="end"
            >
              <Controller
                name="amountCurrency"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <CurrencySelector value={value} onChange={onChange} />
                )}
              />
            </InputAdornment>
          }
          {...focusRefOnEnter(type === TYPES.transfer ? rateRef : tagsRef)}
        />

        {type === TYPES.transfer && (
          <Input
            ref={rateRef}
            afterChange={() => {
              const rate = Number(form.getValues("rate"));
              const amount = parseNumber(form.getValues("amount"));
              form.setValue(
                "toAmount",
                `${new Big(amount).mul(rate).toNumber()}`
              );
            }}
            rules={{
              required: true,
            }}
            fullWidth
            label="rate"
            name="rate"
            {...focusRefOnEnter(amountToRef)}
          />
        )}

        {type === TYPES.transfer && (
          <Input
            ref={amountToRef}
            format={formatInputNumber}
            afterChange={() => {
              const toAmount = parseNumber(form.getValues("toAmount"));
              const amount = parseNumber(form.getValues("amount"));
              form.setValue("rate", new Big(toAmount).div(amount).toNumber());
            }}
            rules={{
              required: true,
            }}
            fullWidth
            label="To amount"
            name="toAmount"
            suffix={
              <InputAdornment
                sx={{
                  mr: -1.5,
                }}
                position="end"
              >
                <Controller
                  name="toAmountCurrency"
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <CurrencySelector value={value} onChange={onChange} />
                  )}
                />
              </InputAdornment>
            }
            {...focusRefOnEnter(tagsRef)}
          />
        )}

        <Controller
          name="tags"
          control={form.control}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              filterOptions={(options, params) => {
                const sortedOptions = matchSorter(options, params.inputValue);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.includes(inputValue);
                if (inputValue !== "" && !isExisting) {
                  sortedOptions.push(`+ "${inputValue}"`);
                }

                return sortedOptions;
              }}
              multiple
              freeSolo
              getOptionLabel={(option) => option}
              options={tags.map((t) => t.uid)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={tagsRef}
                  label="Tags"
                  margin="dense"
                  helperText=" "
                />
              )}
              value={value}
              onChange={(__, newValue: string[] | null) => {
                if (newValue) {
                  onChange(
                    newValue?.map((val) =>
                      val.includes(`+ "`) ? val.slice(3, -1).trim() : val
                    )
                  );
                }
              }}
            />
          )}
        />
        <Input
          label="Description"
          name="description"
          fullWidth
          multiline
          rows={3}
        />

        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {form.render(
            "isQuickAction",
            ({ field: { value: isQuickAction, onChange } }) => (
              <IconButton
                disabled={!!data.quickActionUid}
                aria-label="toggle save as quick action"
                onClick={() => {
                  onChange(!isQuickAction);
                }}
              >
                <Replay color={isQuickAction ? "primary" : "action"} />
              </IconButton>
            )
          )}

          {isQuickAction && (
            <Box flexDirection="row" alignItems="center">
              {form.render(
                "quickActionEmoji",
                ({ field: { value, onChange } }) => (
                  <EmojiSelector
                    onChange={(val) => {
                      onChange(val.emoji);
                    }}
                    render={({ open }) => (
                      <IconButton
                        onClick={open}
                        sx={{ width: 40, height: 40, mr: 1 }}
                        aria-label="select emoji"
                      >
                        {value}
                      </IconButton>
                    )}
                  />
                )
              )}

              {form.render(
                "quickActionName",
                ({ field: { onChange, value } }) => (
                  <TextField
                    label="Quick action name"
                    margin="none"
                    value={value}
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                )
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {data.quickActionUid && (
          <IconButton
            aria-label="delete quick action"
            onClick={() => {
              Prompt.show(
                "Are you sure?",
                "You are about to delete quick action."
              ).then((res) => {
                if (res && data?.quickActionUid) {
                  markQuickActionForDeletionSynced(data.quickActionUid).then(
                    () => {
                      quickActionsAtom.setState((prev) =>
                        prev.filter((t) => t.uid !== data.quickActionUid)
                      );

                      handleClose();
                    }
                  );
                }
              });
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        )}

        {data.quickActionUid && (
          <LoadingButton
            disabled={upsertQuickActionStat.loading}
            onClick={form.handleSubmit(upsertQuickAction)}
          >
            Update
          </LoadingButton>
        )}

        <Box flex={1} />

        {data?.uid && (
          <IconButton
            aria-label="delete transaction"
            onClick={() => {
              Prompt.show(
                "Are you sure?",
                "You are about to delete transaction,"
              ).then((res) => {
                if (res && data?.uid) {
                  markTransactionForDeletionSynced(data.uid).then(() => {
                    transactionsAtom.setState((prev) =>
                      prev.filter((t) => t.uid !== data.uid)
                    );

                    handleClose();
                  });
                }
              });
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        )}

        <LoadingButton
          disabled={
            upsertTransactionStat.loading || upsertQuickActionStat.loading
          }
          onClick={handleClose}
        >
          Cancel
        </LoadingButton>

        {(!isQuickAction || data.quickActionUid) && (
          <LoadingButton
            loading={upsertTransactionStat.loading}
            onClick={form.handleSubmit(upsertTransaction)}
          >
            Save
          </LoadingButton>
        )}

        {isQuickAction && !data.quickActionUid && (
          <LoadingButton
            loading={upsertQuickActionStat.loading}
            onClick={form.handleSubmit(upsertQuickAction)}
          >
            Save as Quick Action
          </LoadingButton>
        )}
      </DialogActions>
    </div>
  );
};

export default TransactionModal;
