import Big from "big.js";
import { Currency } from "types/data.types";
import { loadDb } from "./database";

export const upsertCurrency = async (
  currency: Pick<Currency, "uid" | "primary" | "rate">
) => {
  const db = await loadDb;
  const tx = db.transaction(["currencies"], "readwrite");
  const currenciesStore = tx.objectStore("currencies");
  const curr = await currenciesStore.get(currency.uid);

  const nCurrrency: Currency = {
    uid: currency.uid,
    primary: currency.primary,
    rate: currency.rate,
    createDate: curr?.createDate || new Date(),
    updateDate: new Date(),
    status: !curr ? "ADD" : undefined,
    log: [
      ...(curr?.log || []),
      {
        event: !curr ? "ADD" : "UPDATE",
        timestamp: new Date(),
        userAgent: window.navigator.userAgent,
      },
    ],
  };

  await currenciesStore.put(nCurrrency);

  return nCurrrency;
};

export const changePrimaryCurrency = async (currency: Currency) => {
  if (!currency) {
    throw new Error("Currency is required");
  }

  const db = await loadDb;

  let cursor = await db
    .transaction("currencies", "readwrite")
    .store.openCursor();

  while (cursor) {
    const cur = cursor.value;
    cur.rate = new Big(cur.rate).div(currency.rate).toNumber();
    cur.primary = cur.uid === currency.uid;
    cur.updateDate = new Date();
    await cursor.update(cur);

    cursor = await cursor.continue();
  }
};

export const getCurrencies = async () => {
  const db = await loadDb;
  return (await db.getAll("currencies")).filter((c) => c.status !== "DELETE");
};

export const markCurrencyForDeletion = async (cur: Currency) => {
  if (!cur) {
    throw new Error("Currency is required");
  }

  const db = await loadDb;
  await db.put("currencies", {
    ...cur,
    status: "DELETE",
  });
};

export const exportCurrencies = async () => {
  const db = await loadDb;
  return db.getAll("currencies");
};

export const importCurrency = async (currency: Currency) => {
  const db = await loadDb;
  await db.put("currencies", currency);
};

export const clearCurrencysState = async (currencies: Currency[]) => {
  const db = await loadDb;
  const tx = db.transaction("currencies", "readwrite");
  const currenciesStore = tx.objectStore("currencies");

  let cursor = await currenciesStore.openCursor();

  while (cursor) {
    const currency = cursor.value;

    if (currencies.some((c) => c.uid === currency.uid)) {
      currenciesStore.put({
        ...currency,
        status: undefined,
      });
    }

    cursor = await cursor.continue();
  }
};

export const deleteCurrency = async (cur: Currency) => {
  const db = await loadDb;
  await db.delete("currencies", cur.uid);
};

export const deleteAllCurrencies = async () => {
  const db = await loadDb;
  await db.clear("currencies");
};
