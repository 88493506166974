import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Query,
  QueryCondition,
  QueryConditionAccount,
  QueryConditionAmount,
  QueryConditionCurrency,
  QueryConditionDate,
  QueryConditionDescription,
  QueryConditionTags,
  QueryConditionToAccount,
  QueryConditionToAmount,
  QueryConditionType,
  QueryLogicalOperator,
} from "database";
import {
  Autocomplete,
  Menu,
  MenuItem,
  MenuItemProps,
  TextField,
} from "@mui/material";
import {
  accountsAtom,
  primaryCurrencyAtom,
  tagsAtom,
  transactionQueryAtom,
} from "utils/atoms";
import { useEffect, useRef, useState } from "react";
import { atom } from "@m1st1ck/atomjs";
import { useAtom } from "@m1st1ck/atomjs-react";
import { defaultQuery, TYPES, QueryOptions } from "utils/constants";
import Box from "components/Box";
import { useInput } from "components/FormComponents";
import { Controller, useForm } from "react-hook-form";
import { formatNumber, formatInputNumber } from "utils/format";
import { format } from "date-fns";
import { useModal } from "components/ModalProvider";
import CurrencySelector from "components/CurrencySelector";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

const forceUpdateTransactionQueryAtom = () => {
  localQueryAtom.setState((prev) => [...prev]);
};

const localQueryAtom = atom<Query>([
  [...defaultQuery[0]],
  defaultQuery[1],
  [...defaultQuery[2]],
]);

const TransactionFilterModal: React.FC = () => {
  const { closeModal } = useModal();
  const query = useAtom(localQueryAtom);

  useEffect(() => {
    localQueryAtom.setState(
      JSON.parse(JSON.stringify(transactionQueryAtom.getState()))
    );
  }, []);

  return (
    <div>
      <DialogContent sx={{ p: 1 }}>
        <QueryComponent query={query} root />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localQueryAtom.setState([
              [...defaultQuery[0]],
              defaultQuery[1],
              [...defaultQuery[2]],
            ]);
          }}
        >
          Clear
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={() => {
            transactionQueryAtom.setState(JSON.parse(JSON.stringify(query)));
            closeModal();
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </div>
  );
};

export default TransactionFilterModal;

type SelectProps<T> = {
  options: T[];
  selected?: T;
  placeholder?: string;
  onSelect: (key: T) => void;
  onLongClick?: () => void;
  variant?: ButtonProps["variant"];
  sx?: ButtonProps["sx"];
  menuItemSx?: MenuItemProps["sx"];
};

function Select<T extends string | number>({
  options,
  selected,
  placeholder = "-",
  onSelect,
  onLongClick,
  variant = "outlined",
  sx,
  menuItemSx,
}: SelectProps<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: T) => () => {
    setAnchorEl(null);
    onSelect(key);
  };

  const timeout = useRef(setTimeout(() => {}, 0));
  const openedModal = useRef(false);

  const handlePressStart = () => {
    openedModal.current = false;
    timeout.current = setTimeout(() => {
      openedModal.current = true;
      onLongClick?.();
    }, 1000);
  };

  const handlePressEnd = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    clearTimeout(timeout.current);
    if (!openedModal.current) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "initial",
          flex: 1,
          ...sx,
        }}
        variant={variant}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onTouchStart={() => {
          timeout.current = setTimeout(() => {
            onLongClick?.();
          }, 1000);
        }}
        onTouchEnd={() => {
          clearTimeout(timeout.current);
        }}
        onMouseDown={handlePressStart}
        onMouseUp={handlePressEnd}
      >
        {selected !== undefined ? selected : placeholder}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={open}
        onClose={handleClose}
      >
        {options.map((op, i) => (
          <MenuItem sx={menuItemSx} key={i} onClick={handleSelect(op)}>
            {op}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

type QueryConditionComponentProps = {
  query: Query;
  parent?: Query;
  index?: number;
};

const QueryConditionComponent: React.FC<QueryConditionComponentProps> = ({
  query,
  parent,
  index,
}) => {
  const qry = query as QueryCondition;

  return (
    <Box
      flexDirection="row"
      flex={1}
      border="1px solid rgba(144, 202, 249, 0.5)"
      borderRadius={1}
    >
      <Select
        variant="text"
        options={
          ["-", ...QueryOptions.map((o) => o.key), "[]x[]"] as (
            | QueryCondition[0]
            | "[]x[]"
            | "-"
          )[]
        }
        selected={qry[0]}
        onSelect={(key) => {
          if (key === "-") {
            if (!parent || typeof index !== "number") {
              query.splice(0, 10);
            } else if (parent.length === 3) {
              // if only 2 Query in BlockQuery convert to QueryCondition
              const lastOne = [...parent[index === 0 ? 2 : 0]];

              // convert Query to QueryCondition
              (parent as unknown as QueryCondition)[3] =
                lastOne[3] as QueryCondition[3];
              (parent as unknown as QueryCondition)[2] =
                lastOne[2] as QueryCondition[2];
              (parent as unknown as QueryCondition)[1] =
                lastOne[1] as QueryCondition[1];
              (parent as unknown as QueryCondition)[0] =
                lastOne[0] as QueryCondition[0];
            } else if (parent.length === index + 1) {
              parent?.splice(index - 1, 2);
            } else {
              parent?.splice(index, 2);
            }

            forceUpdateTransactionQueryAtom();

            return;
          }

          if (key === "[]x[]") {
            query[0] = [...qry];
            query[1] = "AND";
            query[2] = [...defaultQuery[0]];
            query.pop();
            forceUpdateTransactionQueryAtom();
            return;
          }

          const option = QueryOptions.find((o) => o.key === key);
          if (!option) {
            throw new Error("Option not found");
          }
          qry[0] = key;
          qry[1] = true;
          qry[2] = option.operators.some((o) => o === qry[2])
            ? qry[2]
            : option.operators[0];
          qry[3] = option.defaultValue;
          forceUpdateTransactionQueryAtom();
        }}
      />

      <Select
        onLongClick={() => {
          qry[1] = !qry[1];
          forceUpdateTransactionQueryAtom();
        }}
        variant="text"
        sx={{
          flex: "none",
          textDecoration: qry[1] ? undefined : "line-through",
          "&:hover": {
            textDecoration: qry[1] ? undefined : "line-through",
          },
        }}
        options={[
          qry[1] ? "NOT" : "NOT NOT",
          ...(QueryOptions.find((o) => o.key === qry[0])?.operators || []),
        ]}
        selected={qry[2]}
        onSelect={(key) => {
          if (key === "NOT" || key === "NOT NOT") {
            qry[1] = !qry[1];
          } else {
            qry[2] = key;
          }
          forceUpdateTransactionQueryAtom();
        }}
      />

      {qry[0] === "amount" && <QueryAmountComponent query={qry} />}
      {qry[0] === "toAmount" && <QueryAmountComponent query={qry} />}
      {qry[0] === "currency" && <QueryCurrencyComponent query={qry} />}
      {qry[0] === "date" && <QueryDateComponent query={qry} />}
      {qry[0] === "description" && <QueryDescriptionComponent query={qry} />}
      {qry[0] === "tags" && <QueryTagsComponent query={qry} />}
      {qry[0] === "type" && <QueryTypeComponent query={qry} />}
      {qry[0] === "account" && <QueryAccountComponent query={qry} />}
      {qry[0] === "toAccount" && <QueryAccountComponent query={qry} />}
    </Box>
  );
};

type QueryComponentProps = {
  query: Query;
  parent?: Query;
  index?: number;
  root?: boolean;
};

const QueryComponent: React.FC<QueryComponentProps> = ({
  query,
  root,
  parent,
  index,
}) => {
  if (query.length === 0) {
    return (
      <Box flex={1} alignItems="center" m={1}>
        <Button
          sx={{
            textTransform: "initial",
            flex: 1,
          }}
          variant="outlined"
          onClick={() => {
            localQueryAtom.setState([...defaultQuery[0]]);
          }}
        >
          Add
        </Button>
      </Box>
    );
  }

  if (query[1] !== "AND" && query[1] !== "OR") {
    return (
      <>
        <QueryConditionComponent query={query} parent={parent} index={index} />
        {root && (
          <Box flex={1} alignItems="center" m={1}>
            <Select
              options={["AND", "OR"] as QueryLogicalOperator[]}
              menuItemSx={{
                justifyContent: "center",
              }}
              onSelect={(key) => {
                query[0] = [...query];
                query[1] = key;
                query[2] = [...defaultQuery[0]];
                query.pop();
                forceUpdateTransactionQueryAtom();
              }}
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <Box
      p={root ? 0 : 1}
      border={root ? undefined : `1px solid rgba(144, 202, 249, 0.5)`}
      borderRadius={1}
    >
      {query.map((_, i) =>
        i % 2 === 0 ? (
          <QueryComponent
            key={i}
            query={query[i] as Query}
            parent={query}
            index={i}
          />
        ) : (
          <Box key={i} flex={1} alignItems="center" m={1}>
            <Select
              options={["AND", "OR", "-"] as (QueryLogicalOperator | "-")[]}
              selected={query[i] as QueryLogicalOperator}
              menuItemSx={{
                justifyContent: "center",
              }}
              onSelect={(key) => {
                if (key === "-") {
                  // if only 2 Query in BlockQuery convert to QueryCondition
                  if (query.length === 3) {
                    // convert Query to QueryCondition
                    (query as unknown as QueryCondition)[3] =
                      query[0][3] as QueryCondition[3];
                    (query as unknown as QueryCondition)[2] =
                      query[0][2] as QueryCondition[2];
                    (query as unknown as QueryCondition)[1] =
                      query[0][1] as QueryCondition[1];
                    (query as unknown as QueryCondition)[0] =
                      query[0][0] as QueryCondition[0];
                  } else {
                    query.splice(i, 2);
                  }

                  forceUpdateTransactionQueryAtom();
                  return;
                }
                query[i] = key;
                forceUpdateTransactionQueryAtom();
              }}
            />
          </Box>
        )
      )}

      <Box flex={1} alignItems="center" m={1}>
        <Select
          options={["AND", "OR"] as QueryLogicalOperator[]}
          menuItemSx={{
            justifyContent: "center",
          }}
          onSelect={(key) => {
            query.push(key);
            query.push([...defaultQuery[0]]);
            forceUpdateTransactionQueryAtom();
          }}
        />
      </Box>
    </Box>
  );
};

const QueryAmountComponent: React.FC<{
  query: QueryConditionAmount | QueryConditionToAmount;
}> = ({ query }) => {
  const { control, getValues } = useForm<{ amount: string }>();
  const Input = useInput(control);
  const qVal = query[3];

  return (
    <EditValueDialog
      label={formatNumber(qVal)}
      onApply={() => {
        query[3] = Number(getValues("amount"));
        forceUpdateTransactionQueryAtom();
      }}
    >
      <Input
        defaultValue={qVal.toString()}
        format={formatInputNumber}
        autoFocus
        name="amount"
        label="Amount"
      />
    </EditValueDialog>
  );
};

const QueryCurrencyComponent: React.FC<{
  query: QueryConditionCurrency;
}> = ({ query }) => {
  useEffect(() => {
    if (query[3].uid !== "??") {
      return;
    }
    const pCurrency = primaryCurrencyAtom.getState();
    if (pCurrency) {
      query[3] = pCurrency;
      forceUpdateTransactionQueryAtom();
    }
  }, [query]);

  return (
    <Box flex={1}>
      <CurrencySelector
        onChange={(val) => {
          query[3] = val;
          forceUpdateTransactionQueryAtom();
        }}
        value={query[3]}
      />
    </Box>
  );
};

const QueryDateComponent: React.FC<{ query: QueryConditionDate }> = ({
  query,
}) => {
  const qVal = new Date(query[3]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        sx={{
          textTransform: "initial",
          flex: 1,
          display: "block",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        onClick={() => {
          setOpen(true);
        }}
        variant="text"
      >
        {format(qVal, "dd/MM/yy HH:mm")}
      </Button>
      <MobileDateTimePicker
        open={open}
        value={qVal}
        ampm={false}
        slotProps={{
          textField: {
            sx: {
              display: "none",
            },
          },
        }}
        onAccept={(date) => {
          if (!date) {
            return;
          }
          query[3] = date;
          forceUpdateTransactionQueryAtom();
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={() => {}}
      />
    </>
  );
};

const QueryTagsComponent: React.FC<{ query: QueryConditionTags }> = ({
  query,
}) => {
  const [tags] = useAtom(tagsAtom);
  const { control, getValues } = useForm<{ tags: string[] }>();
  const qVal = query[3];

  return (
    <EditValueDialog
      label={qVal.length === 0 ? "-" : qVal.join(", ")}
      onApply={() => {
        query[3] = getValues("tags");
        forceUpdateTransactionQueryAtom();
      }}
    >
      <Controller
        name="tags"
        control={control}
        defaultValue={qVal}
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            multiple
            getOptionLabel={(option) => option}
            options={tags.map((t) => t.uid)}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ minWidth: 256 }}
                autoFocus
                label="Tags"
              />
            )}
            value={value}
            onChange={(__, newValue: string[]) => {
              onChange(newValue);
            }}
          />
        )}
      />
    </EditValueDialog>
  );
};

const QueryTypeComponent: React.FC<{ query: QueryConditionType }> = ({
  query,
}) => {
  return (
    <Select
      variant="text"
      options={[TYPES.expense, TYPES.income, TYPES.transfer]}
      selected={query[3]}
      onSelect={(key) => {
        query[3] = key;
        forceUpdateTransactionQueryAtom();
      }}
    />
  );
};

const QueryAccountComponent: React.FC<{
  query: QueryConditionAccount | QueryConditionToAccount;
}> = ({ query }) => {
  const [accounts] = useAtom(accountsAtom);

  return (
    <Select
      variant="text"
      options={accounts.map((a) => a.name)}
      selected={query[3] === "" ? "-" : query[3]}
      onSelect={(key) => {
        query[3] = key;
        forceUpdateTransactionQueryAtom();
      }}
    />
  );
};

const QueryDescriptionComponent: React.FC<{
  query: QueryConditionDescription;
}> = ({ query }) => {
  const { control, getValues } = useForm<{ description: string }>();
  const Input = useInput(control);
  const qVal = query[3];

  return (
    <EditValueDialog
      label={qVal || "..."}
      onApply={() => {
        query[3] = getValues("description");
        forceUpdateTransactionQueryAtom();
      }}
    >
      <Input
        autoFocus
        defaultValue={qVal}
        multiline
        rows={3}
        name="description"
        label="Description"
      />
    </EditValueDialog>
  );
};

function EditValueDialog({
  children,
  onApply,
  label,
}: {
  onApply: () => void;
  label: string;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        sx={{
          textTransform: "initial",
          flex: 1,
          display: "block",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleClose();
            onApply();
          }}
        >
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Apply</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
