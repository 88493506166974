import { Menu, MenuItem, Button } from "@mui/material";
import { useAtom } from "@m1st1ck/atomjs-react";
import { useState } from "react";
import { Currency } from "types/data.types";
import { currenciesAtom } from "utils/atoms";

type CurrencySelectorProps = {
  value: Currency;
  onChange: (val: Currency) => void;
};

export default function CurrencySelector({
  value,
  onChange,
}: CurrencySelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (c: Currency) => () => {
    onChange(c);
    setAnchorEl(null);
  };

  const [currencies] = useAtom(currenciesAtom);

  return (
    <>
      <Button onClick={handleClick}>{value.uid}</Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {currencies.map((c) => (
          <MenuItem key={c.uid} onClick={handleSelect(c)}>
            {c.uid}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
