import { useEffect, useRef } from "react";
import Big from "big.js";
import { Transaction } from "types/data.types";
import { LineChartDataType, renderLineChart } from "./Charts";
import { getAmount } from "utils/format";

export default function LineChart({
  transactionsForSelectedTag,
}: {
  transactionsForSelectedTag: Transaction[];
}) {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    let total = new Big(0);
    const data: LineChartDataType[] = transactionsForSelectedTag
      .map((tran) => ({
        amount: getAmount(tran),
        date: new Date(tran.date).getTime(),
        total: 0,
      }))
      .sort((a, b) => a.date - b.date)
      .map((tran) => {
        total = total.add(tran.amount);
        return {
          ...tran,
          total: total.toNumber(),
        };
      });

    renderLineChart(svgRef, data);
  }, [transactionsForSelectedTag]);

  return (
    <svg
      style={{
        minHeight: "calc(100vmin - 192px)",
      }}
      color="white"
      ref={svgRef}
    />
  );
}
