import { endOfMonth, startOfMonth } from "date-fns";
import {
  QueryCondition,
  QueryConditionAccount,
  QueryConditionAmount,
  QueryConditionCurrency,
  QueryConditionDate,
  QueryConditionDescription,
  QueryConditionTags,
  QueryConditionToAccount,
  QueryConditionToAmount,
  QueryConditionType,
  QueryLogicalOperator,
} from "database";

export enum TYPES {
  "expense" = "expense",
  "transfer" = "transfer",
  "income" = "income",
}

export const getColorForType = (type: TYPES) => {
  const typeToColor = {
    [TYPES.expense]: "#E06666",
    [TYPES.income]: "#6AA84F",
    [TYPES.transfer]: "#3C78D8",
  };

  return typeToColor[type] || "ghostwhite";
};

export const QueryOptions: [
  {
    key: QueryConditionTags[0];
    operators: QueryConditionTags[2][];
    defaultValue: QueryConditionTags[3];
  },
  {
    key: QueryConditionAccount[0];
    operators: QueryConditionAccount[2][];
    defaultValue: QueryConditionAccount[3];
  },
  {
    key: QueryConditionToAccount[0];
    operators: QueryConditionToAccount[2][];
    defaultValue: QueryConditionToAccount[3];
  },
  {
    key: QueryConditionCurrency[0];
    operators: QueryConditionCurrency[2][];
    defaultValue: QueryConditionCurrency[3];
  },
  {
    key: QueryConditionAmount[0];
    operators: QueryConditionAmount[2][];
    defaultValue: QueryConditionAmount[3];
  },
  {
    key: QueryConditionToAmount[0];
    operators: QueryConditionToAmount[2][];
    defaultValue: QueryConditionToAmount[3];
  },
  {
    key: QueryConditionDate[0];
    operators: QueryConditionDate[2][];
    defaultValue: QueryConditionDate[3];
  },
  {
    key: QueryConditionType[0];
    operators: QueryConditionType[2][];
    defaultValue: QueryConditionType[3];
  },
  {
    key: QueryConditionDescription[0];
    operators: QueryConditionDescription[2][];
    defaultValue: QueryConditionDescription[3];
  }
] = [
  {
    key: "tags",
    operators: ["contain", "contain all", "contain only", "contain exact"], // maybe count
    defaultValue: [],
  },
  {
    key: "account",
    operators: ["is"],
    defaultValue: "",
  },
  {
    key: "toAccount",
    operators: ["is"],
    defaultValue: "",
  },
  {
    key: "currency",
    operators: ["is"],
    defaultValue: {
      createDate: new Date(),
      updateDate: new Date(),
      primary: false,
      rate: 1,
      status: undefined,
      uid: "??",
      log: [],
    },
  },
  {
    key: "amount",
    operators: ["is", "greater than", "less than"],
    defaultValue: 0,
  },
  {
    key: "toAmount",
    operators: ["is", "greater than", "less than"],
    defaultValue: 0,
  },
  {
    key: "date",
    operators: [
      "is",
      "is after",
      "is before",
      "is after or same",
      "is before or same",
    ],
    defaultValue: new Date(),
  },
  {
    key: "type",
    operators: ["is"],
    defaultValue: TYPES.expense,
  },
  {
    key: "description",
    operators: ["is", "starts with", "ends with", "contains"],
    defaultValue: "",
  },
];

export const defaultQuery: [
  QueryCondition,
  QueryLogicalOperator,
  QueryCondition
] = [
  ["date", true, "is after or same", startOfMonth(new Date())],
  "AND",
  ["date", true, "is before or same", endOfMonth(new Date())],
];
