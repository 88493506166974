import { useEffect, useRef } from "react";
import Big from "big.js";
import { TYPES } from "utils/constants";
import { Transaction } from "types/data.types";
import {
  PieChartDataType,
  PieChartDataTypeNested,
  renderPieChart,
} from "./Charts";
import { useSearchParams } from "react-router-dom";
import { getAmount } from "utils/format";

export default function PieChart({
  transactionsForSelectedTag,
}: {
  transactionsForSelectedTag: Transaction[];
}) {
  const [searchParams] = useSearchParams();
  const path = searchParams.get("path");
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    const expenseTransactions: Transaction[] = [];

    // tags contained in transactions
    const usedTags: {
      [key: string]: {
        uid: string;
        count: number;
        income: Big;
        expense: Big;
      };
    } = {};

    // populate usedTags and expenseTransactions
    for (let i = 0; i < transactionsForSelectedTag.length; i++) {
      const tran = transactionsForSelectedTag[i];

      // don't show incomes
      if (tran.type === TYPES.income) {
        continue;
      }

      const transAmount = getAmount(tran);

      // get all tags and their usage count and total amount in transactions
      tran.tags.forEach((tag) => {
        if (!usedTags[tag]) {
          usedTags[tag] = {
            uid: tag,
            count: 0,
            income: new Big(0),
            expense: new Big(0),
          };
        }

        usedTags[tag].count++;
        usedTags[tag].expense = usedTags[tag].expense.add(transAmount);
      });

      expenseTransactions.push(tran);
    }

    const pathTags = path?.split("/") || [];
    const useddTags: string[] = [...pathTags];

    const usedTagsList = Object.values(usedTags)
      .map((t) => ({ ...t, expense: t.expense.toNumber() }))
      .sort((a, b) => a.expense - b.expense);

    const taglessTransactions: PieChartDataTypeNested[] = [];

    for (let i = 0; i < expenseTransactions.length; i++) {
      const tran = expenseTransactions[i];

      // transaction without tags with lower usage than selected tag
      if (!tran.tags.some((tag) => usedTags[tag])) {
        taglessTransactions.push({
          name: tran.description || tran.tags.join(",") || tran.uid,
          value: getAmount(tran),
        });
      }
    }

    const pieData: PieChartDataType = {
      name: path || "",
      children: taglessTransactions,
      value: 0,
    };

    function getTagChildren(tags: string[], child: PieChartDataTypeNested) {
      for (let index = 0; index < expenseTransactions.length; index++) {
        const transaction = expenseTransactions[index];

        if (!tags.every((t) => transaction.tags.includes(t))) {
          continue;
        }

        const remainingTags = transaction.tags.filter((t) => !tags.includes(t));

        if (!child.children) {
          child.children = [];
        }

        if (remainingTags.length === 0) {
          child.children!.push({
            name: transaction.description || transaction.tags.join(","),
            value: getAmount(transaction),
          });

          continue;
        }

        const mostUsed = remainingTags.reduce((tag, mostUsed) => {
          return usedTags[tag].expense
            .abs()
            .gt(new Big(usedTags[mostUsed].expense.abs()))
            ? tag
            : mostUsed;
        });

        useddTags.push(...remainingTags);

        const oChild = child.children!.find((c) => c.name === mostUsed);
        if (oChild) {
          continue;
        }

        const nChild: PieChartDataTypeNested = {
          value: 0,
          name: mostUsed,
        };

        getTagChildren([...tags, ...remainingTags], nChild);

        child.children!.push(nChild);
      }
    }

    for (let index = 0; index < usedTagsList.length; index++) {
      const tag = usedTagsList[index];

      if (useddTags.includes(tag.uid)) {
        continue;
      }

      const child: PieChartDataTypeNested = {
        name: tag.uid,
        value: 0,
        children: [],
      };

      getTagChildren([...pathTags, tag.uid], child);

      pieData.children.push(child);
    }

    renderPieChart(svgRef, pieData);
  }, [transactionsForSelectedTag, path]);

  return (
    <svg
      style={{
        minHeight: "calc(100vmin - 192px)",
      }}
      color="white"
      ref={svgRef}
    />
  );
}
