import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CssBaseline,
  LinearProgress,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ViewList,
  BarChart,
  AccountBalanceWallet,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import Dashboard from "Views/Dashboard";
import Stats from "Views/Stats";
import Accounts from "Views/Accounts";
import Settings from "Views/Settings";
import { useEffect } from "react";
import Currencies from "Views/Currencies";
import Prompt from "components/Prompt";
import {
  fetchCurrencies,
  fetchAccounts,
  fetchTags,
  fetchAccountsOrder,
  fetchQuickActions,
  fetchAccountsGroups,
  fetchAccountsGroupsOrder,
} from "utils/actions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { groupByDate } from "stocks";
import { currenciesAtom, primaryCurrencyAtom } from "utils/atoms";
import { useAtom } from "@m1st1ck/atomjs-react";
import { ModalProvider } from "components/ModalProvider";
import Tags from "Views/Tags";
import { isDriveSyncEnabled, syncAtom, syncDriveData } from "utils/dataSync";
import MSnackbar from "components/MSnackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: "#303030",
        height: "100vh",
      }}
    >
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/currencies" element={<Currencies />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>

      <Box>
        <SyncIndicator />
        <BottomNavigation
          showLabels
          value={location.pathname}
          onChange={(__, newValue) => {
            navigate(newValue);
          }}
        >
          <BottomNavigationAction value="/dashboard" icon={<ViewList />} />
          <BottomNavigationAction value="/stats" icon={<BarChart />} />
          <BottomNavigationAction
            value="/accounts"
            icon={<AccountBalanceWallet />}
          />
          <BottomNavigationAction value="/settings" icon={<SettingsIcon />} />
        </BottomNavigation>
      </Box>
    </Box>
  );
};

function SyncIndicator() {
  const sync = useAtom(syncAtom);

  if (!sync.syncing) {
    return null;
  }

  return (
    <Box width={1} position="absolute">
      <LinearProgress />
    </Box>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Root = () => {
  const [currencies] = useAtom(currenciesAtom);

  useEffect(() => {
    groupByDate();
    // TODO: don't fetch globally but on demand
    fetchTags();
    fetchCurrencies();
    fetchAccounts();
    fetchAccountsGroups();
    fetchAccountsOrder();
    fetchAccountsGroupsOrder();
    fetchQuickActions();

    isDriveSyncEnabled().then((res) => {
      if (res) {
        syncDriveData();
      }
    });

    window.onerror = () => {
      // clear storage
      console.log("ERROR -> CLEAR LOCALSTORAGE");
      localStorage.clear();

      //the error can still be triggered as usual, we just wanted to know what's happening on the client side
      return false;
    };
  }, []);

  useEffect(() => {
    primaryCurrencyAtom.setState(currencies.find((c) => c.primary));
  }, [currencies]);

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
            <ModalProvider />
            <Prompt />
            <MSnackbar />
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default Root;
