import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useInput } from "components/FormComponents";
import { Controller, useForm } from "react-hook-form";
import { Account, Currency } from "types/data.types";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useAtom } from "@m1st1ck/atomjs-react";
import { formatNumber, formatInputNumber, parseNumber } from "utils/format";
import { currenciesAtom, accountsAtom } from "utils/atoms";
import { useModal } from "components/ModalProvider";
import { Upsert } from "database";
import {
  markAccountForDeletionSynced,
  revertAccountStatusSynced,
  upsertAccountSynced,
} from "utils/databaseMiddleware";
import Prompt from "components/Prompt";
import MSnackbar from "components/MSnackbar";

type AccountFormData = Pick<Upsert<Account>, "name"> & {
  base: string;
  currency: Currency | null;
};

const AccountModal: React.FC = () => {
  const formData = useForm<AccountFormData>({
    mode: "onBlur",
  });
  const Input = useInput(formData.control);

  const { data, closeModal } = useModal<"account">();

  const [currencies] = useAtom(currenciesAtom);

  return (
    <>
      <DialogTitle>
        {!data?.uid ? "Create Account" : "Update Account"}
      </DialogTitle>
      <DialogContent>
        <Input defaultValue={data?.name} label="name" name="name" />
        <Input
          defaultValue={data?.base ? formatNumber(data?.base) : "0"}
          format={formatInputNumber}
          label="base"
          name="base"
        />

        <Controller
          name="currency"
          control={formData.control}
          defaultValue={currencies.find((c) => c.uid === data?.currency.uid)}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              filterOptions={createFilterOptions({
                stringify: (option) => `${option.uid}`,
              })}
              options={currencies}
              isOptionEqualToValue={(a, b) => a.uid === b.uid}
              getOptionLabel={(option) => option.uid}
              renderInput={(params) => (
                <TextField {...params} label="Currency" />
              )}
              value={value}
              onChange={(__, newValue: Currency | null) => {
                onChange(newValue);
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        {data?.uid !== undefined && (
          <Button
            color="error"
            onClick={() => {
              Prompt.show(
                "Delete account",
                `Are you sure that you want to delete ${data.name}? `
              ).then((res) => {
                if (res) {
                  closeModal();
                  markAccountForDeletionSynced(data.uid!).then(() => {
                    MSnackbar.show("Deleted", {
                      action: (
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => {
                            MSnackbar.hide();
                            revertAccountStatusSynced(data.uid!).then((acc) => {
                              accountsAtom.setState((prev) => [...prev, acc]);
                            });
                          }}
                        >
                          UNDO
                        </Button>
                      ),
                    });
                  });

                  accountsAtom.setState((prev) =>
                    prev.filter((a) => a.uid !== data.uid)
                  );
                }
              });
            }}
          >
            Delete
          </Button>
        )}

        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={formData.handleSubmit((formData) => {
            upsertAccountSynced({
              base: parseNumber(formData.base),
              currency: {
                uid: formData.currency!.uid,
                rate: formData.currency!.rate,
              },
              name: formData.name,
              uid: data?.uid,
              groupId: data?.groupId || null,
            }).then((acc) => {
              accountsAtom.setState((prev) => [
                ...prev.filter((acc) => acc.uid !== data?.uid),
                acc,
              ]);
              closeModal();
            });
          })}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AccountModal;
