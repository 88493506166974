import { AppBar, Fab, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useAtom } from "@m1st1ck/atomjs-react";
import {
  accountsAtom,
  multipleDeletionAtom,
  quickActionsAtom,
  transactionQueryAtom,
  transactionsAtom,
} from "utils/atoms";
import { fetchTransactions } from "utils/actions";
import Box from "components/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useModal } from "components/ModalProvider";
import { openQuickTagsModal } from "modals/QuickTagsModal";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import Prompt from "components/Prompt";
import MonthSelector from "components/MonthSelector";
import { markTransactionForDeletionSynced } from "utils/databaseMiddleware";
import { useNavigate } from "react-router-dom";
import { TransactionsList } from "components/TransactionsList";

const Dashboard = () => {
  const query = useAtom(transactionQueryAtom);
  const { openModal } = useModal();

  useEffect(() => {
    fetchTransactions(query);
  }, [query]);

  const [transactions] = useAtom(transactionsAtom);

  const scrollContainer = useRef<HTMLDivElement | null>(null);

  return (
    <Box flex={1} overflow="hidden">
      <AppBar position="relative">
        <Box alignItems="center" flexDirection="row">
          <Box flex={1} alignItems="flex-start">
            <Tooltip enterDelay={666} title="Delete multiple">
              <IconButton
                sx={{
                  zIndex: 1,
                }}
                onClick={() => {
                  const multipleDeletion = multipleDeletionAtom.getState();
                  if (!multipleDeletion.enabled) {
                    multipleDeletionAtom.setState({ enabled: true, items: [] });
                    return;
                  }

                  if (multipleDeletion.items.length > 0) {
                    Prompt.show(
                      "Are you sure you want to delete?",
                      `${multipleDeletion.items.length} transactions -
                    ${multipleDeletion.items
                      .map((i) => i.amount.value)
                      .join(`, `)}`
                    ).then((res) => {
                      if (res) {
                        const p = multipleDeletion.items.map((item) =>
                          markTransactionForDeletionSynced(item.uid)
                        );
                        Promise.all(p).then(() => {
                          transactionsAtom.setState((trans) =>
                            trans.filter(
                              ({ uid }) =>
                                !multipleDeletion.items.some(
                                  (t) => t.uid === uid
                                )
                            )
                          );
                          multipleDeletionAtom.reset();
                        });
                      }
                    });
                  } else {
                    multipleDeletionAtom.setState({
                      enabled: false,
                      items: [],
                    });
                  }
                }}
                aria-label="open quick tags modal"
              >
                <DeleteSweepIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <MonthSelector />
          <Box flex={1} flexDirection="row" justifyContent="flex-end">
            <Tooltip enterDelay={666} title="Quick tags">
              <IconButton
                onClick={openQuickTagsModal}
                aria-label="open quick tags modal"
              >
                <ContentPasteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip enterDelay={666} title="Transactions filter">
              <IconButton
                onClick={() => {
                  openModal("transactionFilter", undefined);
                }}
                aria-label="open filter"
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </AppBar>

      <Box sx={{ overflow: "overlay" }} ref={scrollContainer} flex={1}>
        <TransactionsList
          transactions={transactions}
          scrollContainer={scrollContainer}
        />
        <DashboardActions />
      </Box>
    </Box>
  );
};

export default Dashboard;

function DashboardActions() {
  const { openModal } = useModal();
  const [quickActions] = useAtom(quickActionsAtom);
  const navigate = useNavigate();

  return (
    <Box
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      bottom={64}
      left={8}
      right={8}
      position="fixed"
    >
      {quickActions.map((qa) => (
        <Fab
          key={qa.uid}
          size="medium"
          sx={{
            backgroundColor: "#303030",
            mr: 1,
            fontSize: 21,
          }}
          aria-label={`quick action - ${qa.name}`}
          onClick={() => {
            openModal("transaction", {
              ...qa.transaction,
              quickActionUid: qa.uid,
              quickActionEmoji: qa.emoji,
              quickActionName: qa.name,
            });
          }}
        >
          {qa.emoji}
        </Fab>
      ))}
      <Fab
        color="primary"
        aria-label="add transaction"
        onClick={() => {
          const accounts = accountsAtom.getCoreState();
          if (accounts.length === 0) {
            Prompt.show("No accounts", "", {
              actions: "Add",
            }).then(() => {
              navigate("/accounts");
            });
            return;
          }

          openModal("transaction", {});
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
