import { format } from "date-fns";

import { Box, Typography } from "@mui/material";

type TimeBoxProps = {
  date: Date;
};

const TimeBox: React.FC<TimeBoxProps> = ({ date }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 0.5,
      }}
    >
      <Typography color="textSecondary" variant="caption">
        {format(date, "HH")}
      </Typography>
      <Typography color="textSecondary" variant="caption">
        {format(date, "mm")}
      </Typography>
    </Box>
  );
};

export default TimeBox;
