import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAtom } from "@m1st1ck/atomjs-react";
import {
  accountsAtom,
  accountsGroupsAtom,
  accountsGroupsOrderAtom,
  accountsOrderAtom,
  currenciesAtom,
} from "utils/atoms";
import { Account, CurrencyClean, Transaction } from "types/data.types";
import { fetchAccounts, fetchTags } from "utils/actions";
import { upsertTransactionSynced } from "utils/databaseMiddleware";
import { useModal } from "components/ModalProvider";
import { Close } from "@mui/icons-material";
import Box from "components/Box";
import { LoadingButton } from "@mui/lab";
import { useAsync } from "@m1st1ck/useasync";

export default function ImportToAccountModal() {
  const { closeModal } = useModal();
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [json, setJson] = useState<string>("");
  const [file, setFile] = useState<File | undefined>();
  const [accounts] = useAtom(accountsAtom);
  const [accountsGroups] = useAtom(accountsGroupsAtom);
  const [accountsOrder] = useAtom(accountsOrderAtom);
  const [accountsGroupsOrder] = useAtom(accountsGroupsOrderAtom);

  const [importData, importDataStat] = useAsync(async () => {
    try {
      if (!selectedAccount) {
        return;
      }

      const parsedJson = (await (file
        ? new Promise((resolve) => {
            const reader = new FileReader();

            reader.addEventListener("load", () => {
              resolve(JSON.parse(reader.result as string));
            });

            reader.readAsText(file);
          })
        : Promise.resolve(JSON.parse(json)))) as (Transaction & {
        _currency?: string;
        _toAccount?: string;
        _toAmount?: number;
        _toCurrency?: string;
      })[];

      const currencies = currenciesAtom.getCoreState();

      const accCurr =
        currencies.find((c) => c.uid === selectedAccount.currency.uid) ||
        selectedAccount.currency;

      const getCurrency = (currency?: string): CurrencyClean | null => {
        if (!currency) {
          return accCurr;
        }

        const amountCurr = currencies.find(
          (c) => c.uid.toLowerCase() === currency?.toLowerCase()
        );

        if (!amountCurr) {
          // error
          return null;
        }

        return {
          uid: amountCurr.uid,
          rate: amountCurr.rate,
        };
      };

      await Promise.all(
        parsedJson.map((t) => {
          const {
            _currency,
            _toAccount,
            _toAmount,
            _toCurrency,
            ...transaction
          } = t;

          if (typeof transaction.amount === "number") {
            const currency = getCurrency(_currency);

            if (!currency) {
              // alert(`Currency not found ${_currency}!`);
              return Promise.resolve();
            }

            transaction.amount = {
              value: transaction.amount,
              currency,
            };
          }

          if (
            transaction.toAccount &&
            transaction.toAccount.name === transaction.account?.name
          ) {
            transaction.toAccount = {
              name: selectedAccount.name,
              uid: selectedAccount.uid,
            };
          } else if (transaction.toAccount) {
            const toAccount = accounts.find(
              (acc) => acc.name === transaction.toAccount.name
            );

            if (toAccount) {
              transaction.toAccount = {
                name: toAccount.name,
                uid: toAccount.uid,
              };
            }
          }

          transaction.account = {
            name: selectedAccount.name,
            uid: selectedAccount.uid,
          };

          if (_toAmount) {
            const toAccount = accounts.find(
              (acc) => acc.name === (_toAccount || selectedAccount.name)
            );

            if (!toAccount) {
              alert(`Account not found ${transaction.toAccount}!`);
              return Promise.resolve();
            }

            const currency = getCurrency(_toCurrency || _currency);

            if (!currency) {
              // alert(`Currency not found ${_toCurrency}!`);
              return Promise.resolve();
            }

            transaction.toAccount = {
              name: toAccount.name,
              uid: toAccount.uid,
            };

            transaction.toAmount = {
              value: _toAmount,
              currency,
            };
          }

          return upsertTransactionSynced({
            ...transaction,
            date: new Date(transaction.date),
          });
        })
      );

      await fetchTags();
      await fetchAccounts();
      closeModal();
    } catch (error) {
      console.log(error);
      alert("Failed to parse json");
    }
  });

  const sortedAccounts = accounts
    .sort(
      (a, b) =>
        accountsOrder[a.groupId || "NOT_IN_GROUP_ID"]?.indexOf(a.uid) -
        accountsOrder[b.groupId || "NOT_IN_GROUP_ID"]?.indexOf(b.uid)
    )
    .sort(
      (a, b) =>
        accountsGroupsOrder.indexOf(a.groupId || "") -
        accountsGroupsOrder.indexOf(b.groupId || "")
    );

  return (
    <>
      <DialogTitle>Import to Account</DialogTitle>
      <DialogContent sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Autocomplete
          getOptionLabel={(option) => option.name}
          options={sortedAccounts}
          groupBy={(a) =>
            accountsGroups.find((ag) => ag.uid === a.groupId)?.name || ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Account"
              margin="dense"
              helperText=" "
            />
          )}
          value={selectedAccount}
          onChange={(__, newValue: Account | null) => {
            setSelectedAccount(newValue);
          }}
        />
        <Button
          onClick={async () => {
            const element = document.createElement("input");
            element.setAttribute("type", "file");
            element.setAttribute("accept", ".json");
            element.style.display = "none";

            const promise = new Promise((resolve) => {
              element.addEventListener("change", () => {
                const files = element.files;

                if (!files || files.length === 0) {
                  resolve(null);
                  return;
                }

                setFile(files.item(0) || undefined);
                resolve(null);
              });
            });

            document.body.appendChild(element);

            element.click();

            await promise;

            document.body.removeChild(element);
          }}
          variant="outlined"
        >
          {!file && <>Add File</>}

          {file && (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>{file.name}</Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(undefined);
                }}
              >
                <Close />
              </IconButton>
            </Box>
          )}
        </Button>
        <TextField
          disabled={!!file}
          style={{
            flex: 1,
            display: "flex",
          }}
          InputProps={{
            inputProps: {
              style: {
                flex: 1,
                overflow: "auto",
              },
            },
            sx: {
              flex: 1,
              flexDirection: "column",
            },
          }}
          autoFocus
          margin="dense"
          label="JSON"
          fullWidth
          multiline
          minRows={10}
          value={json}
          onChange={(e) => {
            setJson(e.target.value);
          }}
          variant="outlined"
          placeholder={`[
    {
      "date": "2022-02-27T22:00:00.000Z",
      "amount": 2.5,
      "description": "...",
      "type": "expense"
    },
    ...
  ]`}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={importDataStat.loading}
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <LoadingButton loading={importDataStat.loading} onClick={importData}>
          Import
        </LoadingButton>
      </DialogActions>
    </>
  );
}
