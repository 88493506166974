import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useAtom } from "@m1st1ck/atomjs-react";
import { formatNumber } from "utils/format";
import {
  accountsAtom,
  accountsGroupsAtom,
  accountsGroupsOrderAtom,
  accountsOrderAtom,
  accountsTotalAtom,
  currenciesAtom,
} from "utils/atoms";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Box from "components/Box";
import { useEffect, useState } from "react";
import Big from "big.js";
import { fetchAccountsTotal } from "utils/actions";
import AccountItem from "./Accounts/AccountItem";
import AccountAddButton from "./Accounts/AccountAddButton";
import { AccountsGroup } from "types/data.types";
import { DragIndicator, Edit } from "@mui/icons-material";
import {
  updateAccountsGroupsOrderSynced,
  updateAccountsOrderSynced,
  upsertAccountSynced,
} from "utils/databaseMiddleware";
import { useModal } from "components/ModalProvider";

const Accounts = () => {
  const [accounts] = useAtom(accountsAtom);
  const [accountsGroups] = useAtom(accountsGroupsAtom);
  const [accountsOrder] = useAtom(accountsOrderAtom);
  const [accountsGroupsOrder] = useAtom(accountsGroupsOrderAtom);

  const sortedAccountsGroups = accountsGroups.sort(
    (a, b) =>
      accountsGroupsOrder.indexOf(a.uid) - accountsGroupsOrder.indexOf(b.uid)
  );

  const accountsNotInGroup = accounts.filter(
    (acc) => !acc.groupId || !accountsGroups.some((g) => g.uid === acc.groupId)
  );

  const sortedAccountsNotInGroup = accountsNotInGroup.sort(
    (a, b) =>
      accountsOrder["NOT_IN_GROUP_ID"]?.indexOf(a.uid) -
      accountsOrder["NOT_IN_GROUP_ID"]?.indexOf(b.uid)
  );

  useEffect(() => {
    fetchAccountsTotal();
  }, []);

  const onDragEnd = ({
    destination,
    source,
    draggableId,
    type,
  }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    if (type === "group") {
      const withoutSourceItem = [
        ...sortedAccountsGroups.slice(0, source.index),
        ...sortedAccountsGroups.slice(source.index + 1),
      ];

      const newAccountsGroupsOrder = [
        ...withoutSourceItem.slice(0, destination.index),
        sortedAccountsGroups[source.index],
        ...withoutSourceItem.slice(destination.index),
      ].map((ag) => ag.uid);

      accountsGroupsOrderAtom.setState(newAccountsGroupsOrder);

      updateAccountsGroupsOrderSynced(newAccountsGroupsOrder);

      return;
    }

    const sourceGroupIdString = source.droppableId.slice(19);
    const destinationGroupIdString = destination.droppableId.slice(19);
    const sourceGroupId =
      sourceGroupIdString === "none" ? "NOT_IN_GROUP_ID" : sourceGroupIdString;
    const destinationGroupId =
      destinationGroupIdString === "none"
        ? "NOT_IN_GROUP_ID"
        : destinationGroupIdString;

    const account = accounts.find((a) => a.uid === draggableId);

    if (!account) {
      return;
    }

    // move groups
    if (sourceGroupId !== destinationGroupId) {
      accountsAtom.setState((prev) =>
        prev.map((acc) =>
          acc.uid === account.uid
            ? {
                ...acc,
                groupId:
                  destinationGroupId === "NOT_IN_GROUP_ID"
                    ? null
                    : destinationGroupId,
              }
            : acc
        )
      );

      upsertAccountSynced({
        ...account,
        groupId: destinationGroupId,
      });
    }

    if (!accountsOrder[sourceGroupId]) {
      accountsOrder[sourceGroupId] = accounts
        .filter(
          (acc) =>
            acc.groupId === sourceGroupId ||
            (sourceGroupId === "NOT_IN_GROUP_ID" &&
              (!acc.groupId ||
                !accountsGroups.some((g) => g.uid === acc.groupId)))
        )
        .map((a) => a.uid);
    }

    if (
      accountsOrder[sourceGroupId] &&
      accountsOrder[sourceGroupId].indexOf(draggableId) > -1
    ) {
      accountsOrder[sourceGroupId].splice(
        accountsOrder[sourceGroupId].indexOf(draggableId),
        1
      );
    }

    if (!accountsOrder[destinationGroupId]) {
      accountsOrder[destinationGroupId] = accounts
        .filter(
          (acc) =>
            acc.groupId === destinationGroupId ||
            (destinationGroupId === "NOT_IN_GROUP_ID" &&
              (!acc.groupId ||
                !accountsGroups.some((g) => g.uid === acc.groupId)))
        )
        .map((a) => a.uid);
    }

    accountsOrder[destinationGroupId].splice(destination.index, 0, draggableId);

    accountsOrderAtom.setState(accountsOrder);
    updateAccountsOrderSynced(accountsOrder);
  };

  return (
    <Box p={1} pt={4} overflow="auto" flex={1}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`droppable-accounts-none`} type="account">
          {(provided) => (
            <Paper
              sx={{ mb: 1 }}
              elevation={3}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortedAccountsNotInGroup?.map((acc, i) => (
                <AccountItem account={acc} key={acc.uid} index={i} />
              ))}
              {provided.placeholder}
            </Paper>
          )}
        </Droppable>

        <Droppable droppableId={`droppable-groups`} type="group">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {sortedAccountsGroups.map((g, i) => (
                <AccountsGroupComponent key={g.uid} group={g} i={i} />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <Box bottom={64} right={8} position="fixed">
        <AccountAddButton />
      </Box>
    </Box>
  );
};

export default Accounts;

function AccountsGroupComponent({
  group,
  i,
}: {
  group: AccountsGroup;
  i: number;
}) {
  const { openModal } = useModal();
  const [currencies] = useAtom(currenciesAtom);
  const [accountsTotal, accountsTotalStat] = useAtom(accountsTotalAtom);

  const [accounts] = useAtom(accountsAtom);
  const [accountsOrder] = useAtom(accountsOrderAtom);

  const accountsInGroup = accounts.filter((acc) => acc.groupId === group.uid);
  const sortedAccountsInGroup = accountsInGroup.sort(
    (a, b) =>
      accountsOrder[group.uid]?.indexOf(a.uid) -
      accountsOrder[group.uid]?.indexOf(b.uid)
  );

  const groupTotal = sortedAccountsInGroup.reduce((accTotal, val) => {
    const accCur =
      currencies.find((c) => c.uid === val.currency.uid) || val.currency;

    return (
      Object.keys(accountsTotal[val.uid] || {})
        .reduce((acc, cName) => {
          const cur =
            currencies.find((c) => c.uid === cName) ||
            accountsTotal[val.uid][cName].currency;

          return accountsTotal[val.uid][cName].value
            .times(accCur.rate)
            .div(cur?.rate || 1)
            .plus(acc);
        }, new Big(0))
        .plus(val.base)
        .div(accCur.rate)
        .plus(accTotal)
        .toNumber() || 0
    );
  }, 0);

  const groupTotalString = accountsTotalStat.loaded
    ? formatNumber(groupTotal)
    : "";

  const [isOpened, setIsOpened] = useState(i === 0);

  return (
    <Draggable draggableId={group.uid} index={i}>
      {(provided) => (
        <Droppable
          droppableId={`droppable-accounts-${group.uid}`}
          type="account"
        >
          {(dropProvided, snapshot) => (
            <Accordion
              ref={provided.innerRef}
              {...provided.draggableProps}
              expanded={isOpened}
              onChange={(_, expanded) => {
                setIsOpened(expanded);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`Accounts group - ${group.name}`}
              >
                <Box
                  {...provided.dragHandleProps}
                  sx={{
                    width: 40,
                    height: 40,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DragIndicator />
                </Box>
                <Box flexDirection="row" alignItems="center" flex={1}>
                  <Typography sx={{ flex: 1 }}>
                    {`${group.name} - ${groupTotalString}`}
                    {accountsTotalStat.loading && (
                      <CircularProgress size={14} sx={{ ml: 1 }} />
                    )}
                  </Typography>

                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal("accountGroup", group);
                    }}
                    aria-label="edit accounts group"
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  sx={{ mb: 1 }}
                  elevation={3}
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  {sortedAccountsInGroup?.map((acc, i) => (
                    <AccountItem account={acc} key={acc.uid} index={i} />
                  ))}
                  {dropProvided.placeholder}
                </Paper>
              </AccordionDetails>
            </Accordion>
          )}
        </Droppable>
      )}
    </Draggable>
  );
}
