import { Dialog } from "@mui/material";
import AccountModal from "modals/AccountModal";
import TransactionFilterModal from "modals/TransactionFilterModal";
import TransactionModal from "modals/TransactionModal";
import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Account, AccountsGroup, Transaction } from "types/data.types";
import { Upsert } from "database";
import ImportToAccountModal from "modals/ImportToAccountModal";
import ExportFromAccountModal from "modals/ExportFromAccountModal";
import AccountGroupModal from "modals/AccountGroupModal";

type Modals = {
  transaction: Partial<Upsert<Transaction>> & {
    quickActionUid?: string;
    quickActionName?: string;
    quickActionEmoji?: string;
  };
  transactionFilter: undefined;
  account: Upsert<Account> | undefined;
  accountGroup: Upsert<AccountsGroup>;
  importToAccount: undefined;
  exportFromAccount: undefined;
};

const MODALS: {
  [key in keyof Modals]: React.FC;
} = {
  transaction: TransactionModal,
  transactionFilter: TransactionFilterModal,
  account: AccountModal,
  accountGroup: AccountGroupModal,
  importToAccount: ImportToAccountModal,
  exportFromAccount: ExportFromAccountModal,
};

export function useModal<T extends keyof Modals>() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  //  TODO: figure how to set undefined data to optional
  function openModal<P extends keyof Modals>(name: P, data: Modals[P]) {
    searchParams.set("modal", name);
    setSearchParams(searchParams, { state: data });
  }

  const closeModal = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return { openModal, closeModal, data: location.state as Modals[T] };
}

export const ModalProvider = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const modalName = searchParams.get("modal") as keyof Modals | null;

  if (!modalName || !MODALS[modalName]) {
    return null;
  }

  const Modal = MODALS[modalName];

  return (
    <Dialog
      fullScreen
      open
      onClose={() => {
        navigate(-1);
      }}
    >
      <Modal />
    </Dialog>
  );
};
