export type StorageKeys = "auth_refresh_token" | "auth_token" | "user-language";

type StorageObject<T> = {
  value: T;
  expiration?: Date;
};

const setItem =
  (storage: Storage) =>
  <T>(key: StorageKeys, value: T, expiration?: number) => {
    try {
      const storageObject: StorageObject<T> = { value };
      if (expiration) {
        storageObject.expiration = new Date(Date.now() + expiration);
      }
      storage.setItem(key, JSON.stringify(storageObject));
    } catch (error) {
      console.error("storage failed to set", value, "with key", key, error);
    }
  };

const getItem = (storage: Storage) => {
  function exec<T>(key: StorageKeys, v2?: undefined): T | undefined;
  function exec<T>(key: StorageKeys, v2: T): T;
  function exec<T>(key: StorageKeys, fallbackValue: T) {
    try {
      const storageData = storage.getItem(key);
      if (!storageData) {
        return fallbackValue;
      }
      const storageObject: StorageObject<T> = JSON.parse(storageData);
      if (
        storageObject.expiration &&
        new Date() > new Date(storageObject.expiration)
      ) {
        storage.removeItem(key);
        return fallbackValue;
      }

      return storageObject.value;
    } catch (error) {
      console.error("storage failed to get", key, error);
    }

    return fallbackValue;
  }

  return exec;
};

const removeItem = (storage: Storage) => (key: StorageKeys) => {
  try {
    storage.removeItem(key);
  } catch (error) {
    console.error("storage failed to delete", key, error);
  }
};

const setCookie = <T>(
  key: StorageKeys,
  value: T,
  {
    expiration,
    secure = false,
    path = "/",
  }: {
    expiration?: number;
    secure?: boolean;
    path?: string;
  } = {}
) => {
  try {
    const expires = expiration
      ? `;expires=${new Date(Date.now() + expiration).toUTCString()}`
      : "";
    const secureString = secure ? ";secure" : "";
    const pathString = path ? `;path=${path}` : "";
    const cookieValue = encodeURIComponent(JSON.stringify(value));
    document.cookie = `${key}=${cookieValue}${expires}${secureString}${pathString}`;
  } catch (error) {
    console.error(
      "storage failed to set cookie",
      value,
      "with key",
      key,
      error
    );
  }
};

const getCookie = <T>(key: StorageKeys, fallbackValue?: T) => {
  try {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return JSON.parse(c.substring(name.length, c.length)) as T;
      }
    }
  } catch (error) {
    console.error("storage failed to get cookie", key, error);
  }

  return fallbackValue;
};

const removeCookie = (key: StorageKeys) => {
  try {
    const expirationDate = new Date(Date.now() - 666);
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `${key}=${JSON.stringify(null)};${expires};path=/`;
  } catch (error) {
    console.error("storage failed to delete cookie", key, error);
  }
};

const isServer = typeof window === "undefined";
const localStorage = isServer ? ({} as Storage) : window.localStorage;
const sessionStorage = isServer ? ({} as Storage) : window.sessionStorage;

const storage = {
  cookie: {
    /** expiration in milliseconds */
    set: setCookie,
    get: getCookie,
    remove: removeCookie,
  },
  local: {
    /** expiration in milliseconds */
    set: setItem(localStorage),
    get: getItem(localStorage),
    remove: removeItem(localStorage),
  },
  session: {
    /** expiration in milliseconds */
    set: setItem(sessionStorage),
    get: getItem(sessionStorage),
    remove: removeItem(sessionStorage),
  },
};

export default storage;
