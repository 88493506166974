import { openDB, DBSchema } from "idb";
import {
  Account,
  AccountsGroup,
  Base,
  Currency,
  Profile,
  QuickAction,
  Tag,
  Transaction,
} from "types/data.types";

export type Upsert<T extends Base> = Omit<
  T,
  "createDate" | "updateDate" | "uid" | "status" | "log"
> &
  Partial<Pick<T, "uid">>;

interface MyDB extends DBSchema {
  transactions: {
    value: Transaction;
    key: string; // uid
    indexes: {
      date: Date;
      account: string;
    };
  };
  quickActions: {
    value: QuickAction;
    key: string; // uid
  };
  profile: {
    value: Profile;
    key: string; // uid
  };
  accounts: {
    value: Account;
    key: string; // uid
  };
  accountsGroups: {
    value: AccountsGroup;
    key: string; // uid
  };
  tags: {
    value: Tag;
    key: string; // name
  };
  currencies: {
    value: Currency;
    key: string; // name
  };
}

export const loadDb = openDB<MyDB>("fundsninja", 4, {
  upgrade(db, oldversion) {
    console.log({ oldversion });
    if (oldversion < 1) {
      const transactionsStore = db.createObjectStore("transactions", {
        keyPath: "uid",
      });
      transactionsStore.createIndex("date", "date", {
        unique: false,
      });
      transactionsStore.createIndex("account", "account", { unique: false });

      db.createObjectStore("accounts", {
        keyPath: "uid",
      });

      db.createObjectStore("tags", {
        keyPath: "uid",
      });

      db.createObjectStore("currencies", {
        keyPath: "uid",
      });
    }

    if (oldversion < 2) {
      db.createObjectStore("profile", {
        keyPath: "uid",
      });
    }

    if (oldversion < 3) {
      db.createObjectStore("quickActions", {
        keyPath: "uid",
      });
    }

    if (oldversion < 4) {
      db.createObjectStore("accountsGroups", {
        keyPath: "uid",
      });
    }
  },
});

loadDb.then((db) => {
  db.onversionchange = function () {
    db.close();
    alert("Database is outdated, please reload the page.");
  };
});
