import { Button } from "@mui/material";
import Box from "components/Box";
import Big from "big.js";
import { formatNumber, getAmount } from "utils/format";
import { Transaction } from "types/data.types";
import { useSearchParams } from "react-router-dom";
import { TransactionsList } from "components/TransactionsList";

export default function TagsStats({
  scrollContainer,
  transactionsForSelectedTag,
}: {
  scrollContainer: React.MutableRefObject<HTMLDivElement | null>;
  transactionsForSelectedTag: Transaction[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const path = searchParams.get("path");
  const pathTags = path?.split("/") || [];

  // tags contained in transactions
  const usedTags: {
    [key: string]: {
      uid: string;
      count: number;
      income: Big;
      expense: Big;
    };
  } = {};

  // populate usedTags and transactionsForSelectedTag, filtering out transfers
  for (let i = 0; i < transactionsForSelectedTag.length; i++) {
    const tran = transactionsForSelectedTag[i];

    // get all tags and their usage count and total amount in transactions
    tran.tags.forEach((tag) => {
      if (!usedTags[tag]) {
        usedTags[tag] = {
          uid: tag,
          count: 0,
          income: new Big(0),
          expense: new Big(0),
        };
      }

      usedTags[tag].count++;
    });
  }

  const maxCount =
    pathTags.length > 0
      ? usedTags[pathTags[pathTags.length - 1]]?.count || -1
      : -1;

  // if there is selected tag, filter out tags with higher or same usage
  if (maxCount > -1) {
    Object.keys(usedTags).forEach((key) => {
      if (usedTags[key].count >= maxCount) {
        delete usedTags[key];
      }
    });
  }

  const taglessTransactions: Transaction[] = [];

  for (let i = 0; i < transactionsForSelectedTag.length; i++) {
    const tran = transactionsForSelectedTag[i];

    // transaction without tags with lower usage than selected tag
    if (!tran.tags.some((tag) => usedTags[tag])) {
      taglessTransactions.push(tran);
      continue;
    }

    // most used tag for this transaction
    const tag = tran.tags.reduce(
      (mostUsed, next) =>
        mostUsed.count > (usedTags[next]?.count || -1)
          ? mostUsed
          : usedTags[next],
      {
        uid: "unknown",
        count: 0,
        income: new Big(0),
        expense: new Big(0),
      }
    );

    const transAmount = getAmount(tran);

    if (transAmount >= 0) {
      usedTags[tag.uid].income = usedTags[tag.uid].income.add(transAmount);
    } else {
      usedTags[tag.uid].expense = usedTags[tag.uid].expense.add(transAmount);
    }
  }

  const usedTagsList = Object.values(usedTags).sort((a, b) =>
    b.income.add(b.expense).abs().sub(a.income.add(a.expense).abs()).toNumber()
  );

  return (
    <>
      <Box flexDirection="row" flexWrap="wrap" mt={1}>
        {usedTagsList.map((item) => [
          item.income.toNumber() !== 0 ? (
            <Button
              key={item.uid + "_income"}
              onClick={() => {
                searchParams.set(
                  "path",
                  (path || "") + (path ? "/" : "") + item.uid
                );
                setSearchParams(searchParams);
              }}
              variant="outlined"
              color={item.income.gte(new Big(0)) ? "success" : "error"}
              sx={{
                m: [0.5, 0.5, 1],
                textTransform: "none",
                px: [0.5, 0.5, 2],
                py: [0, 0, 1],
              }}
            >
              {item.uid} {formatNumber(item.income.toNumber())}
            </Button>
          ) : null,
          item.expense.toNumber() !== 0 ? (
            <Button
              key={item.uid + "_expense"}
              onClick={() => {
                searchParams.set(
                  "path",
                  (path || "") + (path ? "/" : "") + item.uid
                );
                setSearchParams(searchParams);
              }}
              variant="outlined"
              color={item.expense.gte(new Big(0)) ? "success" : "error"}
              sx={{
                m: [0.5, 0.5, 1],
                textTransform: "none",
                px: [0.5, 0.5, 2],
                py: [0, 0, 1],
              }}
            >
              {item.uid} {formatNumber(item.expense.toNumber())}
            </Button>
          ) : null,
        ])}
      </Box>

      <TransactionsList
        transactions={taglessTransactions}
        scrollContainer={scrollContainer}
      />
    </>
  );
}
