import { Account } from "types/data.types";
import { v4 } from "uuid";
import { loadDb, Upsert } from "./database";

export const upsertAccount = async (account: Upsert<Account>) => {
  const db = await loadDb;
  const tx = db.transaction(["accounts"], "readwrite");
  const accountsStore = tx.objectStore("accounts");
  const acc = account.uid ? await accountsStore.get(account.uid) : undefined;

  const nAccount: Account = {
    ...account,
    updateDate: new Date(),
    createDate: acc?.createDate || new Date(),
    uid: acc?.uid || v4(),
    status: !acc ? "ADD" : undefined,
    log: [
      ...(acc?.log || []),
      {
        event: !acc ? "ADD" : "UPDATE",
        timestamp: new Date(),
        userAgent: window.navigator.userAgent,
      },
    ],
  };

  await accountsStore.put(nAccount);

  return nAccount;
};

export const getAccounts = async () => {
  const db = await loadDb;
  return (await db.getAll("accounts")).filter((c) => c.status !== "DELETE");
};

export const markAccountForDeletion = async (id: Account["uid"]) => {
  if (!id) {
    throw new Error("Account id is required");
  }

  const db = await loadDb;
  const tx = db.transaction(["accounts"], "readwrite");
  const accountsStore = tx.objectStore("accounts");
  const acc = await accountsStore.get(id);

  if (!acc) {
    throw new Error("Account not found");
  }

  await accountsStore.put({
    ...acc,
    status: "DELETE",
  });
};

export const revertAccountStatus = async (id: Account["uid"]) => {
  if (!id) {
    throw new Error("Account id is required");
  }

  const db = await loadDb;
  const tx = db.transaction(["accounts"], "readwrite");
  const accountsStore = tx.objectStore("accounts");
  const acc = await accountsStore.get(id);

  if (!acc) {
    throw new Error("Account not found");
  }

  const nAcc = {
    ...acc,
    status: undefined,
    updateDate: new Date(),
  };

  await accountsStore.put(nAcc);

  return nAcc;
};

export const exportAccounts = async () => {
  const db = await loadDb;
  return db.getAll("accounts");
};

export const importAccount = async (account: Account) => {
  const db = await loadDb;
  await db.put("accounts", account);
};

export const clearAccountsState = async (accounts: Account[]) => {
  const db = await loadDb;
  const tx = db.transaction("accounts", "readwrite");
  const accountsStore = tx.objectStore("accounts");

  let cursor = await accountsStore.openCursor();

  while (cursor) {
    const account = cursor.value;

    if (accounts.some((a) => a.uid === account.uid)) {
      accountsStore.put({
        ...account,
        status: undefined,
      });
    }

    cursor = await cursor.continue();
  }
};

export const deleteAccount = async (account: Account) => {
  const db = await loadDb;
  await db.delete("accounts", account.uid);
};

export const deleteAllAccounts = async () => {
  const db = await loadDb;
  await db.clear("accounts");
};
