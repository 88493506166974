import { asyncAtom, atom } from "@m1st1ck/atomjs";
import {
  Account, 
  AccountsGroup, 
  Currency,
  CurrencyClean,
  Profile,
  QuickAction,
  Tag,
  Transaction,
} from "types/data.types";
import { defaultQuery } from "./constants";
import { Query } from "database";
import Big from "big.js";

export const accountsAtom = asyncAtom<Account[]>([]);
export const accountsGroupsAtom = asyncAtom<AccountsGroup[]>([]);
export const accountsOrderAtom = asyncAtom<Required<Profile>["accountsOrder"]>(
  {}
);
export const accountsGroupsOrderAtom = asyncAtom<
  Required<Profile>["accountsGroupsOrder"]
>([]);
export const quickActionsAtom = asyncAtom<QuickAction[]>([]);
export const currenciesAtom = asyncAtom<Currency[]>([]);
export const primaryCurrencyAtom = atom<Currency | undefined>(undefined);
export const tagsAtom = asyncAtom<Tag[]>([]);
export const transactionsAtom = asyncAtom<Transaction[]>([]);
export const transactionQueryAtom = atom<Query>([
  [...defaultQuery[0]],
  defaultQuery[1],
  [...defaultQuery[2]],
]);

export const quickTagsAtom = atom<string[]>([]);
export const multipleDeletionAtom = atom<{
  enabled: boolean;
  items: Transaction[];
}>({
  enabled: false,
  items: [],
});
export const accountsTotalAtom = asyncAtom<{
  [acc: string]: {
    [cur: string]: {
      value: Big;
      currency: CurrencyClean;
    };
  };
}>({});
