import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { forwardRef, useMemo } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from "react-hook-form";

export type InputProps = Omit<TextFieldProps, "error"> & {
  error?: string;
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
};

export const Input: React.FC<InputProps> = forwardRef(
  ({ error, label, prefix, suffix, ...rest }, ref) => {
    return (
      <TextField
        inputRef={ref}
        label={label}
        margin="dense"
        variant="outlined"
        error={error !== " "}
        helperText={error}
        InputProps={{
          startAdornment: prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : undefined,
          endAdornment: suffix ? (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ) : undefined,
        }}
        {...rest}
      />
    );
  }
);

type ControlledInputProps<T> = {
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  rules?: RegisterOptions;
  afterChange?: () => void;
  noSpaces?: boolean;
} & (
  | {
      format?: undefined;
      parse?: undefined;
    }
  | {
      format: (val: string) => string;
    }
);

export const getInput = <T extends FieldValues>(
  control: Control<T, object>
) => {
  return forwardRef<HTMLInputElement, ControlledInputProps<T> & InputProps>(
    (
      {
        name,
        defaultValue,
        rules,
        error,
        noSpaces,
        afterChange,
        onBlur: onBlurExtend,
        ...props
      },
      ref
    ) => {
      const inputProps = { ...props } as Partial<ControlledInputProps<T>>;

      if ("format" in inputProps) {
        delete inputProps.format;
      }

      return (
        <Controller<any>
          defaultValue={defaultValue}
          control={control}
          name={name}
          rules={rules}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error: fieldError },
          }) => {
            return (
              <Input
                ref={ref}
                onBlur={(e) => {
                  onBlur();
                  onBlurExtend?.(e);
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  const spacedVal = noSpaces ? val.replace(/\s/g, "") : val;
                  onChange(props.format ? props.format(spacedVal) : spacedVal);
                  afterChange?.();
                }}
                error={error || fieldError?.message || " "}
                value={value}
                {...inputProps}
              />
            );
          }}
        />
      );
    }
  );
};

export const useInput = <T extends FieldValues>(control: Control<T, object>) =>
  useMemo(() => getInput(control), [control]);

export const getCheckbox = <T extends FieldValues>(
  control: Control<T, object>
) => {
  return ({
    error,
    defaultValue,
    name,
    rules,
    label,
    checkboxSx,
  }: ControlledInputProps<T> & {
    label: FormControlLabelProps["label"];
    checkboxSx?: FormControlLabelProps["sx"];
    error?: string;
  }) => {
    return (
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error: fieldError },
        }) => {
          const err = error || fieldError?.message || " ";
          return (
            <FormControl required error={err !== " "} variant="standard">
              <FormGroup>
                <FormControlLabel
                  sx={checkboxSx}
                  control={
                    <Checkbox
                      icon={
                        <CheckBoxOutlineBlankIcon
                          color={err === " " ? "inherit" : "error"}
                        />
                      }
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value as boolean}
                    />
                  }
                  label={label}
                />
                <FormHelperText>{err}</FormHelperText>
              </FormGroup>
            </FormControl>
          );
        }}
      />
    );
  };
};

export const useCheckbox = <T extends FieldValues>(
  control: Control<T, object>
) => useMemo(() => getCheckbox(control), [control]);
