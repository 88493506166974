import {
  AppBar,
  IconButton,
  Tooltip,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";
import { transactionQueryAtom, transactionsAtom } from "utils/atoms";
import Box from "components/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAtom } from "@m1st1ck/atomjs-react";
import { useModal } from "components/ModalProvider";
import { useEffect, useRef, useState } from "react";
import { fetchTransactions } from "utils/actions";
import Big from "big.js";
import { TYPES } from "utils/constants";
import { formatNumber, getAmount } from "utils/format";
import { Transaction } from "types/data.types";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import MonthSelector from "components/MonthSelector";
import PieChartIcon from "@mui/icons-material/PieChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TagIcon from "@mui/icons-material/Tag";
import LineChart from "./Stats/LineChart";
import PieChart from "./Stats/PieChart";
import TagsStats from "./Stats/TagsStats";

const Stats = () => {
  const { openModal } = useModal();
  const query = useAtom(transactionQueryAtom);
  const [searchParams] = useSearchParams();
  const path = searchParams.get("path");
  const pathTags = path?.split("/") || [];

  useEffect(() => {
    fetchTransactions(query);
  }, [query]);

  const [view, setView] = useState<"tags" | "lineChart" | "pieChart">("tags");

  const [transactions, transactionsState] = useAtom(transactionsAtom);

  const transactionsForSelectedTag: Transaction[] = [];
  let totalIncome = new Big(0);
  let totalExpense = new Big(0);

  // transactionsForSelectedTag without transfers
  for (let i = 0; i < transactions.length; i++) {
    const tran = transactions[i];

    // don't show transfers
    if (tran.type === TYPES.transfer) {
      continue;
    }

    // if there is selected tag, filter transactions to only those that contain the tag
    if (pathTags.length > 0 && pathTags.some((pt) => !tran.tags.includes(pt))) {
      continue;
    }

    transactionsForSelectedTag.push(tran);

    if (tran.type === TYPES.income) {
      totalIncome = totalIncome.add(getAmount(tran));
    } else {
      totalExpense = totalExpense.add(getAmount(tran));
    }
  }

  const scrollContainer = useRef<HTMLDivElement | null>(null);

  if (transactionsState.loading) {
    return (
      <Box pt={10} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box pt={8} overflow="hidden">
      <AppBar>
        <Box
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Box flex={1}>
            <ButtonGroup
              size="small"
              sx={{
                ml: 1,
                my: 1,
              }}
              aria-label="small button group"
            >
              <Button
                variant={view === "pieChart" ? "contained" : "outlined"}
                aria-label="pie chart"
                onClick={() => {
                  setView("pieChart");
                }}
              >
                <PieChartIcon />
              </Button>
              <Button
                variant={view === "lineChart" ? "contained" : "outlined"}
                aria-label="line chart"
                onClick={() => {
                  setView("lineChart");
                }}
              >
                <ShowChartIcon />
              </Button>
              <Button
                variant={view === "tags" ? "contained" : "outlined"}
                aria-label="tags"
                onClick={() => {
                  setView("tags");
                }}
              >
                <TagIcon />
              </Button>
            </ButtonGroup>
          </Box>
          <MonthSelector />

          <Box flex={1} alignItems="flex-end">
            <Tooltip enterDelay={666} title="Transactions filter">
              <IconButton
                onClick={() => {
                  openModal("transactionFilter", undefined);
                }}
                aria-label="open filter"
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </AppBar>

      <Box flexDirection="row" justifyContent="space-evenly">
        <Typography variant="h5" color="#66bb6a">
          {formatNumber(totalIncome.toNumber())}
        </Typography>

        <Typography variant="h5" color="tomato">
          {formatNumber(totalExpense.toNumber())}
        </Typography>

        <Typography
          variant="h5"
          color={totalIncome.add(totalExpense).gt(0) ? "skyblue" : "tomato"}
        >
          {formatNumber(totalIncome.add(totalExpense).toNumber())}
        </Typography>
      </Box>

      <Box alignItems="center">
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" to="/stats">
            All
          </Link>
          {pathTags.map((pt, i) => (
            <Link
              key={pt}
              component={RouterLink}
              underline="hover"
              to={`/stats?path=${pathTags.slice(0, i + 1).join("%2F")}`}
            >
              {pt}
            </Link>
          ))}
        </Breadcrumbs>
      </Box>

      <Box overflow="overlay" ref={scrollContainer}>
        {view === "lineChart" && (
          <LineChart transactionsForSelectedTag={transactionsForSelectedTag} />
        )}
        {view === "pieChart" && (
          <PieChart transactionsForSelectedTag={transactionsForSelectedTag} />
        )}
        {view === "tags" && (
          <TagsStats
            scrollContainer={scrollContainer}
            transactionsForSelectedTag={transactionsForSelectedTag}
          />
        )}
      </Box>
    </Box>
  );
};

export default Stats;
