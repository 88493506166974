import { waitForAtom } from "@m1st1ck/atomjs";
import {
  accountsAtom,
  accountsGroupsAtom,
  accountsGroupsOrderAtom,
  accountsOrderAtom,
  accountsTotalAtom,
  currenciesAtom,
  quickActionsAtom,
  tagsAtom,
  transactionsAtom,
} from "./atoms";
import {
  getAccounts,
  getAccountsGroups,
  getAccountsGroupsOrder,
  getAccountsOrder,
  getAccountsTotal,
  getCurrencies,
  getQuickActions,
  getTags,
  getTransactions,
  Query,
} from "database";

export const fetchAccountsOrder = () => {
  accountsOrderAtom.setAsyncState({ loading: true });
  getAccountsOrder()
    .then((order) => {
      accountsOrderAtom.setAsyncState(
        {
          loaded: true,
        },
        order || {}
      );
    })
    .catch((err) => {
      accountsOrderAtom.setAsyncState({ error: true });
    });
};

export const fetchAccountsGroupsOrder = () => {
  accountsGroupsOrderAtom.setAsyncState({ loading: true });
  getAccountsGroupsOrder()
    .then((order) => {
      accountsGroupsOrderAtom.setAsyncState(
        {
          loaded: true,
        },
        order || []
      );
    })
    .catch((err) => {
      accountsGroupsOrderAtom.setAsyncState({ error: true });
    });
};

export const fetchTags = async () => {
  // TODO: don't fetch globally but on demand
  try {
    tagsAtom.setAsyncState({ loading: true });
    const tags = await getTags();
    tagsAtom.setAsyncState(
      {
        loaded: true,
      },
      tags
    );
  } catch {
    tagsAtom.setAsyncState({ error: true });
  }
};

export const fetchCurrencies = async () => {
  currenciesAtom.setAsyncState({ loading: true });
  await getCurrencies()
    .then((currencies) => {
      currenciesAtom.setAsyncState(
        {
          loaded: true,
        },
        currencies
      );
    })
    .catch((err) => {
      console.log(err);
      currenciesAtom.setAsyncState({ error: true });
    });
};

export const fetchAccounts = async () => {
  accountsAtom.setAsyncState({ loading: true });
  try {
    const accounts = await getAccounts();

    await waitForAtom(currenciesAtom, ([, { loaded }]) => loaded);
    const currencies = currenciesAtom.getCoreState();

    accountsAtom.setAsyncState(
      {
        loaded: true,
      },
      accounts.map((acc) => {
        const currency =
          currencies.find((c) => c.uid === acc.currency.uid) || acc.currency;
        return {
          ...acc,
          currency: {
            uid: currency.uid,
            rate: currency.rate,
          },
        };
      })
    );
  } catch {
    accountsAtom.setAsyncState({ error: true });
  }
};

export const fetchAccountsGroups = async () => {
  accountsGroupsAtom.setAsyncState({ loading: true });
  try {
    const accountsGroups = await getAccountsGroups();

    accountsGroupsAtom.setAsyncState(
      {
        loaded: true,
      },
      accountsGroups
    );
  } catch {
    accountsGroupsAtom.setAsyncState({ error: true });
  }
};

export const fetchTransactions = (query?: Query) => {
  transactionsAtom.setAsyncState({ loading: true });
  getTransactions(query)
    .then((transactions) => {
      transactionsAtom.setAsyncState(
        {
          loaded: true,
        },
        transactions
      );
    })
    .catch((err) => {
      console.log(err);
      transactionsAtom.setAsyncState({ error: true });
    });
};

export const fetchAccountsTotal = () => {
  accountsTotalAtom.setAsyncState({ loading: true });
  getAccountsTotal()
    .then((data) => {
      accountsTotalAtom.setAsyncState({ loaded: true }, data);
    })
    .catch(() => {
      accountsTotalAtom.setAsyncState({ error: true });
    });
};

export const fetchQuickActions = () => {
  quickActionsAtom.setAsyncState({ loading: true });
  getQuickActions()
    .then((data) => {
      quickActionsAtom.setAsyncState({ loaded: true }, data);
    })
    .catch(() => {
      quickActionsAtom.setAsyncState({ error: true });
    });
};
