import { parse } from "date-fns";

const transactions = [
  {
    country: "USA",
    platform: "Revolut",
    openDate: "29/10/2019",
    stock: "GOOGL",
    openAmount: 2,
    currency: "USD",
    units: 0.00154361,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "31/08/2020",
    stock: "GOOGL",
    openAmount: 96.29,
    currency: "USD",
    units: 0.058929,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "28/10/2019",
    stock: "TSLA",
    openAmount: 5,
    currency: "USD",
    units: 0.01539077,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "14/11/2019",
    stock: "TSLA",
    openAmount: 3,
    currency: "USD",
    units: 0.00855236,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "3/02/2020",
    stock: "TSLA",
    openAmount: 19.67,
    currency: "USD",
    units: 0.02605687,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "3/02/2020",
    stock: "TSLA",
    openAmount: 37.64,
    currency: "USD",
    units: 0.05,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "31/08/2020",
    stock: "TSLA",
    openAmount: 264.4,
    currency: "USD",
    units: 0.5,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "31/08/2020",
    stock: "TSLA",
    openAmount: 200,
    currency: "USD",
    units: 0.40784697,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "23/09/2020",
    stock: "TSLA",
    openAmount: 314.39,
    currency: "USD",
    units: 0.80144284,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "02/12/2020",
    stock: "TSLA",
    openAmount: 50,
    currency: "USD",
    units: 0.08812902,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "11/12/2020",
    stock: "TSLA",
    openAmount: 50,
    currency: "USD",
    units: 0.08184913,
  },
  {
    country: "USA",
    platform: "Revolut",
    openDate: "05/01/2021",
    stock: "TSLA",
    openAmount: 350,
    currency: "USD",
    units: 0.48209366,
  },
  {
    country: "China",
    platform: "eToro",
    openDate: "09/03/2021",
    stock: "BABA",
    openAmount: 100,
    currency: "USD",
    units: 0.42111,
  },
  {
    country: "Netherlands",
    platform: "eToro",
    openDate: "10/03/2021",
    stock: "TKWY.NV",
    openAmount: 100,
    currency: "USD",
    units: 1.773317,
  },
  {
    country: "Netherlands",
    platform: "eToro",
    openDate: "06/12/2021",
    stock: "TKWY.NV",
    openAmount: 100,
    currency: "USD",
    units: 1.002727,
  },
  {
    country: "Netherlands",
    platform: "eToro",
    openDate: "23/02/2022",
    stock: "TKWY.NV",
    openAmount: 100,
    currency: "USD",
    units: 2.71693,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "10/11/2021",
    stock: "RIVN",
    openAmount: 200,
    currency: "USD",
    units: 2.00884,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "16/03/2021",
    stock: "FTCV",
    openAmount: 200,
    currency: "USD",
    units: 14.51379,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "10/11/2021",
    stock: "PYPL",
    openAmount: 100,
    currency: "USD",
    units: 0.49172,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "29/11/2021",
    stock: "PYPL",
    openAmount: 50,
    currency: "USD",
    units: 0.27052,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "24/01/2022",
    stock: "PYPL",
    openAmount: 150,
    currency: "USD",
    units: 0.97415,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "09/02/2022",
    stock: "PYPL",
    openAmount: 200,
    currency: "USD",
    units: 1.62417,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2022",
    stock: "PYPL",
    openAmount: 100,
    currency: "USD",
    units: 0.96628,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "FB",
    openAmount: 100,
    currency: "USD",
    units: 0.38317,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "09/02/2022",
    stock: "FB",
    openAmount: 200,
    currency: "USD",
    units: 0.87623,
  },
  {
    country: "Switzerland",
    platform: "eToro",
    openDate: "09/02/2022",
    stock: "CRSP",
    openAmount: 100,
    currency: "USD",
    units: 1.53445,
  },
  {
    country: "Ireland",
    platform: "eToro",
    openDate: "23/02/2021",
    stock: "FLTR.L",
    openAmount: 100,
    currency: "USD",
    units: 0.5,
  },
  {
    country: "Ireland",
    platform: "eToro",
    openDate: "03/11/2021",
    stock: "FLTR.L",
    openAmount: 100,
    currency: "USD",
    units: 0.57,
  },
  {
    country: "Ireland",
    platform: "eToro",
    openDate: "19/11/2021",
    stock: "FLTR.L",
    openAmount: 100,
    currency: "USD",
    units: 0.64,
  },
  {
    country: "Ireland",
    platform: "eToro",
    openDate: "29/11/2021",
    stock: "FLTR.L",
    openAmount: 100,
    currency: "USD",
    units: 0.72,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "10/11/2021",
    stock: "NET",
    openAmount: 100,
    currency: "USD",
    units: 0.51013,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "10/01/2022",
    stock: "NET",
    openAmount: 100,
    currency: "USD",
    units: 0.91199,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "19/01/2022",
    stock: "NET",
    openAmount: 100,
    currency: "USD",
    units: 1.05263,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "24/01/2022",
    stock: "NET",
    openAmount: 100,
    currency: "USD",
    units: 1.21095,
  },
  {
    country: "Cayman Islands",
    platform: "eToro",
    openDate: "09/12/2021",
    stock: "NU",
    openAmount: 100,
    currency: "USD",
    units: 9.51475,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "NFLX",
    openAmount: 100,
    currency: "USD",
    units: 0.18609,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "24/01/2022",
    stock: "NFLX",
    openAmount: 100,
    currency: "USD",
    units: 0.28325,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "T",
    openAmount: 60,
    currency: "USD",
    units: 2.04569,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "29/11/2021",
    stock: "T",
    openAmount: 50,
    currency: "USD",
    units: 2.07125,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "02/11/2021",
    stock: "USRT",
    openAmount: 100,
    currency: "USD",
    units: 1.56378,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2022",
    stock: "USRT",
    openAmount: 100,
    currency: "USD",
    units: 1.6438,
  },
  {
    country: "Hungaria",
    platform: "eToro",
    openDate: "19/11/2021",
    stock: "WIZZ.L",
    openAmount: 100,
    currency: "USD",
    units: 1.7,
  },
  {
    country: "Hungaria",
    platform: "eToro",
    openDate: "29/11/2021",
    stock: "WIZZ.L",
    openAmount: 100,
    currency: "USD",
    units: 1.96,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "10/11/2021",
    stock: "GOOG",
    openAmount: 100,
    currency: "USD",
    units: 0.03418,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "02/11/2021",
    stock: "GOOG",
    openAmount: 100,
    currency: "USD",
    units: 0.03436,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2022",
    stock: "GOOG",
    openAmount: 100,
    currency: "USD",
    units: 0.03842,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "GOOG",
    openAmount: 100,
    currency: "USD",
    units: 0.04824,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "07/01/2021",
    stock: "TSLA",
    openAmount: 150,
    currency: "USD",
    units: 0.18705,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "26/01/2021",
    stock: "TSLA",
    openAmount: 500,
    currency: "USD",
    units: 0.56312,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "27/01/2021",
    stock: "TSLA",
    openAmount: 200,
    currency: "USD",
    units: 0.2272,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "27/01/2021",
    stock: "TSLA",
    openAmount: 100,
    currency: "USD",
    units: 0.11524,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "28/01/2021",
    stock: "TSLA",
    openAmount: 200,
    currency: "USD",
    units: 0.2401,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "28/01/2021",
    stock: "TSLA",
    openAmount: 240,
    currency: "USD",
    units: 0.28813,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "29/01/2021",
    stock: "TSLA",
    openAmount: 100,
    currency: "USD",
    units: 0.12153,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "29/01/2021",
    stock: "TSLA",
    openAmount: 150,
    currency: "USD",
    units: 0.1875,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "TSLA",
    openAmount: 200,
    currency: "USD",
    units: 0.26064,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "TSLA",
    openAmount: 160,
    currency: "USD",
    units: 0.21622,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "05/03/2021",
    stock: "TSLA",
    openAmount: 200,
    currency: "USD",
    units: 0.3485,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2022",
    stock: "TSLA",
    openAmount: 100,
    currency: "USD",
    units: 0.12291,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "SPY",
    openAmount: 100,
    currency: "USD",
    units: 0.25737,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "02/11/2021",
    stock: "SPY",
    openAmount: 100,
    currency: "USD",
    units: 0.21643,
  },
  {
    country: "South Korea",
    platform: "eToro",
    openDate: "19/11/2021",
    stock: "SMSN.L",
    openAmount: 100,
    currency: "USD",
    units: 0.07,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "V",
    openAmount: 100,
    currency: "USD",
    units: 0.48207,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "29/11/2021",
    stock: "V",
    openAmount: 50,
    currency: "USD",
    units: 0.25466,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "MA",
    openAmount: 100,
    currency: "USD",
    units: 0.29573,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "29/11/2021",
    stock: "MA",
    openAmount: 50,
    currency: "USD",
    units: 0.15546,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "25/02/2021",
    stock: "QCOM",
    openAmount: 70,
    currency: "USD",
    units: 0.50104,
  },
  {
    country: "Japan",
    platform: "eToro",
    openDate: "23/02/2021",
    stock: "TM",
    openAmount: 50,
    currency: "USD",
    units: 0.33333,
  },
  {
    country: "Japan",
    platform: "eToro",
    openDate: "23/02/2021",
    stock: "TM",
    openAmount: 50,
    currency: "USD",
    units: 0.33333,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "KO",
    openAmount: 100,
    currency: "USD",
    units: 2.00723,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "09/03/2021",
    stock: "AAPL",
    openAmount: 100,
    currency: "USD",
    units: 0.82713,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "22/02/2021",
    stock: "NVDA",
    openAmount: 100,
    currency: "USD",
    units: 0.6751,
  },
  {
    country: "USA",
    platform: "eToro",
    openDate: "19/02/2021",
    stock: "SPX500",
    openAmount: 1000,
    currency: "USD",
    units: 0.254747,
    closeDate: "22/02/2021",
    closeAmount: 1000 - 9.46,
    feesAndDividends: -0.19,
  },
  {
    country: "USA",
    platform: "eToro",
    stock: "SPX500",
    openDate: "28/01/2021",
    closeDate: "28/01/2021",
    openAmount: 220,
    currency: "USD",
    units: 1.158102 / 20,
    closeAmount: 220 + 27.71,
    feesAndDividends: 0,
  },
  {
    country: "USA",
    platform: "eToro",
    stock: "SPX500",
    openDate: "17/12/2020",
    closeDate: "28/01/2021",
    openAmount: 200,
    currency: "USD",
    units: 0.268802 / 5,
    closeAmount: 220 + 20.86,
    feesAndDividends: -2.61,
  },
  {
    country: "USA",
    platform: "eToro",
    stock: "TSLA",
    openDate: "17/12/2020",
    closeDate: "28/01/2021",
    openAmount: 100,
    currency: "USD",
    units: 0.77006 / 5,
    closeAmount: 100 + 140.84,
    feesAndDividends: -4.45,
  },
  {
    country: "USA",
    platform: "eToro",
    stock: "TSLA",
    openDate: "08/01/2021",
    closeDate: "25/01/2021",
    openAmount: 500,
    currency: "USD",
    units: 2.833535 / 5,
    closeAmount: 500 - 5.38,
    feesAndDividends: -7.3,
  },
  {
    country: "USA",
    platform: "eToro",
    stock: "FB",
    openDate: "17/12/2020",
    closeDate: "07/01/2021",
    openAmount: 100,
    currency: "USD",
    units: 1.804533 / 5,
    closeAmount: 100 - 15.39,
    feesAndDividends: -1.88,
  },
  {
    country: "Japan",
    platform: "eToro",
    stock: "TM",
    openDate: "17/12/2020",
    closeDate: "07/01/2021",
    openAmount: 100,
    currency: "USD",
    units: 3.226639 / 5,
    closeAmount: 100 - 10.49,
    feesAndDividends: -1.88,
  },
];

export const groupByDate = () => {
  const groupMap: {
    [key: string]: {
      units: number;
      openAmount: number;
      closeAmount: number;
      feesAndDividends: number;
    };
  } = {};

  transactions.forEach((trans) => {
    const key = `${trans.openDate}_${trans.country}_${trans.closeDate}`;
    if (!groupMap[key]) {
      groupMap[key] = {
        units: 0,
        openAmount: 0,
        closeAmount: 0,
        feesAndDividends: 0,
      };
    }

    groupMap[key].units += trans.units;
    groupMap[key].openAmount += trans.openAmount;
    groupMap[key].closeAmount += trans.closeAmount || 0;
    groupMap[key].feesAndDividends += trans.feesAndDividends || 0;
  });

  const rows = [
    [
      "Type",
      "Country",
      "Units",
      "Open date",
      "amount in currency",
      "amount in bgn",
      "Close date",
      "Close amount",
      "FeesAndDividends",
    ],
    ["1", "2", "3", "4", "5", "6"],

    ...Object.keys(groupMap)
      .map((key, i) => {
        const parts = key.split("_");
        const data = groupMap[key];
        return [
          i,
          parts[1],
          data.units,
          parts[0],
          data.openAmount,
          data.openAmount,
          parts[2],
          data.closeAmount,
          data.feesAndDividends,
        ] as [
          number,
          string,
          number,
          string,
          number,
          number,
          string,
          number,
          number
        ];
      })
      .sort(
        (a, b) =>
          parse(a[3], "dd/MM/yyyy", new Date()).getTime() -
          parse(b[3], "dd/MM/yyyy", new Date()).getTime()
      )
      .map((t, i) => [
        i,
        t[1],
        t[2],
        t[3],
        t[4],
        t[5],
        t[6] === "undefined" ? "" : t[6],
        t[6] === "undefined" ? "" : t[7],
        t[6] === "undefined" ? "" : t[8],
      ]),
  ];

  let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "my_data.csv");
  document.body.appendChild(link); // Required for FF

  // console.log(groupMap);
  // console.log(rows);
  // link.click();
  document.body.removeChild(link); // Required for FF
};
