import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Box from "components/Box";
import { useEffect, useState } from "react";
import { useAtom } from "@m1st1ck/atomjs-react";
import { tagsAtom, transactionQueryAtom, transactionsAtom } from "utils/atoms";
import { Tag } from "types/data.types";
import { mergeTags, renameTag } from "database";
import { fetchTransactions } from "utils/actions";
import { markTagForDeletionSynced } from "utils/databaseMiddleware";
import Prompt from "components/Prompt";
import { useNavigate } from "react-router-dom";

const Tags = () => {
  const navigate = useNavigate();

  const [renameTagOpened, setRenameTagOpened] = useState(false);
  const [mergeTagOpened, setMergeTagsOpened] = useState(false);

  const [tags] = useAtom(tagsAtom);

  useEffect(() => {
    fetchTransactions([]);
  }, []);

  const [transactions] = useAtom(transactionsAtom);

  const tagsCount: {
    [key: string]: number;
  } = {};

  transactions.forEach((t) => {
    t.tags.forEach((tag) => {
      if (!tagsCount[tag]) {
        tagsCount[tag] = 0;
      }
      tagsCount[tag]++;
    });
  });

  useEffect(() => {
    console.log("=======================================");

    tags.forEach((t, i, arr) => {
      const lastIndex = arr.findLastIndex(
        (tt) => tt.uid.toLowerCase().trim() === t.uid.toLowerCase().trim()
      );

      if (i !== lastIndex) {
        console.log(t.uid, arr[lastIndex].uid);
      }
    });

    console.log("=======================================");
  }, [tags]);

  return (
    <Box sx={{ overflow: "auto" }} flex={1}>
      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            setRenameTagOpened(true);
          }}
        >
          Rename Tag
        </Button>
      </Box>
      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            setMergeTagsOpened(true);
          }}
        >
          Merge Tags
        </Button>
      </Box>

      <MergeTagsDialog
        mergeTagOpened={mergeTagOpened}
        setMergeTagsOpened={setMergeTagsOpened}
      />

      <RenameTagDialog
        renameTagOpened={renameTagOpened}
        setRenameTagOpened={setRenameTagOpened}
      />

      <Box flexWrap="wrap" flexDirection="row">
        {tags.map((t, i) => (
          <Chip
            onClick={() => {
              transactionQueryAtom.setState(["tags", true, "contain", [t.uid]]);

              navigate("/dashboard");
            }}
            sx={{ m: 1, whiteSpace: "pre-wrap" }}
            key={i}
            label={`${t.uid}(${tagsCount[t.uid] || 0})`}
            onDelete={() => {
              if (tagsCount[t.uid] > 0) {
                Prompt.show(
                  "Cannot delete because there are transactions using the tag.",
                  "",
                  {
                    actions: [
                      {
                        text: "Okay",
                        value: false,
                      },
                    ],
                  }
                );

                return;
              }
              Prompt.show<boolean>(
                `Are you sure you want to delete ${t.uid}?`
              ).then((res) => {
                if (res) {
                  markTagForDeletionSynced(t).then(() => {
                    tagsAtom.setState((prev) =>
                      prev.filter((tag) => tag.uid !== t.uid)
                    );
                  });
                }
              });
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Tags;

type RenameTagDialogProps = {
  renameTagOpened: boolean;
  setRenameTagOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

function RenameTagDialog({
  renameTagOpened,
  setRenameTagOpened,
}: RenameTagDialogProps) {
  const [tags] = useAtom(tagsAtom);
  const [tagName, setTagName] = useState<string>("");
  const [selectedTagToRename, setSelectedTagToRaname] = useState<Tag | null>(
    null
  );
  return (
    <Dialog
      open={renameTagOpened}
      onClose={() => {
        setRenameTagOpened(false);
      }}
    >
      <DialogTitle>Rename Tag</DialogTitle>
      <DialogContent>
        <Autocomplete
          getOptionLabel={(option) => option}
          options={tags.map((t) => t.uid)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ minWidth: 256, my: 1 }}
              autoFocus
              label="Tags"
            />
          )}
          value={selectedTagToRename?.uid || ""}
          onChange={(__, newValue: string | null) => {
            setSelectedTagToRaname(
              tags.find((t) => t.uid === newValue) || null
            );
          }}
        />
        <TextField
          margin="dense"
          label="Name"
          fullWidth
          value={tagName}
          onChange={(e) => {
            setTagName(e.target.value);
          }}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setRenameTagOpened(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!selectedTagToRename) {
              return;
            }
            renameTag(selectedTagToRename, tagName).then(() => {
              setRenameTagOpened(false);
              setTagName("");
              setSelectedTagToRaname(null);

              tagsAtom.setState((prev) =>
                prev.map((tag) =>
                  tag.uid === selectedTagToRename.uid
                    ? {
                        ...tag,
                        uid: tagName,
                      }
                    : tag
                )
              );

              transactionsAtom.setState((trans) =>
                trans.map((t) =>
                  t.tags.includes(selectedTagToRename.uid)
                    ? {
                        ...t,
                        tags: [
                          ...t.tags.filter(
                            (t) => t !== selectedTagToRename.uid
                          ),
                          tagName,
                        ],
                      }
                    : t
                )
              );
            });
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type MergeTagsDialogProps = {
  mergeTagOpened: boolean;
  setMergeTagsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

function MergeTagsDialog({
  mergeTagOpened,
  setMergeTagsOpened,
}: MergeTagsDialogProps) {
  const [tags] = useAtom(tagsAtom);
  const [selectedTagToRemove, setSelectedTagToRemove] = useState<Tag | null>(
    null
  );

  const [selectedTagToUse, setSelectedTagToUse] = useState<Tag | null>(null);
  return (
    <Dialog
      open={mergeTagOpened}
      onClose={() => {
        setMergeTagsOpened(false);
      }}
    >
      <DialogTitle>Merge Tags</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Autocomplete
            getOptionLabel={(option) => option}
            options={tags
              .map((t) => t.uid)
              .filter((t) => t !== selectedTagToUse?.uid)}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ minWidth: 256 }}
                autoFocus
                label="Merge"
              />
            )}
            value={selectedTagToRemove?.uid || ""}
            onChange={(__, newValue: string | null) => {
              setSelectedTagToRemove(
                tags.find((t) => t.uid === newValue) || null
              );
            }}
          />
        </Box>

        <Box mt={2}>
          <Autocomplete
            getOptionLabel={(option) => option}
            options={tags
              .map((t) => t.uid)
              .filter((t) => t !== selectedTagToRemove?.uid)}
            renderInput={(params) => (
              <TextField {...params} sx={{ minWidth: 256 }} label="In to" />
            )}
            value={selectedTagToUse?.uid || ""}
            onChange={(__, newValue: string | null) => {
              setSelectedTagToUse(tags.find((t) => t.uid === newValue) || null);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setMergeTagsOpened(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!selectedTagToUse || !selectedTagToRemove) {
              return;
            }
            mergeTags(selectedTagToRemove, selectedTagToUse).then(() => {
              setMergeTagsOpened(false);
              setSelectedTagToRemove(null);
              setSelectedTagToUse(null);
              tagsAtom.setState((prev) =>
                prev.filter((tag) => tag.uid !== selectedTagToRemove.uid)
              );

              transactionsAtom.setState((trans) =>
                trans.map((t) =>
                  t.tags.includes(selectedTagToRemove.uid)
                    ? {
                        ...t,
                        tags: [
                          ...t.tags.filter(
                            (t) => t !== selectedTagToRemove.uid
                          ),
                          selectedTagToUse.uid,
                        ],
                      }
                    : t
                )
              );
            });
          }}
        >
          Merge
        </Button>
      </DialogActions>
    </Dialog>
  );
}
