import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useInput } from "components/FormComponents";
import { useForm } from "react-hook-form";
import { AccountsGroup } from "types/data.types";
import { accountsGroupsAtom } from "utils/atoms";
import { useModal } from "components/ModalProvider";
import { Upsert } from "database";
import {
  markAccountsGroupForDeletionSynced,
  revertAccountsGroupStatusSynced,
  upsertAccountsGroupSynced,
} from "utils/databaseMiddleware";
import Prompt from "components/Prompt";
import MSnackbar from "components/MSnackbar";

type AccountFormData = Pick<Upsert<AccountsGroup>, "name">;

const AccountGroupModal: React.FC = () => {
  const formData = useForm<AccountFormData>({
    mode: "onBlur",
  });
  const Input = useInput(formData.control);

  const { data, closeModal } = useModal<"accountGroup">();

  return (
    <>
      <DialogTitle>
        {!data?.uid ? "Create Account Group" : "Update Account Group"}
      </DialogTitle>
      <DialogContent>
        <Input defaultValue={data?.name} label="name" name="name" />
      </DialogContent>
      <DialogActions>
        {data?.uid !== undefined && (
          <Button
            color="error"
            onClick={() => {
              Prompt.show(
                "Delete account group",
                `Are you sure that you want to delete ${data.name}? `
              ).then((res) => {
                if (res) {
                  closeModal();

                  markAccountsGroupForDeletionSynced(data.uid!).then(() => {
                    MSnackbar.show("Deleted", {
                      action: (
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => {
                            MSnackbar.hide();
                            revertAccountsGroupStatusSynced(data.uid!).then(
                              (acc) => {
                                accountsGroupsAtom.setState((prev) => [
                                  ...prev,
                                  acc,
                                ]);
                              }
                            );
                          }}
                        >
                          UNDO
                        </Button>
                      ),
                    });
                  });

                  accountsGroupsAtom.setState((prev) =>
                    prev.filter((a) => a.uid !== data.uid)
                  );
                }
              });
            }}
          >
            Delete
          </Button>
        )}

        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={formData.handleSubmit((formData) => {
            upsertAccountsGroupSynced({
              name: formData.name,
              uid: data?.uid,
            }).then((acc) => {
              accountsGroupsAtom.setState((prev) => [
                ...prev.filter((acc) => acc.uid !== data?.uid),
                acc,
              ]);
              closeModal();
            });
          })}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AccountGroupModal;
