import { IconButton, Tooltip } from "@mui/material";
import { addMonths, format, startOfMonth, subMonths } from "date-fns";
import { useAtom } from "@m1st1ck/atomjs-react";
import { transactionQueryAtom } from "utils/atoms";
import Box from "components/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { QueryConditionDate } from "database";
import { endOfMonth, isSameMonth } from "date-fns/esm";

export default function MonthSelector() {
  const query = useAtom(transactionQueryAtom);

  const isMonthsRange =
    query[0]?.[0] === "date" &&
    query[0]?.[2] === "is after or same" &&
    query[1] === "AND" &&
    query[2]?.[0] === "date" &&
    query[2]?.[2] === "is before or same";

  const monthsRangeStart = new Date((query[0] as QueryConditionDate)?.[3]);
  const monthsRangeEnd = new Date((query[0] as QueryConditionDate)?.[3]);

  const _isSameMonth = isMonthsRange
    ? isSameMonth(monthsRangeStart, monthsRangeEnd)
    : false;

  if (!_isSameMonth) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      left={0}
      right={0}
      zIndex={0}
    >
      <Tooltip enterDelay={666} title="Prev Month">
        <IconButton
          onClick={() => {
            if (query[1] === "AND") {
              const monthStart = query.find(
                (q) =>
                  q !== "AND" &&
                  q !== "OR" &&
                  q[0] === "date" &&
                  q[1] === true &&
                  q[2] === "is after or same" &&
                  new Date(q[3]).toISOString() ===
                    startOfMonth(new Date(q[3])).toISOString()
              ) as QueryConditionDate | undefined;

              if (monthStart) {
                monthStart[3] = startOfMonth(
                  subMonths(new Date(monthStart[3]), 1)
                );
              }

              const monthEnd = query.find((q) => {
                return (
                  q !== "AND" &&
                  q !== "OR" &&
                  q[0] === "date" &&
                  q[1] === true &&
                  q[2] === "is before or same" &&
                  new Date(q[3]).toISOString() ===
                    endOfMonth(new Date(q[3])).toISOString()
                );
              }) as QueryConditionDate | undefined;

              if (monthEnd) {
                monthEnd[3] = endOfMonth(subMonths(new Date(monthEnd[3]), 1));
              }

              transactionQueryAtom.setState([...query]);
            }
          }}
          aria-label="prev month"
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Tooltip>
      {format(monthsRangeStart, "MMMM yyyy")}
      <Tooltip enterDelay={666} title="Next Month">
        <IconButton
          onClick={() => {
            if (query[1] === "AND") {
              const monthStart = query.find(
                (q) =>
                  q !== "AND" &&
                  q !== "OR" &&
                  q[0] === "date" &&
                  q[1] === true &&
                  q[2] === "is after or same" &&
                  new Date(q[3]).toISOString() ===
                    startOfMonth(new Date(q[3])).toISOString()
              ) as QueryConditionDate | undefined;

              if (monthStart) {
                monthStart[3] = startOfMonth(
                  addMonths(new Date(monthStart[3]), 1)
                );
              }

              const monthEnd = query.find((q) => {
                return (
                  q !== "AND" &&
                  q !== "OR" &&
                  q[0] === "date" &&
                  q[1] === true &&
                  q[2] === "is before or same" &&
                  new Date(q[3]).toISOString() ===
                    endOfMonth(new Date(q[3])).toISOString()
                );
              }) as QueryConditionDate | undefined;

              if (monthEnd) {
                monthEnd[3] = endOfMonth(addMonths(new Date(monthEnd[3]), 1));
              }

              transactionQueryAtom.setState([...query]);
            }
          }}
          aria-label="next month"
        >
          <NavigateNextIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
