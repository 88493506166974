import { ButtonGroup, Button } from "@mui/material";
import { transactionQueryAtom } from "utils/atoms";

type TagsGroupProps = {
  tags: string[];
};

const TagsGroup: React.FC<TagsGroupProps> = ({ tags }) => {
  if (tags.length === 0) {
    return null;
  }
  return (
    <ButtonGroup variant="text" size="small" color="primary" aria-label="tags">
      {tags.sort().map((tag) => (
        <Button
          sx={{
            whiteSpace: "nowrap",
            textTransform: "initial",
          }}
          onClick={(e) => {
            e.stopPropagation();
            transactionQueryAtom.setState(["tags", true, "contain", [tag]]);
          }}
          key={tag}
        >
          {tag}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default TagsGroup;
