import Big from "big.js";
import { Transaction } from "types/data.types";
import { TYPES } from "./constants";
import { currenciesAtom } from "./atoms";

export const formatInputNumber = (value: string): string => {
  const num = value.replaceAll(" ", "");
  if (num === "") {
    return "";
  }

  if (num === "-" || num === "+") {
    return num;
  }

  const parts = num.split(",").join("").split(".");
  const int = Number(parts[0]);
  const absInt = Math.abs(int);

  if (Number.isNaN(int)) {
    return "";
  }

  const start =
    (int < 0 ? "-" : "") +
    absInt.toString().replace(/(.)(?=(\d{3})+$)/g, "$1,");

  if (parts.length === 1) {
    return start;
  }

  const decimal = Number(parts[1]);

  if (Number.isNaN(decimal)) {
    return start + ".";
  }

  return start + "." + parts[1].slice(0, 2);
};

export const formatNumber = (num: number) => {
  const absNumber = Math.abs(num);
  const parts = absNumber.toFixed(2).split(".");
  return (
    (num < 0 ? "-" : "") +
    parts[0].replace(/(.)(?=(\d{3})+$)/g, "$1,") +
    "." +
    parts[1]
  );
};

export const parseNumber = (num: string) => {
  return Number(num.split(",").join(""));
};

export const getAmount = (
  tran: Transaction,
  useBaseCurrency: boolean = false
) => {
  if (useBaseCurrency) {
    const currencies = currenciesAtom.getCoreState();
    const cName = tran.amount.currency.uid;
    const cur = currencies.find((c) => c.uid === cName) || tran.amount.currency;
    const amount = new Big(tran.amount.value).div(cur.rate).toNumber();
    return tran.type === TYPES.expense ? -amount : amount;
  }

  const cur = tran.amount.currency;
  const amount = new Big(tran.amount.value).div(cur.rate).toNumber();
  return tran.type === TYPES.expense ? -amount : amount;
};
