import { atom } from "@m1st1ck/atomjs";
import { Box, Paper, ListItem, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import { useMemo, useEffect, useState, useRef } from "react";
import { Transaction } from "types/data.types";
import { formatNumber, getAmount } from "utils/format";
import DateBox from "./DateBox";
import TransactionBox from "./TransactionBox";
import { TYPES } from "utils/constants";

const scrollAtom = atom(0);

const parseEntries = (entries: Transaction[]) => {
  const groupedEntries: {
    [key: string]: {
      [TYPES.expense]: 0;
      [TYPES.income]: 0;
      list: Transaction[];
    };
  } = {};

  entries
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .forEach((entry) => {
      let timestamp = format(new Date(entry.date), "yDDD");

      if (!groupedEntries[timestamp]) {
        groupedEntries[timestamp] = {
          [TYPES.expense]: 0,
          [TYPES.income]: 0,
          list: [],
        };
      }

      groupedEntries[timestamp].list.push(entry);

      const amount = getAmount(entry);

      if (entry.type === TYPES.income || entry.type === TYPES.expense) {
        groupedEntries[timestamp][entry.type] += amount;
      }
    });

  return groupedEntries;
};

type TransactionListProps = {
  transactions: Transaction[];
  scrollContainer: React.MutableRefObject<HTMLElement | null>;
};

const GROUP_HEADER_HEIGHT = 56;
const GROUP_ITEM_HEIGHT = 64;

export const TransactionsList: React.FC<TransactionListProps> = ({
  transactions,
  scrollContainer,
}) => {
  const entries = useMemo(
    () => parseEntries(transactions || []),
    [transactions]
  );

  const groups = Object.keys(entries).sort(
    (a, b) =>
      parse(b, "yDDD", new Date()).getTime() -
      parse(a, "yDDD", new Date()).getTime()
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  let [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffsetTop(containerRef.current?.offsetTop || 0);
      scrollAtom.setState(scrollContainer.current?.scrollTop || 0);
    };
    const el = scrollContainer.current;
    el?.addEventListener("scroll", handleScroll);
    scrollAtom.setState(scrollContainer.current?.scrollTop || 0);
    return () => {
      el?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainer]);

  let startTop = 0;

  return (
    <Box position="relative" mb={8} mt={4} ref={containerRef}>
      <Box
        minHeight={groups.reduce(
          (acc, val) =>
            acc +
            entries[val].list.length * GROUP_ITEM_HEIGHT +
            GROUP_HEADER_HEIGHT,
          0
        )}
      />
      {groups.map((key, index) => {
        const group = entries[key];
        const groupStart = startTop;
        const groupEnd =
          groupStart +
          GROUP_HEADER_HEIGHT +
          group.list.length * GROUP_ITEM_HEIGHT;
        startTop = groupEnd;

        return (
          <TransactionItem
            key={`${index}_${group.list.length}`}
            group={group}
            date={key}
            groupStart={groupStart}
            groupEnd={groupEnd}
            offsetTop={offsetTop}
          />
        );
      })}
    </Box>
  );
};

const TransactionItem: React.FC<{
  date: string;
  groupStart: number;
  groupEnd: number;
  offsetTop: number;
  group: {
    [TYPES.expense]: 0;
    [TYPES.income]: 0;
    list: Transaction[];
  };
}> = ({ group, date, groupStart, offsetTop, groupEnd }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const calc = () => {
      const startVisible = scrollAtom.getState() - offsetTop;
      const endVisible = startVisible + window.innerHeight;

      setVisible(
        startVisible < groupStart + groupEnd && groupStart < endVisible
      );
    };
    const unsub = scrollAtom.subscribe(calc);
    calc();
    return unsub;
  }, [groupStart, groupEnd, offsetTop]);

  if (!visible) {
    return null;
  }

  return (
    <Paper
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: groupStart,
      }}
      elevation={3}
      square
    >
      <ListItem sx={{ height: 56 }}>
        <DateBox date={parse(date, "yDDD", new Date())} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Typography style={{ color: "#6AA84F" }} variant="h6">
            {formatNumber(group.income)}
          </Typography>
          <Typography style={{ color: "#E06666" }} variant="h6">
            {formatNumber(group.expense)}
          </Typography>
        </Box>
      </ListItem>
      {group.list.map((transaction) => (
        <TransactionBox key={transaction.uid} transaction={transaction} />
      ))}
    </Paper>
  );
};
