import { Button, CircularProgress, Typography } from "@mui/material";
import Box from "components/Box";
import Prompt from "components/Prompt";
import { useNavigate } from "react-router-dom";
import {
  disabledDriveSync,
  enableDriveSync,
  exportJSONData,
  importJSONData,
  initNewSyncFile,
  useIsDriveSyncEnabled,
  wipeData,
} from "utils/dataSync";
import { useAsync } from "@m1st1ck/useasync";
import packageJson from "../../package.json";
import { useModal } from "components/ModalProvider";

const Settings = () => {
  const { openModal } = useModal();
  const navigate = useNavigate();

  const [exportJSON, exportJSONStat] = useAsync(exportJSONData, {});
  const [importJSON, importJSONStat] = useAsync(importJSONData, {});

  const isDriveSyncEnabled = useIsDriveSyncEnabled();

  return (
    <Box flex={1} py={2} overflow="auto">
      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/currencies");
          }}
        >
          Currencies
        </Button>
      </Box>

      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/tags");
          }}
        >
          Tags
        </Button>
      </Box>

      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            Prompt.show(
              "Are you sure?",
              "This will create new Google Drive file with clean data."
            ).then((res) => {
              if (res) {
                initNewSyncFile();
              }
            });
          }}
        >
          new file
        </Button>
      </Box>

      <Box m={2}>
        <Button variant="outlined" onClick={exportJSON}>
          Export JSON Backup
        </Button>
      </Box>
      <Box m={2}>
        <Button variant="outlined" onClick={importJSON}>
          Import JSON Backup
        </Button>
      </Box>

      <Box m={2}>
        {!isDriveSyncEnabled && (
          <Button variant="outlined" onClick={enableDriveSync}>
            Enable Google Drive Sync
          </Button>
        )}

        {isDriveSyncEnabled && (
          <Button variant="outlined" onClick={disabledDriveSync}>
            Disable Google Drive Sync
          </Button>
        )}
      </Box>

      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            openModal("importToAccount", undefined);
          }}
        >
          Import to Account
        </Button>
      </Box>

      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            openModal("exportFromAccount", undefined);
          }}
        >
          Export from Account
        </Button>
      </Box>

      <Box m={2}>
        <Button
          variant="outlined"
          onClick={() => {
            Prompt.show(
              "Are you sure?",
              "You are about to wipe all local data and there is no coming back. Make sure to have backup or sync with google."
            ).then((res) => {
              if (res) {
                wipeData();
              }
            });
          }}
        >
          Wipe Local Data
        </Button>
      </Box>
      <Box flex={1} />
      <Box alignItems="center">
        <Typography color="whitesmoke">
          Version: {packageJson.version}
        </Typography>
      </Box>

      {(exportJSONStat.loading || importJSONStat.loading) && (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(0, 0, 0, 0.5)"
          zIndex={100}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Settings;
