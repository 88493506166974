import {
  Autocomplete,
  Button,
  createFilterOptions,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React from "react";
import { Tag } from "types/data.types";
import { useAtom } from "@m1st1ck/atomjs-react";
import { quickTagsAtom, tagsAtom } from "utils/atoms";
import Prompt from "components/Prompt";
import { Controller, useForm } from "react-hook-form";

type QuickTagsModalProps = {
  resolve: (tags: string[]) => void;
};

const QuickTagsModal: React.FC<QuickTagsModalProps> = ({ resolve }) => {
  type FormData = { tags: Tag["uid"][] };
  const { control, handleSubmit } = useForm<FormData>();
  const [tags] = useAtom(tagsAtom);

  const handleApply = (data: FormData) => {
    resolve(data.tags);
    quickTagsAtom.setState(data.tags);
  };

  const handleClose = () => {
    resolve(quickTagsAtom.getState());
  };

  const handleClear = () => {
    resolve([]);
    quickTagsAtom.setState([]);
  };

  return (
    <form onSubmit={handleSubmit(handleApply)}>
      <DialogContent>
        <Controller
          name="tags"
          control={control}
          defaultValue={quickTagsAtom.getState()}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              filterOptions={(options, params) => {
                const filtered = createFilterOptions<string>()(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.includes(inputValue);
                if (inputValue !== "" && !isExisting) {
                  filtered.push(`+ "${inputValue}"`);
                }

                return filtered;
              }}
              multiple
              freeSolo
              getOptionLabel={(option) => option}
              options={tags.map((t) => t.uid)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  fullWidth
                  margin="dense"
                  helperText=" "
                />
              )}
              value={value}
              onChange={(__, newValue: string[] | null) => {
                if (newValue) {
                  onChange(
                    newValue?.map((val) =>
                      val.includes(`+ "`) ? val.slice(3, -1) : val
                    )
                  );
                }
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClear}>
          Clear
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" type="submit">
          Apply
        </Button>
      </DialogActions>
    </form>
  );
};

export const openQuickTagsModal = () => {
  Prompt.show("Quick tags", (resolve) => <QuickTagsModal resolve={resolve} />, {
    actions: [],
  });
};
