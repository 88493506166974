import { QuickAction } from "types/data.types";
import { v4 } from "uuid";
import { loadDb, Upsert } from "./database";

export const getQuickActions = async () => {
  const db = await loadDb;
  return (await db.getAll("quickActions")).filter((c) => c.status !== "DELETE");
};

export const exportQuickActions = async () => {
  const db = await loadDb;
  return db.getAll("quickActions");
};

export const importQuickAction = async (quickAction: QuickAction) => {
  const db = await loadDb;
  await db.put("quickActions", quickAction);
};

export const clearQuickActionsState = async (quickActions: QuickAction[]) => {
  const db = await loadDb;
  const tx = db.transaction("quickActions", "readwrite");
  const quickActionsStore = tx.objectStore("quickActions");

  let cursor = await quickActionsStore.openCursor();

  while (cursor) {
    const quickAction = cursor.value;

    if (quickActions.some((t) => t.uid === quickAction.uid)) {
      // TODO: should should cursor.update and await?
      quickActionsStore.put({
        ...quickAction,
        status: undefined,
      });
    }

    cursor = await cursor.continue();
  }
};

export const deleteQuickAction = async (qa: QuickAction) => {
  const db = await loadDb;
  await db.delete("quickActions", qa.uid);
};

export const deleteAllQuickActions = async () => {
  const db = await loadDb;
  await db.clear("quickActions");
};

export const markQuickActionForDeletion = async (qaId: QuickAction["uid"]) => {
  const db = await loadDb;

  const tx = db.transaction("quickActions", "readwrite");
  const quickActionsStore = tx.objectStore("quickActions");

  const qa = await quickActionsStore.get(qaId);

  if (!qa) {
    throw new Error("QA does not exits!");
  }

  const nQa: QuickAction = {
    ...qa,
    status: "DELETE",
  };

  await quickActionsStore.put(nQa);

  return nQa;
};

export const upsertQuickAction = async (quickAction: Upsert<QuickAction>) => {
  const db = await loadDb;

  const qa = quickAction.uid
    ? await db.get("quickActions", quickAction.uid)
    : undefined;

  const isNew = !qa?.uid;

  const nQuickAction: QuickAction = {
    ...(quickAction as QuickAction),
    updateDate: new Date(),
    createDate: qa?.createDate || new Date(),
    uid: qa?.uid || v4(),
    status: isNew ? "ADD" : qa.status,
  };

  await db.put("quickActions", nQuickAction);

  return {
    new: isNew,
    quickAction: nQuickAction,
  };
};
