import { DragIndicator, Edit, ViewAgenda } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  ListItem,
  Typography,
  CircularProgress,
  IconButton,
  AccordionDetails,
} from "@mui/material";
import { useAtom } from "@m1st1ck/atomjs-react";
import Big from "big.js";
import { useModal } from "components/ModalProvider";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { Account } from "types/data.types";
import {
  currenciesAtom,
  accountsTotalAtom,
  transactionQueryAtom,
} from "utils/atoms";
import { formatNumber } from "utils/format";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "components/Box";

type AccountItemProps = {
  account: Account;
  index: number;
};

export default function AccountItem({ account, index }: AccountItemProps) {
  const [currencies] = useAtom(currenciesAtom);
  const navigate = useNavigate();
  const { openModal } = useModal();

  const [opened, setOpened] = useState(false);

  const [accountsTotal, accountsTotalStat] = useAtom(accountsTotalAtom);

  const accTotal = accountsTotal[account.uid];

  const viewTransactionsForAccount = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    transactionQueryAtom.setState(["account", true, "is", account.name]);

    navigate("/dashboard");
  };

  const onlyMainCurrency = accTotal
    ? Object.keys(accTotal || {}).length === 1 && accTotal[account.currency.uid]
    : true;

  return (
    <Draggable draggableId={account.uid} index={index}>
      {(provided, snapshot) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          expanded={opened}
          onChange={(_, expanded) => {
            onlyMainCurrency ? setOpened(false) : setOpened(expanded);
          }}
        >
          <AccordionSummary
            expandIcon={
              onlyMainCurrency ? (
                <ExpandMoreIcon sx={{ opacity: 0 }} />
              ) : (
                <ExpandMoreIcon />
              )
            }
          >
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 0,
                opacity: snapshot.isDragging ? 0.5 : 1,
              }}
            >
              <Box
                {...provided.dragHandleProps}
                sx={{
                  width: 40,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DragIndicator />
              </Box>
              <Typography
                color="textSecondary"
                variant="caption"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: 1,
                }}
              >
                {account.name}
              </Typography>

              {accountsTotalStat.loading && (
                <CircularProgress size={14} sx={{ ml: 1 }} />
              )}
              {accountsTotalStat.loaded && (
                <Typography>
                  {formatNumber(
                    Object.keys(accTotal || {})
                      .reduce((acc, cName) => {
                        const cur =
                          currencies.find((c) => c.uid === cName) ||
                          accTotal[cName].currency;

                        const accCurr =
                          currencies.find(
                            (c) => c.uid === account.currency.uid
                          ) || account.currency;

                        return accTotal[cName].value
                          .times(accCurr.rate)
                          .div(cur?.rate || 1)
                          .plus(acc);
                      }, new Big(0))
                      .plus(account.base)
                      .toNumber() || 0
                  )}
                </Typography>
              )}

              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal("account", account);
                }}
                aria-label="edit account"
              >
                <Edit />
              </IconButton>
              <IconButton
                size="small"
                onClick={viewTransactionsForAccount}
                aria-label="view transactions for account"
              >
                <ViewAgenda />
              </IconButton>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(accTotal || {}).map((cur, index) => (
              <Box key={index}>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flex: 1,
                  }}
                >
                  {cur}
                </Typography>
                <Typography>
                  {account.currency.uid === cur
                    ? formatNumber(
                        accTotal[cur].value.plus(account.base).toNumber()
                      )
                    : formatNumber(accTotal[cur].value.toNumber())}
                </Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
}
