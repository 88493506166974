import { atom, waitForAtom } from "@m1st1ck/atomjs";
import { useAtom } from "@m1st1ck/atomjs-react";
import axios from "axios";
import Prompt from "components/Prompt";
import { format, isAfter } from "date-fns";
import { useEffect } from "react";
import {
  Account,
  AccountClean,
  AccountsGroup,
  Base,
  Currency,
  Profile,
  QuickAction,
  Tag,
  Transaction,
} from "types/data.types";
import {
  fetchAccounts,
  fetchAccountsGroups,
  fetchAccountsTotal,
  fetchCurrencies,
  fetchQuickActions,
  fetchTags,
} from "./actions";

import {
  deleteAccount,
  deleteAllAccounts,
  deleteAllCurrencies,
  deleteAllTags,
  deleteAllTransactions,
  deleteCurrency,
  deleteProfile,
  deleteTag,
  deleteTransaction,
  exportAccounts,
  exportTransactions,
  exportCurrencies,
  exportTags,
  importAccount,
  importCurrency,
  importTag,
  importTransaction,
  updateLastSyncedDate,
  exportProfile,
  clearTagsState,
  clearTransactionsState,
  clearAccountsState,
  clearCurrencysState,
  addSyncedProfile,
  updateProfile,
  exportQuickActions,
  importQuickAction,
  deleteQuickAction,
  deleteAllQuickActions,
  deleteAllAccountsGroups,
  exportAccountsGroups,
  importAccountsGroup,
  deleteAccountsGroup,
  clearAccountsGroupsState,
} from "database";
import storage from "./storage";
import {
  accountsAtom,
  accountsGroupsAtom,
  accountsGroupsOrderAtom,
  accountsOrderAtom,
  currenciesAtom,
  quickActionsAtom,
  tagsAtom,
} from "./atoms";
import MSnackbar, { getActionElement } from "components/MSnackbar";
import packageJson from "../../package.json";

export type DataObj = {
  accounts: Account[];
  accountsGroups: AccountsGroup[];
  currencies: Currency[];
  tags: Tag[];
  transactions: Transaction[];
  profile?: Profile;
  quickActions: QuickAction[];
};

export const getDataToExport = async (): Promise<DataObj> => {
  const currencies = await exportCurrencies();
  const accounts = await exportAccounts();
  const accountsGroups = await exportAccountsGroups();
  const transactions = await exportTransactions();
  const tags = await exportTags();
  const profile = await exportProfile();
  const quickActions = await exportQuickActions();

  return {
    currencies,
    accounts,
    accountsGroups,
    transactions,
    tags,
    profile,
    quickActions,
  };
};

export const getJSONToExport = async () => {
  return JSON.stringify(await getDataToExport());
};

export const exportJSONData = async () => {
  const jsonStr = await getJSONToExport();
  const filename = `${format(new Date(), "dd_MM_yyyy_HH_mm")}_FUNDS_NINJA_${
    packageJson.version
  }.json`;
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(jsonStr)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const importJSONToDB = async (data: {
  currencies?: Currency[];
  accounts?: Account[];
  accountsGroups?: AccountsGroup[];
  tags?: Tag[];
  transactions?: Transaction[];
}) => {
  await Promise.all(
    (data.currencies || []).map((c) =>
      importCurrency({
        ...c,
        createDate: new Date(c.createDate),
        updateDate: new Date(c.updateDate),
        status: c.status || "ADD",
      })
    )
  );

  await Promise.all(
    (data.accounts || []).map((c) =>
      importAccount({
        ...c,
        createDate: new Date(c.createDate),
        updateDate: new Date(c.updateDate),
        status: c.status || "ADD",
      })
    )
  );

  await Promise.all(
    (data.accountsGroups || []).map((c) =>
      importAccountsGroup({
        ...c,
        createDate: new Date(c.createDate),
        updateDate: new Date(c.updateDate),
        status: c.status || "ADD",
      })
    )
  );

  await Promise.all(
    (data.tags || []).map((c) =>
      importTag({
        ...c,
        createDate: new Date(c.createDate),
        updateDate: new Date(c.updateDate),
        status: c.status || "ADD",
      })
    )
  );

  await Promise.all(
    (data.transactions || []).map((c) =>
      importTransaction({
        ...c,
        createDate: new Date(c.createDate),
        updateDate: new Date(c.updateDate),
        date: new Date(c.date),
        status: c.status || "ADD",
      })
    )
  );

  fetchTags();
  fetchCurrencies();
  fetchAccounts();
  fetchAccountsGroups();
};

export const importJSONData = async () => {
  const element = document.createElement("input");
  element.setAttribute("type", "file");
  element.setAttribute("accept", ".json");
  element.style.display = "none";

  const promise = new Promise((resolve) => {
    document.body.onfocus = () => {
      setTimeout(() => {
        const files = element.files;
        if (!files || files.length === 0) {
          resolve(null);
        }
      }, 2000);

      document.body.onfocus = null;
    };

    element.addEventListener("change", () => {
      const files = element.files;

      if (!files || files.length === 0) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const result = JSON.parse(reader.result as string) as {
          currencies: Currency[];
          accounts: Account[];
          accountsGroups: AccountsGroup[];
          tags: Tag[];
          transactions: (
            | Transaction
            | (Transaction & {
                amount: number;
                toAmount: number;
                toAccount: AccountClean;
              })
          )[];
        };

        importJSONToDB(result).then(() => {
          Prompt.show("Done", "Import was successful", {
            actions: "Okay",
          });
          resolve(null);
        });
        console.log(result);
      });

      reader.readAsText(files[0]);
    });
  });

  document.body.appendChild(element);

  element.click();

  await promise;

  document.body.removeChild(element);
};

export const wipeData = async () => {
  await deleteAllTags();
  await deleteAllAccounts();
  await deleteAllAccountsGroups();
  await deleteAllCurrencies();
  await deleteAllTransactions();
  await deleteAllQuickActions();
  await deleteProfile();

  fetchTags();
  fetchCurrencies();
  fetchAccounts();
  fetchAccountsGroups();
  fetchQuickActions();
};

const clientId =
  "551053220907-ohklmidc3r6rsmlrdujro3tcrkvk0ce4.apps.googleusercontent.com";

export const reqToken = (): Promise<string> => {
  return new Promise((resolve) => {
    const token = storage.local.get<string>("auth_token");

    if (token) {
      resolve(token);
      return;
    }

    const refresh_token = storage.local.get<string>("auth_refresh_token");

    if (refresh_token) {
      axios
        .post<{
          access_token: string;
          expires_in: number;
          scope: string;
          token_type: "Bearer";
        }>(
          `https://europe-west1-m1st1ck-d15b8.cloudfunctions.net/api/google/refresh`,
          {
            refresh_token,
          }
        )
        .then((res) => {
          storage.local.set(
            "auth_token",
            res.data.access_token,
            res.data.expires_in * 1000
          );

          resolve(res.data.access_token);
        })
        .catch(() => {
          storage.local.remove("auth_refresh_token");
          reqToken().then((token) => {
            resolve(token);
          });
        });
      return;
    }

    // @ts-ignore
    const client = google.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope: "https://www.googleapis.com/auth/drive.appdata",
      ux_mode: "popup",
      // @ts-ignore
      callback: (response) => {
        console.log(response.code, window.location.origin);
        axios
          .post<{
            access_token: string;
            expires_in: number;
            id_token: string;
            refresh_token: string;
            scope: string;
            token_type: "Bearer";
          }>(
            `https://europe-west1-m1st1ck-d15b8.cloudfunctions.net/api/google/token`,
            {
              code: response.code,
              redirect: window.location.origin,
            }
          )
          .then((res) => {
            storage.local.set("auth_refresh_token", res.data.refresh_token);

            storage.local.set(
              "auth_token",
              res.data.access_token,
              res.data.expires_in * 1000
            );

            resolve(res.data.access_token);
          });
      },
    });
    client.requestCode();
  });
};

const getToken = async () => {
  const token = storage.local.get<string>("auth_token");

  if (token) {
    return token;
  }

  const refresh_token = storage.local.get<string>("auth_refresh_token");

  if (refresh_token) {
    try {
      const { data } = await axios.post<{
        access_token: string;
        expires_in: number;
        scope: string;
        token_type: "Bearer";
      }>(
        `https://europe-west1-m1st1ck-d15b8.cloudfunctions.net/api/google/refresh`,
        {
          refresh_token,
        }
      );
      storage.local.set(
        "auth_token",
        data.access_token,
        data.expires_in * 1000
      );

      return data.access_token;
    } catch {
      return await new Promise<string>((resolve) => {
        MSnackbar.show("Failed to connect to Google Drive", {
          action: getActionElement("ReAuth", () => {
            reqToken().then((res) => {
              resolve(res);
              MSnackbar.hide();
            });
          }),
        });
      });
    }
  }
};

const getDriveAppDataFile = async () => {
  const token = await getToken();

  if (!token) {
    return "No token";
  }

  const { data } = await axios.get<{
    files: {
      id: string;
      kind: string;
      mimeType: "application/octet-stream";
      name: string;
    }[];
    incompleteSearch: boolean;
    kind: string;
  }>("https://www.googleapis.com/drive/v3/files", {
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      spaces: "appDataFolder",
      orderBy: "createdTime desc",
    },
  });

  const id = data.files[0]?.id as string | undefined;

  if (!id) {
    return "No file";
  }

  const res = await axios.get<DataObj>(
    `https://www.googleapis.com/drive/v3/files/${id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {
        alt: "media",
      },
    }
  );

  return {
    file: res.data,
    fileId: id,
  };
};

export async function initNewSyncFile() {
  // TODO: popup asking what data to move;

  const profile = await exportProfile();

  // get current synced profile or create one
  const syncedProfile =
    profile?.syncedProfile || (await addSyncedProfile()).syncedProfile;

  const nProfile: Profile = {
    uid: "profile",
    updateDate: new Date(),
    lastSyncedDate: new Date(),
    accountsOrder: {},
    accountsGroupsOrder: [],
    syncedProfile,
  };

  createDriveAppDataFile(
    JSON.stringify({
      tags: [],
      accounts: [],
      accountsGroups: [],
      currencies: [],
      transactions: [],
      profile: nProfile,
    })
  );

  await deleteAllTags();
  await deleteAllAccounts();
  await deleteAllAccountsGroups();
  await deleteAllCurrencies();
  await deleteAllTransactions();

  await updateProfile(nProfile);

  await fetchTags();
  await fetchCurrencies();
  await fetchAccounts();
  await fetchAccountsGroups();
}

export const createDriveAppDataFile = async (data: string) => {
  const token = await reqToken();
  if (!token) {
    return Promise.reject("No token");
  }
  const { headers } = await axios.post(
    "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable",
    {
      name: `${format(new Date(), "dd_MM_yyyy_HH_mm")}_FUNDS_NINJA.fn`,
      parents: ["appDataFolder"],
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  const location = headers.location!;

  await axios.put(location, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const updateDriveAppDataFile = async (id: string, data: string) => {
  const token = await reqToken();
  if (!token) {
    return;
  }
  const { headers } = await axios.patch(
    `https://www.googleapis.com/upload/drive/v3/files/${id}?uploadType=resumable`,
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  const location = headers.location!;

  await axios.put(location, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const syncAtom = atom<{
  syncing: boolean;
  queue: boolean;
}>({ syncing: false, queue: false });

export const syncDriveData = async () => {
  const sync = syncAtom.getState();

  if (sync.syncing) {
    syncAtom.setState({ queue: true });
    return waitForAtom(syncAtom, ({ syncing }) => !syncing);
  }

  console.log("SYNC FETCH DATA");
  syncAtom.setState({ syncing: true });

  try {
    const res = await getDriveAppDataFile();

    if (res === "No token") {
      return "No token";
    }

    const data = await getDataToExport();

    if (res === "No file") {
      console.log("CREATE FILE");

      if (!data.profile?.syncedProfile) {
        data.profile = await addSyncedProfile();
      }

      const nData: DataObj = {
        tags: data.tags
          .filter((item) => item.status !== "DELETE")
          .map((item) => ({ ...item, status: undefined })),
        accounts: data.accounts
          .filter((item) => item.status !== "DELETE")
          .map((item) => ({ ...item, status: undefined })),
        accountsGroups: data.accountsGroups
          .filter((item) => item.status !== "DELETE")
          .map((item) => ({ ...item, status: undefined })),
        currencies: data.currencies
          .filter((item) => item.status !== "DELETE")
          .map((item) => ({ ...item, status: undefined })),
        transactions: data.transactions
          .filter((item) => item.status !== "DELETE")
          .map((item) => ({ ...item, status: undefined })),
        profile: data.profile,
        quickActions: data.quickActions
          .filter((item) => item.status !== "DELETE")
          .map((item) => ({ ...item, status: undefined })),
      };

      await createDriveAppDataFile(JSON.stringify(nData));
    } else {
      console.log("SYNC");

      const file = res.file;

      if (
        file.profile?.syncedProfile &&
        data.profile?.syncedProfile &&
        file.profile.syncedProfile !== data.profile.syncedProfile
      ) {
        // TODO: allow user to cancel, wipe local data and continue
        alert("You are trying to sync to wrong account");
        return;
      }

      const syncedCurrencies = await getSyncedRemoteItems(
        file.currencies,
        data.currencies,
        {
          updateItem: importCurrency,
          deleteItem: deleteCurrency,
        }
      );

      const syncedAccounts = await getSyncedRemoteItems(
        file.accounts,
        data.accounts,
        {
          updateItem: importAccount,
          deleteItem: deleteAccount,
        }
      );

      const syncedAccountsGroups = await getSyncedRemoteItems(
        file.accountsGroups,
        data.accountsGroups,
        {
          updateItem: importAccountsGroup,
          deleteItem: deleteAccountsGroup,
        }
      );

      const syncedTags = await getSyncedRemoteItems(file.tags, data.tags, {
        updateItem: importTag,
        deleteItem: deleteTag,
      });

      const syncedTransactions = await getSyncedRemoteItems(
        file.transactions,
        data.transactions,
        {
          updateItem: importTransaction,
          deleteItem: deleteTransaction,
        }
      );

      const syncedQuickActions = await getSyncedRemoteItems(
        file.quickActions,
        data.quickActions,
        {
          updateItem: importQuickAction,
          deleteItem: deleteQuickAction,
        }
      );

      const syncedProfile =
        file.profile && data.profile
          ? isAfter(
              new Date(file.profile.updateDate),
              new Date(data.profile.updateDate)
            )
            ? file.profile
            : data.profile
          : file.profile || data.profile || (await addSyncedProfile());

      const syncedDate = new Date();

      if (syncedProfile && !syncedProfile.syncedProfile) {
        // create new profile
        syncedProfile.syncedProfile = (await addSyncedProfile()).syncedProfile;
      }

      if (syncedProfile) {
        if (!syncedProfile.syncedFile) {
          syncedProfile.syncedFile = res.fileId;
        } else if (syncedProfile.syncedFile !== res.fileId) {
          // TODO: allow user to cancel, wipe local data and continue
          alert("You are trying to sync to wrong file");
          return;
        }

        await updateProfile(syncedProfile);
        syncedProfile.lastSyncedDate = syncedDate;
      }

      const nData: DataObj = {
        tags: syncedTags,
        accounts: syncedAccounts,
        accountsGroups: syncedAccountsGroups,
        currencies: syncedCurrencies,
        transactions: syncedTransactions,
        profile: syncedProfile,
        quickActions: syncedQuickActions,
      };

      await updateDriveAppDataFile(res.fileId, JSON.stringify(nData));

      await updateLastSyncedDate(syncedDate);

      accountsAtom.setState(nData.accounts);
      accountsGroupsAtom.setState(nData.accountsGroups);

      // TODO: set new transactions instead of fetching all;
      fetchAccountsTotal();
      currenciesAtom.setState(nData.currencies);
      // TODO: don't fetch globally but on demand
      tagsAtom.setState(nData.tags);
      accountsOrderAtom.setState(syncedProfile?.accountsOrder || {});
      accountsGroupsOrderAtom.setState(
        syncedProfile?.accountsGroupsOrder || []
      );
      quickActionsAtom.setState(nData.quickActions);
    }

    console.log("CLEAN UP");
    await clearTransactionsState(data.transactions);
    await clearTagsState(data.tags);
    await clearAccountsState(data.accounts);
    await clearAccountsGroupsState(data.accountsGroups);
    await clearCurrencysState(data.currencies);
  } finally {
    const sync = syncAtom.getState();

    syncAtom.setState({
      syncing: false,
      queue: false,
    });

    if (sync.queue) {
      syncDriveData();
    }
  }
};

export const getSyncedRemoteItems = async <T extends Base>(
  remoteItems: T[],
  localItems: T[],
  {
    updateItem,
    deleteItem,
  }: {
    updateItem: (item: T) => Promise<any>;
    deleteItem: (item: T) => Promise<any>;
  }
) => {
  // mutate without affecting remoteItems( on arr/root level only )
  const mutatableRemoteItems = [...(remoteItems || [])];
  const syncedItems: T[] = [];

  for (let localIndex = 0; localIndex < localItems.length; localIndex++) {
    const localItem = localItems[localIndex];

    // find remote item
    const remoteIndex = mutatableRemoteItems.findIndex(
      (item) => item.uid === localItem.uid
    );

    // not on remote and not new on local - delete from local
    if (remoteIndex === -1 && localItem.status !== "ADD") {
      await deleteItem(localItem);
      continue;
    }

    // not on remote and new on local - add to remote
    if (remoteIndex === -1) {
      syncedItems.push({
        ...localItem,
        status: undefined,
      });
      continue;
    }

    // TODO: should DELETE supersede updateDate???
    const remoteItem = mutatableRemoteItems[remoteIndex];
    // on remote and local - compare and use latest
    if (
      isAfter(new Date(remoteItem.updateDate), new Date(localItem.updateDate))
    ) {
      syncedItems.push({
        ...remoteItem,
        status: undefined,
      });
      await updateItem({
        ...remoteItem,
        status: undefined,
      });

      // local latest with delete status - not synced
    } else if (localItem.status !== "DELETE") {
      syncedItems.push({
        ...localItem,
        status: undefined,
      });
    }

    // remove parsed remote entries - parse remaining after
    mutatableRemoteItems.splice(remoteIndex, 1);
  }

  // parse remaining remotes that were not on local
  for (
    let remoteIndex = 0;
    remoteIndex < mutatableRemoteItems.length;
    remoteIndex++
  ) {
    const remoteItem = mutatableRemoteItems[remoteIndex];
    syncedItems.push({
      ...remoteItem,
      status: undefined,
    });
    await updateItem({
      ...remoteItem,
      status: undefined,
    });
  }

  return syncedItems;
};

export const driveSyncedAtom = atom(false);

export const enableDriveSync = async () => {
  await reqToken();
  driveSyncedAtom.setState(true);
  await syncDriveData();
};

export async function isDriveSyncEnabled() {
  const token = await getToken();
  driveSyncedAtom.setState(!!token);
  return !!token;
}

export function useIsDriveSyncEnabled() {
  const state = useAtom(driveSyncedAtom);

  useEffect(() => {
    isDriveSyncEnabled();
  }, []);

  return state;
}

export function disabledDriveSync() {
  storage.local.remove("auth_token");
  storage.local.remove("auth_refresh_token");
  driveSyncedAtom.setState(false);
}
