import * as d3 from "d3";
import { format } from "date-fns";

export type LineChartDataType = {
  date: number;
  amount: number;
  total: number;
};

type LineChartDataTupleType = [
  LineChartDataType["date"],
  LineChartDataType["total"]
];

// Declare the chart dimensions and margins.
const width = 1000;
const height = 500;
const marginTop = 40;
const marginRight = 64;
const marginBottom = 20;
const marginLeft = 64;

export function renderLineChart(
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  data: LineChartDataType[]
) {
  const tupleData: LineChartDataTupleType[] = data.map((d) => [
    d.date,
    d.total,
  ]);

  // Declare the x (horizontal position) scale.
  const x = d3.scaleUtc(d3.extent(tupleData, (d) => d[0]) as number[], [
    marginLeft,
    width - marginRight,
  ]);

  // Declare the y (vertical position) scale.
  const y = d3.scaleLinear(d3.extent(tupleData, (d) => d[1]) as number[], [
    height - marginBottom,
    marginTop + 16,
  ]);

  // Declare the line generator.
  const lineData = d3
    .line()
    .curve(d3.curveBumpX)
    .x((d) => x(d[0]))
    .y((d) => y(d[1]));

  const length = (path: string | null) =>
    (
      d3.create("svg:path").attr("d", path).node() as SVGGeometryElement
    ).getTotalLength();

  const l = length(lineData(tupleData));

  const svg = d3.select(svgRef.current).attr("viewBox", [0, 0, width, height]);

  // Add the x-axis.
  const xAxis = svg
    .append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x).ticks(width / 80))
    .call((g) => g.select(".domain").remove());

  // Add the y-axis, remove the domain line, add grid lines.
  svg
    .append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y).ticks(height / 40))
    .call((g) => g.select(".domain").remove())
    .call((g) =>
      g
        .selectAll(".tick line")
        .attr("x2", width - marginLeft - marginRight)
        .attr("stroke-opacity", 0.4)
    );

  const indexLine = svg
    .append("g")
    .append("line")
    .attr("y1", height - marginBottom)
    .attr("y2", marginTop)
    .attr("stroke", "transparent");

  const labelsContainer = svg
    .append("g")
    .attr("font-size", 24)
    .attr("font-weight", "bold");

  const labelDate = labelsContainer
    .append("text")
    .attr("text-anchor", "end")
    .attr("transform", `translate(-4,${marginTop})`);

  const labelTotal = labelsContainer
    .append("text")
    .attr("transform", `translate(0,${marginTop - 20})`)
    .attr("text-anchor", "middle");

  const labelAmount = labelsContainer
    .append("text")
    .attr("text-anchor", "start")
    .attr("transform", `translate(4,${marginTop})`);

  // Add a clipPath: everything out of this area won't be drawn.
  svg
    .append("defs")
    .append("svg:clipPath")
    .attr("id", "clip")
    .append("svg:rect")
    .attr("width", width - marginLeft - marginRight)
    .attr("height", height)
    .attr("x", marginLeft)
    .attr("y", 0);

  // Create the line variable: where both the line and the brush take place
  const lineGroup = svg.append("g").attr("clip-path", "url(#clip)");

  // Append a path for the line.
  lineGroup
    .append("path")
    .attr("class", "line")
    .attr("fill", "none")
    .attr("stroke", "skyblue")
    .attr("stroke-width", 0)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("stroke-dasharray", `0,${l}`)
    .attr("d", lineData(tupleData))
    .transition()
    .delay(500)
    .duration(1000)
    .ease(d3.easeLinear)
    .attr("stroke-width", 3)
    .attr("stroke-dasharray", `${l},${l}`);

  const circles = svg
    .append("g")
    .attr("clip-path", "url(#clip)")
    .selectAll("circle")
    .data(data)
    .join("circle")
    .attr("cx", (d) => x(d.date))
    .attr("cy", (d) => y(d.total))
    .attr("r", 0);

  circles
    .each(function (d) {
      const t = d3.select(this);
      t.attr("fill", d.amount > 0 ? "#66bb6a" : "tomato");
    })
    .transition()
    .delay((_, i) => 500 + (1000 / data.length) * i)
    .duration(200)
    .ease(d3.easeLinear)
    .attr("r", 5);

  let idleTimeout: any = null;
  function idled() {
    idleTimeout = null;
  }

  function updateChart(event: any) {
    // What are the selected boundaries?
    const extent = event.selection;

    if (!extent) {
      return;
    }

    x.domain([x.invert(extent[0]), x.invert(extent[1])]);
    brush.move(lineGroup.select(".brush"), null); // This remove the grey brush area as soon as the selection has been done

    rerender();
  }

  // Add brushing
  const brush = d3
    .brushX() // Add the brush feature using the d3.brush function
    .extent([
      [0, marginTop + 4],
      [width, height - marginBottom],
    ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
    .on("end", updateChart);

  lineGroup.append("g").attr("class", "brush").call(brush);

  function rerender() {
    xAxis.transition().duration(1000).call(d3.axisBottom(x));

    const l = length(lineData(tupleData));

    lineGroup.select(".line").remove();

    lineGroup
      .append("path")
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "skyblue")
      .attr("stroke-width", 0)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-dasharray", `0,${l}`)
      .attr("d", lineData(tupleData))
      .transition()
      .duration(500)
      .ease(d3.easeLinear)
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", `${l},${l}`);

    circles
      .attr("cx", (d) => x(d.date))
      .attr("r", 0)
      .each(function (d) {
        const t = d3.select(this);
        t.attr("fill", d.amount > 0 ? "#66bb6a" : "tomato");
      })
      .transition()
      .delay(500)
      .duration(200)
      .ease(d3.easeLinear)
      .attr("r", 5);
  }

  function move(this: SVGSVGElement | null, event: any) {
    event.preventDefault();
    const mouse = d3.pointer(
      {
        clientX: event.clientX || event.changedTouches[0].clientX,
        clientY: event.clientY || event.changedTouches[0].clientY,
        pageX: event.pageX || event.changedTouches[0].clientX,
        pageY: event.pageY || event.changedTouches[0].clientX,
      },
      this
    );

    const mouseDate = x.invert(mouse[0]);
    const mouseTotal = y.invert(mouse[1]);

    const dataItem = data
      .sort(
        (a, b) =>
          Math.abs(mouseTotal - a.total) - Math.abs(mouseTotal - b.total)
      )
      .sort(
        (a, b) =>
          Math.abs(mouseDate.getTime() - a.date) -
          Math.abs(mouseDate.getTime() - b.date)
      )[0];

    const positionX = Math.max(
      Math.min(x(x.invert(mouse[0])), width - marginRight),
      0 + marginLeft
    );
    indexLine
      .attr("transform", `translate(${positionX + 0.5},0)`)
      .attr("stroke", dataItem.amount > 0 ? "#66bb6a" : "tomato");

    labelsContainer.attr("transform", `translate(${positionX},0)`);

    labelDate
      .text(`${format(dataItem.date, "dd/MM")}`)
      .attr("font-size", 14)
      .attr("fill", "skyblue");

    labelAmount
      .text(`${Math.abs(dataItem.amount).toFixed(2)}`)
      .attr("font-size", 14)
      .attr("fill", dataItem.amount > 0 ? "#66bb6a" : "tomato");

    labelTotal
      .text(`${Math.abs(dataItem.total).toFixed(2)}`)
      .attr("fill", dataItem.total > 0 ? "#66bb6a" : "tomato");
  }

  if (data.length > 0) {
    svg.on("touchstart", (e) => {
      e.preventDefault();
      if (idleTimeout) {
        x.domain(d3.extent(tupleData, (d) => d[0]) as number[]);
        rerender();
      }
      idleTimeout = setTimeout(idled, 350);
    });

    svg.on("mousemove", move);

    svg.on("dblclick", () => {
      x.domain(d3.extent(tupleData, (d) => d[0]) as number[]);
      rerender();
    });
  }
}

export type PieChartDataTypeNested = {
  name: string;
  value: number;
  children?: PieChartDataTypeNested[];
};

export type PieChartDataType = {
  name: string;
  children: PieChartDataTypeNested[];
  value: number;
};

type Target = {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
};

const radius = width / 6;

export function renderPieChart(
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  data: PieChartDataType
) {
  const partition = (data: PieChartDataType) => {
    const root = d3
      .hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value! - a.value!);

    return d3
      .partition<PieChartDataType>()
      .size([2 * Math.PI, root.height + 1])(root);
  };

  const color = d3.scaleOrdinal(
    d3.quantize(d3.interpolateRainbow, data.children.length + 1)
  );

  const arc = d3
    .arc<Target>()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

  const root = partition(
    data
  ) as d3.HierarchyRectangularNode<PieChartDataType> & {
    current: Target;
    target?: Target;
  };

  root.each((d) => (d.current = d));

  const svg = d3
    .select(svgRef.current)
    .attr("viewBox", [0, 0, width, width])
    .style("font", "16px sans-serif");

  const g = svg
    .append("g")
    .attr("transform", `translate(${width / 2},${width / 2})`);

  const path = g
    .append("g")
    .selectAll("path")
    .data(root.descendants().slice(1))
    .join("path")
    .attr("fill", (d) => {
      while (d.depth > 1) d = d.parent!;
      return color(d.data.name);
    })
    .attr("fill-opacity", (d) =>
      arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
    )
    .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))

    .attr("d", (d) => arc(d.current));

  path
    .filter((d) => !!d.children)
    .style("cursor", "pointer")
    .on("click", clicked);

  path.append("title").text(
    (d) =>
      `${d
        .ancestors()
        .map((d) => d.data.name)
        .reverse()
        .join("/")}\n${d.value?.toFixed(2)}`
  );

  const label = g
    .append("g")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .style("user-select", "none")
    .selectAll("text")
    .data(root.descendants().slice(1))
    .join("text")
    .attr("dy", "0.35em")
    .attr("fill-opacity", (d) => +labelVisible(d.current))
    .attr("transform", (d) => labelTransform(d.current))
    .text((d) => d.data.name);

  const parent = g
    .append("circle")
    .datum(root)
    .attr("r", radius)
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .on("click", clicked);

  function clicked(_: any, p: d3.HierarchyRectangularNode<PieChartDataType>) {
    parent.datum(p.parent || root);

    root.each(
      (d) =>
        (d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        })
    );

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    path
      .transition()
      .duration(750)
      .tween("data", (d) => {
        const i = d3.interpolate(d.current, d.target!);
        return (t) => (d.current = i(t));
      })
      .filter(function (d) {
        return (
          !!(this && "getAttribute" in this
            ? Number(this.getAttribute("fill-opacity"))
            : false) || arcVisible(d.target!)
        );
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.target!) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr("pointer-events", (d) => (arcVisible(d.target!) ? "auto" : "none"))

      .attrTween("d", (d) => () => arc(d.current)!);

    label
      .filter(function (d) {
        return (
          !!(this && "getAttribute" in this
            ? Number(this.getAttribute("fill-opacity"))
            : false) || labelVisible(d.target!)
        );
      })
      .transition()
      .duration(750)
      .attr("fill-opacity", (d) => +labelVisible(d.target!))
      .attrTween("transform", (d) => () => labelTransform(d.current));
  }

  function arcVisible(d: Target) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d: Target) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }

  function labelTransform(d: Target) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }
}
