import Big from "big.js";
import { isAfter, isBefore, isValid } from "date-fns";
import { Currency, CurrencyClean, Transaction } from "types/data.types";
import { defaultQuery, TYPES } from "utils/constants";
import { v4 } from "uuid";
import { loadDb, Upsert } from "./database";

export const exportTransactions = async () => {
  const db = await loadDb;
  return db.getAll("transactions");
};

export const importTransaction = async (transaction: Transaction) => {
  const db = await loadDb;
  await db.put("transactions", transaction);
};

export const clearTransactionsState = async (transactions: Transaction[]) => {
  const db = await loadDb;
  const tx = db.transaction("transactions", "readwrite");
  const transactionsStore = tx.objectStore("transactions");

  let cursor = await transactionsStore.openCursor();

  while (cursor) {
    const transaction = cursor.value;

    if (transactions.some((t) => t.uid === transaction.uid)) {
      // TODO: should should cursor.update and await?
      transactionsStore.put({
        ...transaction,
        status: undefined,
      });
    }

    cursor = await cursor.continue();
  }
};

export const deleteTransaction = async (tran: Transaction) => {
  const db = await loadDb;
  await db.delete("transactions", tran.uid);
};

export const deleteAllTransactions = async () => {
  const db = await loadDb;
  await db.clear("transactions");
};

export const markTransactionForDeletion = async (
  tranUid: Transaction["uid"]
) => {
  const db = await loadDb;

  const tx = db.transaction("transactions", "readwrite");
  const transactionsStore = tx.objectStore("transactions");

  const tran = await transactionsStore.get(tranUid);

  if (!tran) {
    throw new Error("Transaction not found!");
  }

  const nTran: Transaction = {
    ...tran,
    status: "DELETE",
  };

  await transactionsStore.put(nTran);

  return nTran;
};

export const upsertTransaction = async (transaction: Upsert<Transaction>) => {
  const db = await loadDb;

  const trans = transaction.uid
    ? await db.get("transactions", transaction.uid)
    : undefined;

  const isNew = !trans?.uid;

  const nTransaction: Transaction = {
    ...(transaction as Transaction),
    updateDate: new Date(),
    createDate: trans?.createDate || new Date(),
    uid: trans?.uid || v4(),
    status: isNew ? "ADD" : trans.status,
    log: [
      ...(trans?.log || []),
      {
        event: isNew ? "ADD" : "UPDATE",
        timestamp: new Date(),
        userAgent: window.navigator.userAgent,
      },
    ],
  };

  nTransaction.tags.forEach((tag) => {
    db.add("tags", {
      updateDate: new Date(),
      uid: tag,
      createDate: new Date(),
      status: "ADD",
      log: [
        {
          event: "ADD",
          timestamp: new Date(),
          userAgent: window.navigator.userAgent,
        },
      ],
    }).catch((err: DOMException) => {
      console.log("transactions tags", err);
    });
  });

  await db.put("transactions", nTransaction);

  return {
    new: isNew,
    transaction: nTransaction,
  };
};

const isLogicalOprator = (operator?: Query[3]) =>
  operator === "AND" || operator === "OR";

export const isQueryMatch = (arr: Query, transaction: Transaction): boolean => {
  if (arr.length === 0) {
    return true;
  }

  if (isLogicalOprator(arr[3])) {
    const index = arr.length - 1;
    if (arr[index - 1] === "AND") {
      return (
        isQueryMatch(arr[index] as Query, transaction) &&
        isQueryMatch(arr.slice(0, -2) as Query, transaction)
      );
    }

    if (arr[index - 1] === "OR") {
      return (
        isQueryMatch(arr[index] as Query, transaction) ||
        isQueryMatch(arr.slice(0, -2) as Query, transaction)
      );
    }
  }

  if (arr[1] === "AND") {
    return (
      isQueryMatch(arr[0], transaction) && isQueryMatch(arr[2], transaction)
    );
  }

  if (arr[1] === "OR") {
    return (
      isQueryMatch(arr[0], transaction) || isQueryMatch(arr[2], transaction)
    );
  }

  const not = Number(!arr[1]);

  const testy = (res: boolean) => {
    return Boolean(Number(res) ^ not);
  };

  if (arr[3] === "") {
    return testy(true);
  }

  if (arr[0] === "amount") {
    const transVal = transaction[arr[0]];
    if (arr[2] === "less than") {
      // maybe account for currency => compare to account currecny
      return testy(transVal.value < arr[3]);
    }

    if (arr[2] === "greater than") {
      return testy(transVal.value > arr[3]);
    }

    if (arr[2] === "is") {
      return testy(transVal.value === arr[3]);
    }

    return testy(false);
  }

  if (arr[0] === "toAmount") {
    // TODO: maybe account for currency => compare to account currecny
    const transVal = transaction[arr[0]];
    if (arr[2] === "less than" && transVal) {
      return testy(transVal.value < arr[3]);
    }

    if (arr[2] === "greater than" && transVal) {
      return testy(transVal.value > arr[3]);
    }

    if (arr[2] === "is") {
      return testy(transVal?.value === arr[3]);
    }

    return testy(false);
  }

  if (arr[0] === "currency") {
    if (arr[2] === "is") {
      return testy(transaction.amount.currency.uid === arr[3].uid);
    }

    return testy(false);
  }

  if (arr[0] === "account") {
    if (arr[2] === "is") {
      return testy(transaction[arr[0]].name === arr[3]);
    }

    return testy(false);
  }

  if (arr[0] === "toAccount") {
    if (arr[2] === "is") {
      return testy(transaction[arr[0]]?.name === arr[3]);
    }

    return testy(false);
  }

  if (arr[0] === "date") {
    const transDate = new Date(transaction[arr[0]].toString());
    const valDate = new Date(arr[3].toString());
    const isValidDates = isValid(transDate) && isValid(valDate);

    if (arr[2] === "is after") {
      return testy(isValidDates && isAfter(transDate, valDate));
    }

    if (arr[2] === "is before") {
      return testy(isValidDates && isBefore(transDate, valDate));
    }

    if (arr[2] === "is after or same") {
      return testy(
        isValidDates &&
          (transDate.toISOString() === valDate.toISOString() ||
            isAfter(transDate, valDate))
      );
    }

    if (arr[2] === "is before or same") {
      return testy(
        isValidDates &&
          (transDate.toISOString() === valDate.toISOString() ||
            isBefore(transDate, valDate))
      );
    }

    if (arr[2] === "is") {
      return testy(
        isValidDates && transDate.toISOString() === valDate.toISOString()
      );
    }

    return testy(false);
  }

  if (arr[0] === "description") {
    const transVal = transaction[arr[0]].toLowerCase();
    const val = arr[3].toLowerCase();
    if (arr[2] === "contains") {
      return testy(transVal.includes(val));
    }

    if (arr[2] === "starts with") {
      return testy(transVal.startsWith(val));
    }

    if (arr[2] === "ends with") {
      return testy(transVal.endsWith(val));
    }

    if (arr[2] === "is") {
      return testy(transVal === val);
    }

    return testy(false);
  }

  if (arr[0] === "tags") {
    if (arr[2] === "contain") {
      return testy(transaction[arr[0]].some((a) => arr[3].includes(a)));
    }

    if (arr[2] === "contain all") {
      return testy(!arr[3].some((a) => !transaction[arr[0]].includes(a)));
    }

    if (arr[2] === "contain only") {
      return testy(
        transaction[arr[0]].every((a) => arr[3].includes(a)) &&
          (transaction[arr[0]].length > 0 || arr[3].length === 0)
      );
    }

    if (arr[2] === "contain exact") {
      return testy(
        transaction[arr[0]].every((a) => arr[3].includes(a)) &&
          transaction[arr[0]].length === arr[3].length
      );
    }

    return testy(false);
  }

  if (arr[0] === "type") {
    if (arr[2] === "is") {
      return testy(transaction[arr[0]] === arr[3]);
    }

    return testy(false);
  }

  return testy(false);
};

export const getAccountsTotal = async () => {
  const db = await loadDb;

  const tx = db.transaction("transactions", "readonly");

  let cursor = await tx.store.openCursor();

  const accounts: {
    [acc: string]: {
      [cur: string]: {
        value: Big;
        currency: CurrencyClean;
      };
    };
  } = {};

  while (cursor) {
    const transaction = cursor.value;

    if (transaction.status !== "DELETE") {
      const baseAmount = transaction.amount.value;
      const amountCurrencyUid = transaction.amount.currency.uid;
      const accId = transaction.account.uid;

      if (!accounts[accId]) {
        accounts[accId] = {};
      }

      if (!accounts[accId][amountCurrencyUid]) {
        accounts[accId][amountCurrencyUid] = {
          value: new Big(0),
          currency: transaction.amount.currency,
        };
      }

      if (transaction.type === TYPES.transfer) {
        const toAmountCurrencyUid = transaction.toAmount.currency.uid;
        const baseToAmount = transaction.toAmount.value;
        const toAccId = transaction.toAccount.uid;

        if (!accounts[toAccId]) {
          accounts[toAccId] = {};
        }

        if (!accounts[toAccId][toAmountCurrencyUid]) {
          accounts[toAccId][toAmountCurrencyUid] = {
            value: new Big(0),
            currency: transaction.amount.currency,
          };
        }

        accounts[accId][amountCurrencyUid] = {
          value: accounts[accId][amountCurrencyUid].value.minus(baseAmount),
          currency: transaction.amount.currency,
        };
        accounts[toAccId][toAmountCurrencyUid] = {
          value:
            accounts[toAccId][toAmountCurrencyUid].value.plus(baseToAmount),
          currency: transaction.toAmount.currency,
        };
      } else if (transaction.type === TYPES.expense) {
        accounts[accId][amountCurrencyUid] = {
          value: accounts[accId][amountCurrencyUid].value.minus(baseAmount),
          currency: transaction.amount.currency,
        };
      } else {
        accounts[accId][amountCurrencyUid] = {
          value: accounts[accId][amountCurrencyUid].value.plus(baseAmount),
          currency: transaction.amount.currency,
        };
      }
    }
    cursor = await cursor.continue();
  }

  return accounts;
};

export const getTransactions = async (query: Query = defaultQuery) => {
  const db = await loadDb;

  const tx = db.transaction("transactions", "readonly");

  let cursor = await tx.store.openCursor();

  const trans: Transaction[] = [];

  while (cursor) {
    const transaction = cursor.value;

    if (transaction.status !== "DELETE" && isQueryMatch(query, transaction)) {
      trans.push(transaction);
    }
    cursor = await cursor.continue();
  }

  return trans;
};

export type QueryLogicalOperator = "OR" | "AND";

export type QueryConditionTags = [
  "tags",
  boolean,
  "contain" | "contain all" | "contain only" | "contain exact",
  string[]
];

export type QueryConditionAmount = [
  "amount",
  boolean,
  "is" | "greater than" | "less than",
  number
];

export type QueryConditionCurrency = ["currency", boolean, "is", Currency];

export type QueryConditionToAmount = [
  "toAmount",
  boolean,
  "is" | "greater than" | "less than",
  number
];

export type QueryConditionDate = [
  "date",
  boolean,
  "is" | "is after" | "is before" | "is after or same" | "is before or same",
  Date
];

export type QueryConditionDescription = [
  "description",
  boolean,
  "is" | "starts with" | "ends with" | "contains",
  string
];

export type QueryConditionType = ["type", boolean, "is", TYPES];
export type QueryConditionAccount = ["account", boolean, "is", string];
export type QueryConditionToAccount = ["toAccount", boolean, "is", string];

export type QueryCondition =
  | QueryConditionTags
  | QueryConditionAmount
  | QueryConditionToAmount
  | QueryConditionCurrency
  | QueryConditionAccount
  | QueryConditionToAccount
  | QueryConditionDate
  | QueryConditionDescription
  | QueryConditionType;

export type Query = [] | QueryCondition | QueryBlock;
export type QueryBlock =
  | [Query, QueryLogicalOperator, Query]
  // ...to infinity
  | [Query, QueryLogicalOperator, Query, QueryLogicalOperator, Query];
