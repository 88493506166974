import * as React from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { Popover } from "@mui/material";

export default function EmojiSelector({
  onChange,
  render,
}: {
  onChange: (value: EmojiClickData) => void;
  render: (options: {
    open: (event: React.MouseEvent<HTMLElement>) => void;
  }) => React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {render({
        open: handleClick,
      })}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <EmojiPicker
          onEmojiClick={(emoji) => {
            onChange(emoji);
            handleClose();
          }}
        />
      </Popover>
    </>
  );
}
