import { Tag, Transaction } from "types/data.types";
import { loadDb } from "./database";

export const getTags = async () => {
  const db = await loadDb;
  return (await db.getAll("tags")).filter((c) => c.status !== "DELETE");
};

export const exportTags = async () => {
  const db = await loadDb;
  return db.getAll("tags");
};

export const clearTagsState = async (tags: Tag[]) => {
  const db = await loadDb;
  const tx = db.transaction("tags", "readwrite");
  const tagsStore = tx.objectStore("tags");

  let cursor = await tagsStore.openCursor();

  while (cursor) {
    const tag = cursor.value;

    if (tags.some((t) => t.uid === tag.uid)) {
      // cursor update and await?
      tagsStore.put({
        ...tag,
        status: undefined,
      });
    }

    cursor = await cursor.continue();
  }
};

export const importTag = async (tag: Tag) => {
  const db = await loadDb;
  await db.put("tags", tag);
};

export const deleteTag = async (tag: Tag) => {
  const db = await loadDb;
  await db.delete("tags", tag.uid);
};

export const deleteAllTags = async () => {
  const db = await loadDb;
  await db.clear("tags");
};

// TODO: should this move to transactions???
export const removeTagsFromTransaction = async (
  tags: string[],
  transaction: Transaction
) => {
  const db = await loadDb;
  const trans = await db.get("transactions", transaction.uid);

  if (!trans) {
    throw new Error("No transaction");
  }

  trans.tags = trans.tags.filter((t) => !tags.includes(t));
  trans.updateDate = new Date();
  await db.put("transactions", trans);

  return trans;
};

export const addTagsToTransaction = async (
  tags: string[],
  transaction: Transaction
) => {
  const db = await loadDb;
  const trans = await db.get("transactions", transaction.uid);

  if (!trans) {
    throw new Error("No transaction");
  }

  tags.forEach((tag) => {
    db.add("tags", {
      updateDate: new Date(),
      uid: tag,
      createDate: new Date(),
      status: "ADD",
      log: [
        {
          event: "ADD",
          timestamp: new Date(),
          userAgent: window.navigator.userAgent,
        },
      ],
    }).catch((err: DOMException) => {
      console.log("tags", err);
    });
  });

  trans.tags = [...trans.tags, ...tags.filter((t) => !trans.tags.includes(t))];
  trans.updateDate = new Date();
  await db.put("transactions", trans);
  return trans;
};

export const markTagForDeletion = async (tag: Tag) => {
  const db = await loadDb;

  const nTag: Tag = {
    ...tag,
    status: "DELETE",
  };

  await db.put("tags", nTag);

  return nTag;
};

export const mergeTags = async (deleteTag: Tag, useTag: Tag) => {
  const db = await loadDb;

  const tx = db.transaction(["tags", "transactions"], "readwrite");

  const transactionsStore = tx.objectStore("transactions");
  const tagsStore = tx.objectStore("tags");

  let cursor = await transactionsStore.openCursor();

  while (cursor) {
    const transaction = cursor.value;

    if (transaction.tags.includes(deleteTag.uid)) {
      transactionsStore.put({
        ...transaction,
        updateDate: new Date(),
        tags: [
          ...transaction.tags.filter((t) => t !== deleteTag.uid),
          useTag.uid,
        ],
      });
    }

    cursor = await cursor.continue();
  }

  await tagsStore.put({
    ...deleteTag,
    status: "DELETE",
  });
};

export const renameTag = async (tag: Tag, uid: string) => {
  const db = await loadDb;

  const tx = db.transaction(["tags", "transactions"], "readwrite");

  const transactionsStore = tx.objectStore("transactions");
  const tagsStore = tx.objectStore("tags");

  let cursor = await transactionsStore.openCursor();

  while (cursor) {
    const transaction = cursor.value;

    if (transaction.tags.includes(tag.uid)) {
      // TODO: cursor update and await
      transactionsStore.put({
        ...transaction,
        updateDate: new Date(),
        tags: [...transaction.tags.filter((t) => t !== tag.uid), uid],
      });
    }

    cursor = await cursor.continue();
  }

  await tagsStore.put({
    ...tag,
    status: "DELETE",
  });
  await tagsStore.put({
    uid,
    updateDate: new Date(),
    createDate: tag.createDate,
    status: "ADD",
    log: [
      {
        event: "ADD",
        timestamp: new Date(),
        userAgent: window.navigator.userAgent,
      },
    ],
  });
};
