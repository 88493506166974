import { Profile } from "types/data.types";
import { v4 } from "uuid";
import { loadDb } from "./database";

export const updateProfile = async (profile: Profile) => {
  const db = await loadDb;

  await db.put("profile", {
    ...profile,
    uid: "profile",
  });
};

export const addSyncedProfile = async () => {
  const db = await loadDb;
  const tx = db.transaction(["profile"], "readwrite");
  const profileStore = tx.objectStore("profile");
  const profile = await profileStore.get("profile");

  const nProfile: Profile = {
    ...profile,
    uid: "profile",
    updateDate: new Date(),
    syncedProfile: v4(),
  };

  await profileStore.put(nProfile);

  return nProfile;
};

export const updateLastSyncedDate = async (date?: Date) => {
  const db = await loadDb;
  const tx = db.transaction(["profile"], "readwrite");
  const profileStore = tx.objectStore("profile");
  const profile = await profileStore.get("profile");

  const nProfile: Profile = {
    ...profile,
    uid: "profile",
    updateDate: new Date(),
    lastSyncedDate: date || new Date(),
  };

  await profileStore.put(nProfile);

  return nProfile;
};

export const getAccountsOrder = async () => {
  const db = await loadDb;
  const profile = await db.get("profile", "profile");

  return profile?.accountsOrder;
};

export const getAccountsGroupsOrder = async () => {
  const db = await loadDb;
  const profile = await db.get("profile", "profile");

  return profile?.accountsGroupsOrder;
};

export const updateAccountsOrder = async (
  accountsOrder: Profile["accountsOrder"]
) => {
  const db = await loadDb;
  const tx = db.transaction(["profile"], "readwrite");
  const profileStore = tx.objectStore("profile");
  const profile = await profileStore.get("profile");

  const nProfile: Profile = {
    ...profile,
    uid: "profile",
    accountsOrder,
    updateDate: new Date(),
  };

  await profileStore.put(nProfile);

  return nProfile;
};

export const updateAccountsGroupsOrder = async (
  accountsGroupsOrder: Profile["accountsGroupsOrder"]
) => {
  console.log(123);

  const db = await loadDb;
  const tx = db.transaction(["profile"], "readwrite");
  const profileStore = tx.objectStore("profile");
  const profile = await profileStore.get("profile");

  const nProfile: Profile = {
    ...profile,
    uid: "profile",
    accountsGroupsOrder,
    updateDate: new Date(),
  };

  await profileStore.put(nProfile);

  return nProfile;
};

export const exportProfile = async () => {
  const db = await loadDb;
  return db.get("profile", "profile");
};

export const deleteProfile = async () => {
  const db = await loadDb;
  await db.clear("profile");
};
